import { Box, Heading, Text } from "grommet";
import React from "react";
import { Link } from "react-router-dom";
import { IActivity } from "../../generated/dataInterfaces";
import { SmallLink } from "../Icons/LinkIndicators";
import { Package } from "../Icons/Package";
interface IPropsActivityItem {
    activity: IActivity;
}
const ActivityItem = ({ activity }: IPropsActivityItem) => {
    return (
        <Link
            id={activity.id}
            key={activity.id}
            to={`/activities/${activity.id}`}
            data-testid="view-activity-link"
        >
            <Box
                margin="small"
                width="medium"
                pad="small"
                border="all"
                round="small"
                elevation="small"
                background="white"
                wrap={true}
                key={activity.id}
            >
                <Heading level="3" margin={{ top: "none" }}>
                    {activity.name} <SmallLink />
                </Heading>

                <Box flex={false} direction="row" justify="between">
                    <Text color="accent-3" size="small">
                        VIEW ACTIVITY
                    </Text>
                    {activity.isPackaged ? <Package /> : null}
                </Box>
            </Box>
        </Link>
    );
};

export default ActivityItem;
