import _ from "lodash";
import React, { createContext, useMemo, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ICredential } from "../../generated/dataInterfaces";
import { CREDENTIAL_EXISTS } from "../../data/Credentials/queries";
import { NEW_CUSTOMER_CREDENTIAL_SEND_EMAIL } from "../../data/Customers/mutations";

const CustomerContext = createContext<ICustomerContext>(null);

export interface IExtraToBook {
    extraId: string;
    quantity: number;
    priceEach: number;
}

function CustomerContextProvider(props) {
    const [email, setEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [postcode, setPostcode] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [doesReceiveEmail, setDoesReceiveEmail] = useState<boolean>(true);
    const [doesReceiveSms, setDoesReceiveSms] = useState<boolean>(true);
    const [sendWelcomeEmail, setSendWelcomeEmail] = useState<boolean>(true);
    const [credentialExists, setCredentialExists] = useState<ICredential>(null);
    const [shouldCheckEmail, setShouldCheckEmail] = useState<boolean>(false);

    const { data, errors } = useQuery(CREDENTIAL_EXISTS, {
        skip: !shouldCheckEmail,
        variables: {
            email
        }
    });

    if (
        (data && data.doesCredentialExist && shouldCheckEmail) ||
        (data && credentialExists && shouldCheckEmail)
    ) {
        // want to set null, if null!
        setCredentialExists(data.doesCredentialExist);
        setShouldCheckEmail(false);
    }

    const setEmailExtended = (value: string) => {
        setEmail(value);
        setShouldCheckEmail(true);
    };

    const [mutateNewCustomer] = useMutation(NEW_CUSTOMER_CREDENTIAL_SEND_EMAIL);
    const newCustomer = async () => {
        // do checks here to make sure credentials are correct
        try {
            // should be typed :(
            const input = {
                email,
                firstName,
                lastName,
                postcode,
                phone,
                doesReceiveEmail,
                doesReceiveSms,
                sendWelcomeEmail
            };

            const { data, errors } = await mutateNewCustomer({
                variables: {
                    input
                }
            });

            if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        } catch (e) {
            throw e;
        }
    };

    const values: ICustomerContext = useMemo(() => {
        return {
            email,
            setEmail: setEmailExtended,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            postcode,
            setPostcode,
            phone,
            setPhone,
            doesReceiveEmail,
            setDoesReceiveEmail,
            doesReceiveSms,
            setDoesReceiveSms,
            sendWelcomeEmail,
            setSendWelcomeEmail,
            credentialExists,
            newCustomer
        };
    }, [
        email,
        firstName,
        lastName,
        postcode,
        phone,
        doesReceiveEmail,
        doesReceiveSms,
        sendWelcomeEmail,
        credentialExists
    ]);

    return <CustomerContext.Provider value={values} {...props} />;
}

export interface ICustomerContext {
    email: string;
    setEmail: (value: string) => void;
    firstName: string;
    setFirstName: (value: string) => void;
    lastName: string;
    setLastName: (value: string) => void;
    postcode: string;
    setPostcode: (value: string) => void;
    phone: string;
    setPhone: (value: string) => void;
    doesReceiveEmail: boolean;
    setDoesReceiveEmail: (value: boolean) => void;
    doesReceiveSms: boolean;
    setDoesReceiveSms: (value: boolean) => void;
    sendWelcomeEmail: boolean;
    setSendWelcomeEmail: (value: boolean) => void;
    credentialExists: ICredential;
    newCustomer: () => void;
}

export default CustomerContext;
export { CustomerContextProvider };
