import { gql } from "@apollo/client";

export const FACILITY_LIST = gql`
    query facilities($organisationId: ID!) {
        facilitiesByOrg(input: { organisationId: $organisationId }) {
            id
            name
        }
    }
`;
