import { Heading, Box, Grid, Text, TextInput, Button } from "grommet";
import React, { useContext, useState, useEffect } from "react";
import { IBooking, ICustomer } from "../../../../generated/dataInterfaces";
import moment from "moment";
import BookingListContext from "../../../Context/BookingListContext";
import { Link } from "react-router-dom";
import { SmallLink } from "../../../Icons/LinkIndicators";
import { useMutation } from "@apollo/client";
import {
    BOOKING_UPDATE_TOTAL,
    BOOKING_UPDATE_QUANTITY,
    BOOKING_CANCEL,
    BOOKING_CHECK_IN,
    BOOKING_RESEND_EMAILS,
    BOOKING_UPDATE_BALANCE
} from "../../../../data/Bookings/mutations";
import { toast } from "react-toastify";
import AppContext from "../../../Context/AppContext";
import BookingDetail from "../../Detail/BookingDetail";
interface IPropsBookingDetails {
    booking: IBooking;
}

export default ({ booking }: IPropsBookingDetails) => {
    const [customer, setCustomer] = useState<ICustomer>();
    const [shouldLoadNow, setShouldLoadNow] = useState<boolean>(false);
    const [openBooking, setOpenBooking] = useState<boolean>(false);
    const [bookingId, setBookingId] = useState<string>(booking.id);
    const [isCancelled, setIsCancelled] = useState<boolean>();
    const [canCancel, setCanCancel] = useState<boolean>();
    const [canCheckIn, setCanCheckIn] = useState<boolean>();
    const [isCheckedIn, setIsCheckedIn] = useState<boolean>();
    const [checkedInAt, setCheckedInAt] = useState<Date>();
    const [quantity, setQuantity] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [balance, setBalance] = useState<number>(0);
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);

    const { userId } = useContext(AppContext);

    const calcIsCancelled = booking =>
        booking && booking.bookingStatus === "CANCELLED";
    const calcCanCancel = booking =>
        booking &&
        booking.bookingStatus !== "CANCELLED" &&
        !booking.isCheckedIn &&
        moment(booking.activityStartsAt)
            .utc()
            .isAfter(moment.utc());

    const setBookingExtended = (booking: IBooking) => {
        setCustomer(booking.customer);
        setBookingId(booking.id);
        const isCancelled = calcIsCancelled(booking);
        setIsCancelled(isCancelled);
        setCanCancel(calcCanCancel(booking));
        setIsCheckedIn(booking.isCheckedIn);
        setCheckedInAt(booking.checkedInAt);
        setCanCheckIn(!booking.isCheckedIn && !isCancelled);
        setQuantity(booking.activityQuantity);
        setTotal(booking.total);
        setBalance(booking.balance);
    };

    useEffect(() => {
        setBookingExtended(booking);
    }, [booking]);

    // const mutateCancelBooking = useMutation(BOOKING_CANCEL);
    // const onBookingCancel = async () => {
    //     const result = await mutateCancelBooking({
    //         variables: {
    //             bookingId
    //             note: ""
    //         }
    //     });
    //     const booking = result.data["cancelBooking"];
    //     setIsCancelled(calcIsCancelled(booking));
    //     setCanCancel(calcCanCancel(booking));
    //     setCanCheckIn(false);
    //     toast("Cancelled. Email sent to customer");

    //     setShouldRefresh(true);
    // };

    // const mutateCheckIn = useMutation(BOOKING_CHECK_IN);
    // const onBookingCheckIn = async () => {
    //     const result = await mutateCheckIn({
    //         variables: { bookingId, userId }
    //     });
    //     const booking = result.data["checkIn"];
    //     setIsCheckedIn(booking.isCheckedIn);
    //     setCheckedInAt(booking.checkedInAt);
    //     setCanCancel(false);
    //     toast("Checked in");

    //     setShouldRefresh(true);
    // };

    // const mutateResendEmails = useMutation(BOOKING_RESEND_EMAILS);
    // const onBookingResendEmails = async () => {
    //     const result = await mutateResendEmails({
    //         variables: {
    //             bookingId: booking.id
    //         }
    //     });
    //     const success = result.data["resendBookingEmails"] as boolean;
    //     if (success) {
    //         toast("Customer has been resent their confirmation email");
    //     } else {
    //         toast("There was a problem. No emails sent");
    //     }
    // };

    // update quantity
    const [mutateUpdateQuantity] = useMutation(BOOKING_UPDATE_QUANTITY);
    const onUpdateQuantity = async () => {
        const result = await mutateUpdateQuantity({
            variables: {
                bookingId: booking.id,
                quantity
            }
        });
        const success = result.data["updateBookingQuantity"] as IBooking;
        if (success) {
            // setBookingExtended(booking);
            setShouldLoadNow(true);
            setShouldRefresh(true);
            toast("Quantity has been updated");
        } else {
            toast("There was a problem. No change has been made");
        }
    };

    // update total
    const [mutateUpdateTotal] = useMutation(BOOKING_UPDATE_TOTAL);
    const onUpdateTotal = async () => {
        const result = await mutateUpdateTotal({
            variables: {
                bookingId: booking.id,
                newTotal: total
            }
        });
        const success = result.data["updateBookingTotal"] as IBooking;
        if (success) {
            setShouldLoadNow(true);
            // setShouldRefresh(true);
            toast("Total has been updated");
        } else {
            toast("There was a problem. No change has been made");
        }
    };

    // update total
    const [mutateUpdateBalance] = useMutation(BOOKING_UPDATE_BALANCE);
    const onUpdateBalance = async () => {
        const result = await mutateUpdateBalance({
            variables: {
                bookingId: booking.id,
                newBalance: balance
            }
        });
        const success = result.data["updateBookingBalance"] as IBooking;
        if (success) {
            setShouldLoadNow(true);
            // setShouldRefresh(true);
            toast("Balance has been updated");
        } else {
            toast("There was a problem. No change has been made");
        }
    };

    const formatDateTime = (dateTime: any) => {
        return moment(dateTime)
            .utc()
            .format("DD/MM/YYYY HH:mm");
    };

    return booking ? (
        <>
            <BookingDetail
                open={openBooking}
                setOpen={setOpenBooking}
                bookingId={bookingId}
            />
            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "large",
                    right: "large",
                    bottom: "large",
                    top: "large"
                }}
            >
                {isCancelled ? (
                    <Heading level="2" color="status-error">
                        BOOKING IS CANCELLED
                    </Heading>
                ) : null}

                <Box direction="row">
                    <Heading level="3" margin={{ right: "small" }}>
                        Basic info
                    </Heading>
                    <Button
                        onClick={() => {
                            setOpenBooking(true);
                        }}
                    >
                        Open actions
                    </Button>
                </Box>
                <Grid columns={["small", "flex"]} gap="small">
                    {/* reference */}
                    <Box>Reference</Box>
                    <Box>{booking.reference}</Box>

                    {/* activity */}
                    <Box>Activity</Box>
                    <Box alignContent="stretch" direction="row-responsive" flex>
                        <Link
                            to={`/resource/${
                                booking.activityInstance.activity.resource.id
                            }`}
                        >
                            {booking.resourceName} <SmallLink />
                        </Link>
                        <Box pad={{ left: "medium", right: "medium" }}>
                            <Text> > </Text>
                        </Box>
                        <Link
                            to={`/activities/${
                                booking.activityInstance.activity.id
                            }`}
                        >
                            {booking.activityName} <SmallLink />
                        </Link>
                    </Box>

                    {/* date */}
                    <Box>Date</Box>
                    <Box>{booking.activityInstance.daySchedule.dayText}</Box>

                    {/* time */}
                    <Box>Time</Box>
                    <Box>{booking.activityInstance.timeFromToText}</Box>

                    <Box>Checked in:</Box>
                    <Box>
                        {isCheckedIn
                            ? formatDateTime(checkedInAt)
                            : "Not checked in"}
                    </Box>
                </Grid>

                <Heading level="3">Booking details</Heading>
                <Grid columns={["small", "flex"]} gap="small">
                    <Box>Number of tickets</Box>
                    <Box direction="row-responsive">
                        {canCancel ? (
                            <Grid
                                columns={["xsmall", "medium", "flex"]}
                                gap="small"
                            >
                                <TextInput
                                    style={{ marginTop: "-10px" }}
                                    key="quantity"
                                    size="small"
                                    value={quantity}
                                    onChange={e => {
                                        const num = Number(e.target.value);
                                        setQuantity(
                                            isNaN(num)
                                                ? booking.activityQuantity
                                                : num
                                        );
                                    }}
                                />
                                <Box>
                                    {" x "}
                                    {
                                        booking.activityInstance.activity
                                            .ticketDescription
                                    }
                                    {` (£${(
                                        booking.activityPrice -
                                        booking.activityDiscount
                                    ).toFixed(2)} each)`}
                                </Box>
                                <Box key="updateQty" flex align="end">
                                    <Button
                                        primary
                                        style={{ marginTop: "-10px" }}
                                        color="light-3"
                                        label="Update"
                                        onClick={e => {
                                            onUpdateQuantity();
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ) : (
                            <Box direction="row">
                                <Text weight="bold" margin={{ right: "small" }}>
                                    {booking.activityQuantity}
                                </Text>
                                {" x "}
                                {
                                    booking.activityInstance.activity
                                        .ticketDescription
                                }
                                {` (£${(
                                    booking.activityPrice -
                                    booking.activityDiscount
                                ).toFixed(2)} each)`}
                            </Box>
                        )}
                    </Box>

                    {/* extras */}
                    <Box>Extras</Box>

                    {booking.bookingExtras &&
                    booking.bookingExtras.length > 0 ? (
                        <Box>
                            {booking.bookingExtras.map(extra => (
                                <Box key={`extra-${extra.id}`}>
                                    {extra.quantity} x {extra.name}{" "}
                                    {` (£${(
                                        extra.pricePerItem -
                                        extra.discountPerItem
                                    ).toFixed(2)} each)`}
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box>No extras</Box>
                    )}
                    <Box key="sub-total">Sub total</Box>
                    <Box key="sub-total-value">
                        <Text size="medium" weight="normal">
                            £{booking.subTotal.toFixed(2)}
                        </Text>
                    </Box>
                    <Box key="discount">Discount</Box>
                    <Box key="discount-value">
                        <Text size="medium" weight="normal">
                            £{booking.discount.toFixed(2)}
                        </Text>
                    </Box>
                    <Box key="total-label">Total</Box>
                    <Box key="total-value">
                        {canCancel ? (
                            <Grid columns={["xsmall", "flex"]} gap="small">
                                <Box key="total" direction="row">
                                    <Text
                                        margin={{
                                            top: "small",
                                            right: "xsmall"
                                        }}
                                    >
                                        £{" "}
                                    </Text>
                                    <TextInput
                                        size="small"
                                        step="0.01"
                                        type="number"
                                        value={total.toFixed(2)}
                                        onChange={e => {
                                            const num = Number(e.target.value);
                                            setTotal(
                                                isNaN(num) ? booking.total : num
                                            );
                                        }}
                                    />
                                </Box>
                                <Box key="updateTotal" flex align="start">
                                    <Button
                                        primary
                                        color="light-3"
                                        label="Update"
                                        onClick={e => {
                                            onUpdateTotal();
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ) : (
                            <Text size="large" weight="bold">
                                £{booking.total.toFixed(2)}
                            </Text>
                        )}
                    </Box>
                    <Box key="balance">Balance</Box>

                    <Box key="balance-value">
                        {canCancel ? (
                            <Grid columns={["xsmall", "flex"]} gap="small">
                                <Box key="total" direction="row">
                                    <Text
                                        margin={{
                                            top: "small",
                                            right: "xsmall"
                                        }}
                                    >
                                        £{" "}
                                    </Text>
                                    <TextInput
                                        size="small"
                                        step="0.01"
                                        type="number"
                                        value={balance.toFixed(2)}
                                        onChange={e => {
                                            const num = Number(e.target.value);
                                            setBalance(
                                                isNaN(num)
                                                    ? booking.balance
                                                    : num
                                            );
                                        }}
                                    />
                                </Box>
                                <Box key="updateTotal" flex align="start">
                                    <Button
                                        primary
                                        color="light-3"
                                        label="Update"
                                        onClick={e => {
                                            onUpdateBalance();
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ) : (
                            <Text size="large" weight="bold">
                                £{booking.balance.toFixed(2)}
                            </Text>
                        )}
                    </Box>
                </Grid>
            </Box>
        </>
    ) : null;
};
