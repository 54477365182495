import { useState, useEffect } from "react";

export function useStateWithLocalStorage<T>(localStorageKey): [T, (T) => void] {
    const itemFromLocalStorage = JSON.parse(
        localStorage.getItem(localStorageKey)
    ) as T;
    const [value, setValue] = useState<T>(itemFromLocalStorage);

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
}

export default useStateWithLocalStorage;
