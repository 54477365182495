export const bookingListFields = `
id
reference
packageReference
name
bookingNames
customer{
    id
}
isCheckedIn
checkedInAt
date
time
total
balance
bookingStatus
peopleCount
extrasCount
activityStartsAt
transactions{
    id
    providerIdentifier
}
customerMemberships{
    id
    name
}
activityInstance{
    id
    activity{
        id
        name
        resource {
            id
            name
        }
    }
}
`;

export const bookingFullFields = `
id
    organisation{
        id
    }
    bookingExtras{
        id
        quantity
      	name
        pricePerItem
        discountPerItem
    }
    customer{
        id
        name
    }
    madeByCredential{
        id
        email
        firstName
        lastName
    }
    activityInstance {
        id
        timeFromToText
        daySchedule{
            dayText
        }
        activity{
            id
            name
            ticketDescription
            resource{
                id
                name
            }
        }
    }
    activityQuantity
    activityPrice
    activityDiscount
    activityStartsAt
    customerNotes  (orderBy: createdAt_DESC){
        id
        createdAt
        createdByUser{
            id
        }
        content
        noteType
    }
    adminNotes{
        id
        createdAt
        createdByUser{
            id
        }
        content
        noteType
    }
    midAdminNotes (orderBy: createdAt_DESC){
        id
        createdAt
        createdByUser{
            id
            name
        }
        content
        noteType
    }
    package{
        id
        name
    }
    completedAt
    reference
    packageReference
    bookingNames
    nominalCode
    subTotal
    discount
    total
    balance
    createdAt
    updatedAt
    bookingStatus
    customerConfirmationEmailId
    adminConfirmationEmailId
    checkedInAt
    checkedInByUserId
    otherMetadata
    searchableUpper
    name
    peopleCount
    extrasCount
    isCheckedIn
    activityName
    resourceName
    date
    time
    transactions (orderBy: completedAt_ASC) {
        isComplete
        completedAt
        amount
        providerIdentifier
        authCode
        description
      }
    customerMemberships{
        id
        name
        customer{
            id
            name
        }
        membership{
            name 
        }
        photo{
            base64
        }
    }
`;

export const notesFields = `
id
createdAt
createdByUser {
    id
    name
}
content
noteType
`;
