import { gql } from "@apollo/client";
import { customerMembershipFullDetails } from "./fields";

export const CUSTOMER_MEMBERSHIP_FULL_DETAILS = gql`
query customerMembership($customerMembershipId: ID!){
    customerMembership(where: {id: $customerMembershipId}) {
    ${customerMembershipFullDetails}
  }
}
`;
