import { gql } from "@apollo/client";

export const DISCOUNTS_LIST = gql`
    query discountList {
        discounts(where: { isActive: true }) {
            id
            name
            discountAmount
            discountType
        }
    }
`;

export const DISCOUNT_FULL_DETAILS = gql`
    query discountFullDetails($discountId: ID!) {
        discountFullDetails(input: { discountId: $discountId }) {
            id
            name
            description
            activities {
                name
                resource {
                    name
                }
            }
            daysOfWeek
            isActive
            amount
            activitiesExtraApplicationDescription
        }
    }
`;
