import { useQuery } from "@apollo/client";
import { addTimeStringToJSDate } from "@les-ormes/lib";
import { Box, Button, Heading, Paragraph } from "grommet";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PACKAGE } from "../../data/Packages/queries";
import { IPackage } from "../../generated/dataInterfaces";
import BookingByActivityInstanceNoPayment from "../Bookings/Make/BookingByActivityInstanceNoPayment";
import BookingByActivityInstanceWithPayment from "../Bookings/Make/BookingByActivityInstanceWithPayment";
import BookingContext from "../Context/BookingContext";
import { SmallLink } from "../Icons/LinkIndicators";
import Page from "../Page";

const PackageScreen = ({ match }: { match: { params: { id: string } } }) => {
    const { id } = match.params;

    const { setInstanceToBook, instanceToBook, booking } =
        useContext(BookingContext);
    const [shouldOpenBookingNoPaymentSide, setShouldOpenBookingNoPaymentSide] =
        useState<boolean>(false);
    const [
        shouldOpenBookingWithPaymentSide,
        setShouldOpenBookingWithPaymentSide
    ] = useState<boolean>(false);

    const { data, refetch: refetchPackage } = useQuery<{
        package: IPackage;
    }>(PACKAGE, {
        variables: {
            packageId: id
        },
        skip: !id
    });

    const pack = data ? data.package : null;

    useEffect(
        function refetchPackageAfterBookingIsMade() {
            if (booking) {
                void refetchPackage();
            }
        },
        [booking, refetchPackage]
    );

    const firstActivityInstanceToBook = pack?.activityInstances.find(
        (activityInstance) => {
            const { date } = activityInstance.daySchedule;
            const { startTime } = activityInstance;
            const dateAndTime = addTimeStringToJSDate(date, startTime);
            return moment(dateAndTime).isAfter(new Date());
        }
    );

    if (
        firstActivityInstanceToBook &&
        (!instanceToBook ||
            instanceToBook?.id !== firstActivityInstanceToBook.id)
    ) {
        const activityInstance = {
            ...firstActivityInstanceToBook,
            activity: { ...pack.activity }
        };
        setInstanceToBook(activityInstance);
    }

    return (
        <Page title="Package">
            {shouldOpenBookingWithPaymentSide ? (
                <BookingByActivityInstanceWithPayment
                    open={shouldOpenBookingWithPaymentSide}
                    setOpen={setShouldOpenBookingWithPaymentSide}
                />
            ) : null}

            {shouldOpenBookingNoPaymentSide ? (
                <BookingByActivityInstanceNoPayment
                    open={shouldOpenBookingNoPaymentSide}
                    setOpen={setShouldOpenBookingNoPaymentSide}
                />
            ) : null}

            {pack ? (
                <>
                    <Box
                        direction="column"
                        flex="grow"
                        pad="small"
                        margin="small"
                    >
                        <Heading
                            level="3"
                            margin={{ top: "none" }}
                            responsive={true}
                        >
                            Package: {pack.name}
                        </Heading>
                    </Box>

                    <Box direction="row-responsive" wrap={true}>
                        <Box pad="medium">
                            <Button
                                data-testid="create-booking-no-payment"
                                label="Create booking (no payment)"
                                onClick={() => {
                                    setShouldOpenBookingNoPaymentSide(
                                        !shouldOpenBookingNoPaymentSide
                                    );
                                }}
                            />
                        </Box>
                        <Box pad="medium">
                            <Button
                                data-testid="create-booking-with-payment"
                                label="Make and pay for a booking"
                                onClick={() => {
                                    setShouldOpenBookingWithPaymentSide(
                                        !shouldOpenBookingWithPaymentSide
                                    );
                                }}
                            />
                        </Box>
                    </Box>

                    <Box direction="row-responsive" wrap={true}>
                        {pack.activityInstances.map(
                            (activityInstance, index) => {
                                const isPast = moment(
                                    activityInstance.daySchedule.date
                                ).isBefore(new Date());

                                const color = isPast ? "gray" : "";

                                return (
                                    <Box
                                        margin="small"
                                        width="medium"
                                        pad="small"
                                        border="all"
                                        round="small"
                                        elevation="small"
                                        flex={false}
                                        responsive={true}
                                        background="white"
                                        id={activityInstance.id}
                                        key={activityInstance.id}
                                    >
                                        <Box>
                                            <Heading
                                                level={3}
                                                margin={{
                                                    bottom: "none",
                                                    top: "small"
                                                }}
                                                color={color}
                                            >
                                                <Link
                                                    to={`/activities/instance/${activityInstance.id}`}
                                                >
                                                    Activity {index + 1}{" "}
                                                    <SmallLink />
                                                </Link>
                                            </Heading>

                                            <Paragraph
                                                margin={{
                                                    bottom: "none",
                                                    top: "small"
                                                }}
                                                color={color}
                                            >
                                                {moment(
                                                    activityInstance.daySchedule
                                                        .date
                                                ).format("DD/MM/yyyy")}
                                                {" - "}
                                                {
                                                    activityInstance.timeFromToText
                                                }
                                            </Paragraph>

                                            <Paragraph
                                                margin={{
                                                    top: "small",
                                                    bottom: "small"
                                                }}
                                                color={color}
                                            >
                                                {
                                                    activityInstance.availabilityText
                                                }
                                            </Paragraph>
                                            <Paragraph
                                                margin={{
                                                    top: "small",
                                                    bottom: "small"
                                                }}
                                                color={color}
                                            >
                                                {
                                                    "Price for remaining activities: £"
                                                }
                                                {activityInstance.pricePerBooking.toFixed(
                                                    2
                                                )}
                                            </Paragraph>
                                        </Box>
                                    </Box>
                                );
                            }
                        )}
                    </Box>
                </>
            ) : (
                <Paragraph>Loading...</Paragraph>
            )}
        </Page>
    );
};

export default PackageScreen;
