import { gql } from "@apollo/client";
import { bookingListFields, bookingFullFields } from "./fields";

export const BOOKING_SEARCH_PAST_FUTURE = gql`
    query searchBookingsPastFuture($organisationId: ID!, $searchTerm: String!){
      searchBookingsPastFuture(input: {
        organisationId: $organisationId,
        searchTerm: $searchTerm,
      }) {
        searchTerm
        today{
            ${bookingListFields}
        }
        past{
          ${bookingListFields}
        }
        future {
            ${bookingListFields}
        }
      }
    }
`;

export const BOOKING_FULL_DETAILS = gql`
query booking($bookingId: ID!){
  booking(where: {id: $bookingId}) {
    ${bookingFullFields}
  }
}
`;
