import { Box, FormField, TextInput } from "grommet";
import React, { useContext } from "react";
import BookingContext from "../../Context/BookingContext";

const BookingNames = () => {
    const { bookingNames, setBookingNames, instanceToBook, ticketQty } =
        useContext(BookingContext);
    const { activity } = instanceToBook;

    const names = Array(
        ticketQty * (instanceToBook?.activity?.quantityPerBooking ?? 1)
    ).fill("");

    return (
        <>
            {activity?.isBookingNameRequired
                ? names.map((name, index) => (
                      <Box
                          key={`${index}-name`}
                          flex={false}
                          direction="column"
                          margin={{ top: "small", bottom: "medium" }}
                      >
                          <FormField label={`Booking name ${index + 1}`}>
                              <TextInput
                                  data-testid={`booking-name-input-${index}`}
                                  placeholder="Name"
                                  value={bookingNames[index]}
                                  onChange={(e) => {
                                      const newBookingNames = [...bookingNames];
                                      newBookingNames[index] = e.target.value;
                                      setBookingNames(newBookingNames);
                                  }}
                              />
                          </FormField>
                      </Box>
                  ))
                : null}
        </>
    );
};

export default BookingNames;
