import React, { createContext, useMemo } from "react";
import useStateWithLocalStorage from "../Custom/useStateWithLocalStorageSimple";

const FacilityContext = createContext<IFacilityContext>(null);

function FacilityContextProvider(props) {
    const [facilityId, setFacilityId] = useStateWithLocalStorage<string>(
        "facilityId"
    );

    const values = useMemo(() => {
        return {
            facilityId,
            setFacilityId
        };
    }, [facilityId]);

    return <FacilityContext.Provider value={values} {...props} />;
}

export interface IFacilityContext {
    facilityId: string;
    setFacilityId: (value: string) => void;
}

export default FacilityContext;
export { FacilityContextProvider };
