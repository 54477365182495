import {
    Box,
    Button,
    CheckBox,
    Grid,
    Layer,
    Text,
    TextInput,
    Heading
} from "grommet";
import { Close } from "grommet-icons";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ActivityInstanceItem from "../../Activities/ActivityInstanceItem";
import BookingContext from "../../Context/BookingContext";
import FindCustomer from "../../Customers/Finder/FindCustomer";
import { BRAINTREE_CLIENT_TOKEN_QUERY } from "../../../data/Braintree/queries";
import DropIn from "braintree-web-drop-in-react";
import { useQuery } from "@apollo/client";
import BookingNames from "./BookingNames";

interface IPropsMakeBookingByActivityInstance {
    open: boolean;
    setOpen: any;
    otherProps?: any;
}

export default ({ open, setOpen }: IPropsMakeBookingByActivityInstance) => {
    const {
        customer,
        instanceToBook,
        ticketQty,
        setTicketQty,
        calculateTotal,
        extrasToBook,
        updateExtra,
        note,
        setNote,
        shouldSendEmails,
        setShouldSendEmails,
        total,
        setTotal,
        setInitialPayment,
        makeBooking
    } = useContext(BookingContext);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [totalPrice, setTotalPrice] = useState<string>();
    const [initialPaymentPrice, setInitialPaymentPrice] = useState<string>();
    const [braintreeClientToken, setBraintreeClientToken] = useState(null);
    const [braintreeInstance, setBraintreeInstance] = useState(null);

    useEffect(() => {
        setTotalPrice("");
        setInitialPaymentPrice("");
    }, [open]);

    useEffect(() => {
        console.log("PAY useEffect [ticketQty, extrasToBook]");
        const p = calculateTotal();
        setTotalPrice(p.toFixed(2));
        setTotal(p);
        setInitialPaymentPrice(p.toFixed(2));
        setInitialPayment(p);
        setButtonDisabled(false);
    }, [ticketQty, extrasToBook, calculateTotal, setInitialPayment, setTotal]);

    useEffect(() => {
        if (customer) {
            setBraintreeClientToken("");
        } else {
            setBraintreeClientToken("");
        }
    }, [customer]);

    const onClose = () => {
        setOpen(false);
    };

    const makeBookingExtended = async () => {
        try {
            const { nonce } = await braintreeInstance.requestPaymentMethod({
                threeDSecure: {
                    amount: total,
                    challengeRequested: true
                }
            });
            if (!nonce) {
                throw new Error("no nonce");
            }
            const booking = await makeBooking(nonce);

            if (booking == null) {
                toast("The booking was not created - payment failed");
                setButtonDisabled(false);
            } else {
                const pid = booking.transactions[0].providerIdentifier;
                toast(
                    `Booking created, payment successful. Provider ID:${pid}`
                );
                setTimeout(() => onClose(), 1000);
            }
        } catch (e) {
            toast(e.message);
            setButtonDisabled(false);
        }
    };

    const { data } = useQuery(BRAINTREE_CLIENT_TOKEN_QUERY, {
        variables: {
            customerId: customer ? customer.id : ""
        },
        skip: !!braintreeClientToken || !customer
    });

    if (data && data["braintreeClientToken"] && !braintreeClientToken) {
        setBraintreeClientToken(data["braintreeClientToken"].token);
    }

    return open && instanceToBook ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box width="large" pad="small" overflow="auto">
                <Heading level="2" alignSelf="stretch">
                    Booking with Payment{" "}
                    <Button icon={<Close />} onClick={onClose} />
                </Heading>
                <Box flex={false} direction="row" justify="between">
                    <ActivityInstanceItem
                        instance={instanceToBook}
                        showActivity={true}
                        showResource={true}
                        showDate={true}
                        showPrice={true}
                        hideBookingLink={true}
                    />
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Find customer</strong>
                        </Box>
                        <Box>
                            {" "}
                            <FindCustomer />
                        </Box>
                    </Grid>
                </Box>
                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Heading level="4">Payment details</Heading>
                    <Box margin={{ bottom: "medium" }}>
                        {braintreeClientToken ? (
                            <DropIn
                                options={{
                                    authorization: braintreeClientToken,
                                    translations: {
                                        payingWith: "",
                                        chooseAnotherWayToPay:
                                            "Enter new card details",
                                        chooseAWayToPay: "Enter card details",
                                        saveCardLabel:
                                            "Save card against customer account? You must have their permission."
                                    },
                                    threeDSecure: true,
                                    vaultManager: true,
                                    card: {
                                        cardholderName: {
                                            required: true
                                        },
                                        vault: {
                                            vaultCard: false,
                                            allowVaultCardOverride: true
                                        }
                                    }
                                }}
                                onInstance={(instance) => {
                                    setBraintreeInstance(instance);
                                }}
                            />
                        ) : (
                            <Text size="small">
                                Select a customer to load their payment details
                            </Text>
                        )}
                    </Box>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Number of tickets</strong>
                        </Box>
                        <Box>
                            <TextInput
                                data-testid="ticket-quantity-input-with-payment"
                                required
                                onChange={(e) => {
                                    const num = Number(e.target.value);
                                    setTicketQty(isNaN(num) ? 0 : num);
                                }}
                                type="number"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    as="form"
                    direction="column"
                    margin={{ left: "small" }}
                >
                    {instanceToBook.activity.resource.extras.map((extra) => {
                        if (!extra.isAvailable) {
                            return null;
                        }
                        return (
                            <Box
                                key={extra.id}
                                direction="column"
                                margin={{
                                    top: "small",
                                    bottom: "medium"
                                }}
                            >
                                <Grid columns={["small", "flex"]} gap="xsmall">
                                    <Box
                                        margin={{
                                            top: "small",
                                            right: "small"
                                        }}
                                    >
                                        <Text size="small">{extra.name}</Text>
                                    </Box>
                                    <Box>
                                        <TextInput
                                            placeholder={`£${extra.price.toFixed(
                                                2
                                            )} each`}
                                            onChange={(e) => {
                                                const num = Number(
                                                    e.target.value
                                                );
                                                updateExtra(
                                                    extra.id,
                                                    isNaN(num) ? 0 : num
                                                );
                                            }}
                                            type="number"
                                        />
                                    </Box>
                                </Grid>
                            </Box>
                        );
                    })}
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Total price</strong>
                        </Box>
                        <Box>
                            <TextInput
                                value={totalPrice}
                                onChange={(e) => {
                                    console.log(
                                        "BookingByActivityInstanceWithPayment.tsx total e.target.value",
                                        e.target.value
                                    );
                                    const num = Number(e.target.value);
                                    setTotalPrice(e.target.value);
                                    setTotal(isNaN(num) ? 0 : num);
                                }}
                                type="text"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Initial payment</strong>
                        </Box>
                        <Box>
                            <TextInput
                                value={initialPaymentPrice}
                                onChange={(e) => {
                                    const num = Number(e.target.value);
                                    setInitialPaymentPrice(e.target.value);
                                    setInitialPayment(isNaN(num) ? 0 : num);
                                }}
                                type="text"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Note</strong>
                        </Box>
                        <Box>
                            <TextInput
                                value={note}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                                type="text"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "none", right: "small" }}>
                            <strong>Send emails</strong>
                        </Box>
                        <Box>
                            <CheckBox
                                checked={shouldSendEmails}
                                onChange={(e) => {
                                    setShouldSendEmails(e.target.checked);
                                }}
                            />
                        </Box>
                    </Grid>
                </Box>

                <BookingNames />

                <Grid
                    columns={["small", "flex"]}
                    gap="xsmall"
                    margin={{ bottom: "medium" }}
                >
                    <Box margin={{ top: "small", right: "small" }} />
                    <Box>
                        <Button
                            data-testid="complete-booking-with-payment-button"
                            onClick={() => {
                                setButtonDisabled(true);
                                void makeBookingExtended();
                            }}
                            disabled={buttonDisabled}
                            primary
                            color="accent-2"
                        >
                            <Box
                                direction="column"
                                alignContent="center"
                                pad="small"
                                align="center"
                            >
                                {buttonDisabled
                                    ? `Creating booking`
                                    : `Complete booking`}
                            </Box>
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </Layer>
    ) : null;
};
