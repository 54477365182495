import * as React from "react";
import { render } from "react-dom";
import App from "./components/App";
import { AppContextProvider } from "./components/Context/AppContext";
import "./css/default.css";

render(
    <AppContextProvider>
        <App />
    </AppContextProvider>,
    document.getElementById("root")
);
