import { gql } from "@apollo/client";

export const INSTANCES_BY_DATE_RANGE = gql`
    query instancesByResourceAndDateRange(
        $resourceId: ID!
        $startDate: DateTime!
        $endDate: DateTime!
    ) {
        instancesByResourceAndDateRange(
            input: {
                resourceId: $resourceId
                startDate: $startDate
                endDate: $endDate
            }
        ) {
            startDate
            endDate
            resource {
                id
                name
            }
            days {
                dayText
                date
                activityInstances {
                    startTime
                    endTime
                    id
                    timeFromToText
                    availabilityText
                    quantityAvailable
                    quantityUnavailable
                    maxCapacity
                    activity {
                        id
                        name
                        isPackaged
                        resource {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
