import {
    Box,
    Button,
    Grid,
    Heading,
    Layer,
    Text,
    TextInput,
    Select,
    TextArea,
    Calendar
} from "grommet";
import { Close } from "grommet-icons";
import moment from "moment";
import React, { useContext, useState, useEffect, useDebugValue } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import BookingListContext from "../../../Context/BookingListContext";
import AppContext from "../../../Context/AppContext";
import { IDiscount } from "../../../../generated/dataInterfaces";
import { DISCOUNTS_LIST } from "../../../../data/Discounts/queries";
import { BENEFIT_ADD } from "../../../../data/Customers/mutations";

interface IPropsAddBenefit {
    customerId: string;
    open: boolean;
    setOpen: any;
    otherProps?: any;
}

export default ({ open, setOpen, customerId }: IPropsAddBenefit) => {
    const [shouldLoadNow, setShouldLoadNow] = useState(true);
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);
    const [discounts, setDiscounts] = useState<IDiscount[]>();
    const { userId } = useContext(AppContext);
    const [discount, setDiscount] = useState<IDiscount>();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [expiryDate, setExpiryDate] = useState<string>();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const { data, error, loading } = useQuery(DISCOUNTS_LIST, {
        skip: !shouldLoadNow,
        fetchPolicy: "no-cache"
    });

    const onClose = () => {
        open = false;
        setOpen(false);
    };

    useEffect(() => {
        setButtonDisabled(false);
    }, [open, customerId]);

    const hasData = data && data.discounts;
    if (hasData) {
        setShouldLoadNow(false);
        const discounts: IDiscount[] = data.discounts;
        setDiscounts(discounts);
    }

    const [mutateAddBenefit] = useMutation(BENEFIT_ADD);
    const onAddBenefit = async () => {
        try {
            const result = await mutateAddBenefit({
                variables: {
                    input: {
                        discountId: discount.id,
                        customerId,
                        name,
                        description,
                        expiryDate
                    }
                }
            });
            const benefit = result.data["addBenefit"];
            toast("Discount assigned to customer as a benefit");

            setShouldRefresh(true);
            setOpen(false);
        } catch (e) {
            console.log(e);
            toast(`Error: ${e}. Nothing has been changed`);
            setButtonDisabled(false);
        }
    };

    const marginBottom = { bottom: "small" };
    const padTop = { top: "small" };

    return open && discounts ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box
                as="form"
                fill="vertical"
                overflow="auto"
                width="large"
                pad="medium"
                onSubmit={onClose}
            >
                <Box flex={false} direction="row" justify="end">
                    <Heading level="2">
                        Assign a discount to a customer as a benefit
                    </Heading>
                    <Button
                        icon={<Close />}
                        alignSelf="end"
                        onClick={onClose}
                    />
                </Box>
                <Box flex={false} direction="column" justify="between">
                    <Grid columns={["small", "flex"]}>
                        <Box pad={padTop} margin={marginBottom}>
                            Discount:
                        </Box>
                        <Box margin={marginBottom}>
                            <Select
                                onChange={({ value }) => {
                                    setDiscount(value);
                                }}
                                // placeholder={
                                //     discount ? discount.name : "Select discount"
                                // }
                                placeholder="Select discount"
                                value={discount}
                                labelKey="name"
                                valueKey="id"
                                options={discounts}
                            />
                        </Box>
                        <Box pad={padTop} margin={marginBottom}>
                            Name:
                        </Box>
                        <Box margin={marginBottom}>
                            <TextInput
                                required
                                placeholder="Unique name for the benefit"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </Box>
                        <Box pad={padTop} margin={marginBottom}>
                            Description:
                        </Box>
                        <Box margin={marginBottom}>
                            <TextArea
                                placeholder="Some more details, if required"
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                        </Box>
                        <Box>Expiry date:</Box>
                        <Box>
                            <Calendar
                                size="medium"
                                date={expiryDate}
                                daysOfWeek
                                onSelect={(date) => {
                                    setExpiryDate(date);
                                }}
                                bounds={[
                                    moment.utc().format("YYYY-MM-DD"),
                                    moment
                                        .utc()
                                        .add(10, "years")
                                        .format("YYYY-MM-DD")
                                ]}
                            />
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <Box
                direction="column"
                justify="between"
                margin={{ bottom: "medium", top: "large", left: "small" }}
                alignContent="end"
                width="medium"
            >
                <Grid columns={["small", "flex"]}>
                    <Box />
                    <Button
                        onClick={(e) => {
                            setButtonDisabled(true);
                            onAddBenefit();
                        }}
                        primary
                        disabled={buttonDisabled}
                        color="accent-2"
                    >
                        <Box
                            direction="column"
                            alignContent="center"
                            pad="small"
                            align="center"
                        >
                            {buttonDisabled
                                ? `Assigning benefit`
                                : `Assign benefit`}
                        </Box>
                    </Button>
                </Grid>
            </Box>
        </Layer>
    ) : null;
};
