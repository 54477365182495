import {
    Box,
    Button,
    Grid,
    Heading,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text
} from "grommet";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IBooking, ITransaction } from "../../../../generated/dataInterfaces";
import AddTransactionNoPayment from "./AddTransactionNoPayment";
import AddTransactionWithPayment from "./AddTransactionWithPayment";

interface IPropsBookingTransactions {
    booking: IBooking;
}

export default ({ booking }: IPropsBookingTransactions) => {
    const [transactions, setTransactions] = useState<ITransaction[]>();
    const [openTransactionNoPayment, setOpenTransactionNoPayment] = useState(
        false
    );
    const [
        openTransactionWithPayment,
        setOpenTransactionWithPayment
    ] = useState(false);

    useEffect(() => {
        setTransactions(booking.transactions);
    }, [booking]);

    const formatDateTime = (dateTime: any) => {
        return moment(dateTime).utc().format("DD/MM/YYYY HH:mm");
    };

    return transactions ? (
        <>
            <AddTransactionNoPayment
                booking={booking}
                open={openTransactionNoPayment}
                setOpen={setOpenTransactionNoPayment}
            />
            <AddTransactionWithPayment
                booking={booking}
                open={openTransactionWithPayment}
                setOpen={setOpenTransactionWithPayment}
            />

            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "small"
                }}
            >
                <Heading level="3">Transactions</Heading>

                <Grid columns={["flex", "medium"]} gap="small">
                    <Grid columns={["small", "flex"]} gap="small">
                        <Box key="sub-total">Sub total</Box>
                        <Box key="sub-total-value">
                            £{booking.subTotal.toFixed(2)}
                        </Box>
                        <Box key="discount">Discount</Box>
                        <Box key="discount-value">
                            £{booking.discount.toFixed(2)}
                        </Box>
                        <Box key="total-label">Total</Box>
                        <Box key="total-value">£{booking.total.toFixed(2)}</Box>
                        <Box key="balance-label">Balance</Box>
                        <Box key="balance-value">
                            £{booking.balance.toFixed(2)}
                        </Box>
                    </Grid>
                    <Box>
                        <Box margin={{ bottom: "medium" }}>
                            <Button
                                label="Add transaction (no payment)"
                                onClick={() =>
                                    setOpenTransactionNoPayment(true)
                                }
                            />
                        </Box>
                        <Box>
                            <Button
                                label="Make a payment"
                                data-testid="make-payment-button"
                                onClick={() =>
                                    setOpenTransactionWithPayment(true)
                                }
                            />
                        </Box>
                    </Box>
                </Grid>

                <Box
                    alignSelf="stretch"
                    pad={{ bottom: "large" }}
                    alignContent="stretch"
                >
                    <Table
                        alignSelf="stretch"
                        className="table hover"
                        caption="Default Table"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableCell scope="col">Completed</TableCell>
                                <TableCell scope="col">Amount</TableCell>
                                <TableCell scope="col">Provider ID</TableCell>
                                <TableCell scope="col">Auth code</TableCell>
                                <TableCell scope="col">Description</TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {transactions.map((transaction) => (
                                <TableRow key={`trans-${transaction.id}`}>
                                    <TableCell>
                                        {formatDateTime(
                                            transaction.completedAt
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        £
                                        {transaction.amount
                                            ? transaction.amount.toFixed(2)
                                            : "???"}
                                    </TableCell>
                                    <TableCell>
                                        {transaction.providerIdentifier}
                                    </TableCell>
                                    <TableCell>
                                        {transaction.authCode}
                                    </TableCell>
                                    <TableCell>
                                        <Text size="small">
                                            {transaction.description}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </>
    ) : null;
};
