import React from "react";
import { IBooking } from "../../../../generated/dataInterfaces";
import { Paragraph, Box, Heading, Text, Button } from "grommet";
import _, { groupBy, orderBy } from "lodash";
import { Package, SmallPackage } from "../../../Icons/Package";

interface IPropsPackagedBookings {
    bookings: IBooking[];
    setActiveBooking: any;
}

export default ({ bookings, setActiveBooking }: IPropsPackagedBookings) => {
    const packageGroups = groupBy(bookings, "packageReference");

    bookings[0].activityStartsAt;

    return packageGroups ? (
        <Box flex="grow" wrap={true} direction="row-responsive">
            {Object.keys(packageGroups).map((key) => {
                const bookings = orderBy(packageGroups[key], [
                    "activityStartsAt"
                ]);
                const booking = bookings[0];
                const isCancelled = booking.bookingStatus == "CANCELLED";

                return (
                    <Box
                        margin="small"
                        width="medium"
                        pad="small"
                        border="all"
                        round="small"
                        elevation="small"
                        background={!isCancelled ? "white" : "light-2"}
                        key={key}
                    >
                        <Heading
                            level="4"
                            margin={{
                                top: "small",
                                bottom: "small"
                            }}
                        >
                            {booking.activityInstance.activity.name}{" "}
                            {isCancelled ? (
                                <Text color="status-error">CANCELLED</Text>
                            ) : null}
                        </Heading>

                        <Box margin={{ bottom: "small" }}>
                            {bookings.map((booking) => (
                                <Button
                                    key={booking.id}
                                    onClick={() => setActiveBooking(booking.id)}
                                    margin={{ bottom: "small" }}
                                >
                                    <Text
                                        color={"brand"}
                                        size={"small"}
                                        margin={{ bottom: "xsmall" }}
                                    >
                                        {booking.date}{" "}
                                        {
                                            booking.activityInstance
                                                .timeFromToText
                                        }
                                        {" ("}
                                        {booking.reference}
                                        {")"}
                                    </Text>
                                </Button>
                            ))}
                        </Box>

                        <Text margin={{ bottom: "xsmall" }}>
                            <SmallPackage /> Package reference:{" "}
                            {booking.packageReference}
                        </Text>

                        <Text margin={{ bottom: "small" }}>
                            People: {booking.activityQuantity}
                        </Text>
                        {booking.bookingNames &&
                        booking.bookingNames.length > 0 ? (
                            <Text margin={{ bottom: "xsmall" }}>
                                Name(s): {booking.bookingNames.join(", ")}
                            </Text>
                        ) : null}
                        <Text margin={{ bottom: "small" }}>
                            Total:{" £"}
                            {booking.total.toFixed(2)}
                        </Text>
                        {booking.balance > 0 ? (
                            <Text
                                margin={{
                                    bottom: "small"
                                }}
                            >
                                Balance:{" £"}
                                {booking.balance.toFixed(2)}
                            </Text>
                        ) : null}
                    </Box>
                );
            })}
        </Box>
    ) : (
        <Box>Loading...</Box>
    );
};
