import { ApolloClient, createHttpLink, DefaultOptions } from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, { createContext, useContext, useEffect, useState } from "react";
import useStateWithLocalStorage from "../Util/useStateWithLocalStorage";

const generateApolloClient = (token: string) => {
    const httpLink = createHttpLink({
        uri: process.env.GRAPHQL_ENDPOINT
    });

    const authLink = setContext((request, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ""
            }
        };
    });

    const errorHandlingLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                        locations,
                        null,
                        2
                    )}, Path: ${path}`
                )
            );
            const isNotAuth = graphQLErrors.some((error) =>
                error.message.toLowerCase().includes("not authorised")
            );
            if (isNotAuth) {
                localStorage.removeItem("token");
                window.location.reload();
            }
        }

        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }
    });

    const defaultApolloOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "ignore"
        },
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all"
        },
        mutate: {
            errorPolicy: "all"
        }
    };

    const client = new ApolloClient({
        link: authLink.concat(errorHandlingLink).concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: defaultApolloOptions
    });

    return client;
};

const AppContext = createContext<IAppContext>(null);

export const AppContextProvider = ({ children }) => {
    const [organisationId, setOrganisationId] = useState(null);
    const [userId, setUserId] = useState<string>(null);
    const [userName, setUserName] = useState<string>(null);
    const [token, setToken] = useStateWithLocalStorage<string>("token");
    const [redirectTo, setRedirectTo] = useState<string>(null);

    const [apolloClient, setApolloClient] = useState<
        ApolloClient<NormalizedCacheObject>
    >(null);

    useEffect(() => {
        const apolloClient = generateApolloClient(token);
        setApolloClient(apolloClient);
    }, [token]);

    const appContext: IAppContext = {
        apolloClient,
        organisationId,
        setOrganisationId,
        redirectTo,
        setRedirectTo,
        token,
        setToken,
        userId,
        setUserId,
        userName,
        setUserName
    };

    return (
        <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
    );
};

export interface IAppContext {
    apolloClient: ApolloClient<NormalizedCacheObject>;
    organisationId: string;
    setOrganisationId: (value: string) => void;
    token: string;
    setToken: (value: string) => void;
    userId: string;
    setUserId: (value: string) => void;
    userName: string;
    setUserName: (value: string) => void;
    redirectTo: string;
    setRedirectTo: (value: string) => void;
}

export default AppContext;

function useAppRedirect() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("huh?");
    }
    const { redirectTo, setRedirectTo } = context;
    return { redirectTo, setRedirectTo };
}

export { useAppRedirect };
