import { Box, Button, Grid, Heading, Paragraph } from "grommet";
import React, { useState } from "react";
import { ICustomerBenefit } from "../../../../generated/dataInterfaces";
import AddBenefitDetail from "./AddBenefitDetail";
import BenefitItem from "./BenefitItem";

interface IPropsBenefits {
    benefits: ICustomerBenefit[];
    customerId: string;
}

export default ({ benefits, customerId }: IPropsBenefits) => {
    const [openAddCredential, setOpenAddCredential] = useState(false);

    const onAssignCredential = () => {
        setOpenAddCredential(true);
    };

    return benefits ? (
        <>
            <AddBenefitDetail
                customerId={customerId}
                open={openAddCredential}
                setOpen={setOpenAddCredential}
            />

            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "large"
                }}
            >
                <Grid columns={["flex", "small"]}>
                    <Heading level="3">Benefits</Heading>
                    <Button
                        alignSelf="end"
                        primary
                        color="accent-2"
                        onClick={onAssignCredential}
                    >
                        <Box pad="small" align="center">
                            Assign benefit
                        </Box>
                    </Button>
                </Grid>
                <Box flex="grow" direction="row-responsive">
                    <Box direction="row">
                        {benefits.length > 0 ? (
                            benefits.map((benefit) => (
                                <BenefitItem
                                    key={benefit.id}
                                    benefit={benefit}
                                />
                            ))
                        ) : (
                            <Paragraph>No benefits</Paragraph>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    ) : (
        <Box>Loading...</Box>
    );
};
