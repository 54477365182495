import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ActivitiesScreen from "./Screens/ActivitiesScreen";
import ActivityInstanceBookingsScreen from "./Screens/ActivityInstanceBookingsScreen";
import ActivityInstancesByActivityAndDateScreen from "./Screens/ActivityInstancesByActivityAndDateScreen";
import ActivityInstancesByResourceAndDateScreen from "./Screens/ActivityInstancesByResourceAndDateScreen";
import BookingScreen from "./Screens/BookingScreen";
import PackageScreen from "./Screens/PackageScreen";
import BookingSearchScreen from "./Screens/BookingSearchScreen";
import CustomerAccountScreen from "./Screens/CustomerAccountScreen";
import CustomerSearchScreen from "./Screens/CustomerSearchScreen";
import DashboardScreen from "./Screens/DashboardScreen";
import LoginScreen from "./Screens/LoginScreen";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen";
import ResourcesScreen from "./Screens/ResourcesScreen";
import PrivateRoute from "./Util/PrivateRoute";

const Routes = () => (
    <Router>
        <Switch>
            <PrivateRoute path="/" exact component={DashboardScreen} />
            <PrivateRoute
                path="/bookings"
                exact
                component={BookingSearchScreen}
            />
            <PrivateRoute
                path="/customers"
                exact
                component={CustomerSearchScreen}
            />
            <PrivateRoute
                path="/activities"
                exact
                component={ActivitiesScreen}
            />
            <PrivateRoute
                path="/activities/:id"
                exact
                component={ActivityInstancesByActivityAndDateScreen}
            />
            <PrivateRoute
                path="/packages/:id"
                exact
                component={PackageScreen}
            />
            <PrivateRoute
                path="/activities/instance/:id"
                exact
                component={ActivityInstanceBookingsScreen}
            />
            <PrivateRoute path="/resources" exact component={ResourcesScreen} />
            <PrivateRoute
                path="/resource/:id"
                exact
                component={ActivityInstancesByResourceAndDateScreen}
            />
            <PrivateRoute
                path="/customer/:id"
                exact
                component={CustomerAccountScreen}
            />
            <PrivateRoute path="/booking/:id" exact component={BookingScreen} />
            <Route path="/login" exact component={LoginScreen} />
            <Route
                path="/password-reset/:secToken"
                exact
                component={ResetPasswordScreen}
            />
        </Switch>
    </Router>
);

export default Routes;
