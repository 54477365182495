import { useQuery } from "@apollo/client";
import { Box, Heading } from "grommet";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { INSTANCES_BY_DATE_RANGE } from "../../data/Activities/queries";
import { IDateRangeByActivity } from "../../generated/dataInterfaces";
import DayInstanceItem from "../Activities/DayInstanceItem";
import WeekSelectCalendarDropButton from "../Activities/WeekSelectCalendarDropButton";
import { SmallLink } from "../Icons/LinkIndicators";
import { Package } from "../Icons/Package";
import Page from "../Page";
import EmptyPage from "./EmptyScreen";

export default ({ match }: { match: { params: { id: string } } }) => {
    const { id } = match.params;
    const [dateRangeByActivity, setDateRangeByActivity] =
        useState<IDateRangeByActivity>(null);
    const [shouldLoadData, setShouldLoadData] = useState(true);
    const [[startDate, endDate], setDates] = useState([
        moment.utc().startOf("day").toDate(),
        moment.utc().endOf("isoWeek").toDate()
    ]);

    const { data, loading } = useQuery(INSTANCES_BY_DATE_RANGE, {
        variables: {
            activityId: id,
            startDate,
            endDate
        },
        skip: !shouldLoadData,
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (data && data.instancesByDateRange && !loading) {
            setShouldLoadData(false);
            setDateRangeByActivity(
                data.instancesByDateRange as IDateRangeByActivity
            );
            toast("Date range updated");
        }
    }, [data, loading]);

    const setDatesAndReload = ([startDate, endDate]) => {
        setDates([startDate, endDate]);
        setShouldLoadData(true);
    };

    return dateRangeByActivity ? (
        <Page title="Activity calendar">
            <Box
                direction="row-responsive"
                border="bottom"
                margin={{
                    top: "none",
                    left: "small",
                    right: "small",
                    bottom: "none"
                }}
                pad={{
                    top: "none",
                    left: "small",
                    right: "small",
                    bottom: "none"
                }}
            >
                <Box direction="column" flex="grow" pad="none" margin="none ">
                    <Link
                        to={`/activities/#${dateRangeByActivity.activity.resource.id}`}
                    >
                        <Heading level="2">
                            {dateRangeByActivity.activity.resource.name}{" "}
                            <SmallLink />
                        </Heading>
                    </Link>

                    <Heading level="3" margin={{ top: "none" }}>
                        {dateRangeByActivity.activity.name}
                    </Heading>

                    {dateRangeByActivity.activity.isPackaged ? (
                        <ul style={{ marginTop: 0 }}>
                            {dateRangeByActivity.activity.packages.map((p) => (
                                <Link
                                    key={p.id}
                                    to={`/packages/${p.id}`}
                                    data-testid="package-link"
                                >
                                    <li style={{ listStyleType: "none" }}>
                                        <Package /> {p.name}
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    ) : null}
                </Box>
                <Box direction="column" align="end">
                    <WeekSelectCalendarDropButton
                        dates={[startDate, endDate]}
                        setDates={setDatesAndReload}
                    />
                </Box>
            </Box>

            <Box direction="column">
                {dateRangeByActivity && dateRangeByActivity.days.length > 0 ? (
                    dateRangeByActivity.days.map((day, index) => (
                        <DayInstanceItem
                            key={index}
                            day={day}
                            activityId={id}
                            showActivity={false}
                            showResource={false}
                        />
                    ))
                ) : (
                    <Box pad="medium">Nothing scheduled this week</Box>
                )}
            </Box>
        </Page>
    ) : (
        <EmptyPage />
    );
};
