import { Box, Grid, Button, Heading, Layer, Paragraph, Text } from "grommet";
import React, { useState } from "react";

interface IPropsFieldValue {
    label: string;
    value: string;
}

export default ({ label, value }: IPropsFieldValue) => {
    return (
        <Grid
            columns={["small", "flex"]}
            gap="small"
            margin={{ bottom: "medium" }}
        >
            <Box margin={{ right: "small" }}>
                <strong>{label}:</strong>
            </Box>
            <Box>{value}</Box>
        </Grid>
    );
};
