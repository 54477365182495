import { Accordion, Box } from "grommet";
import React, { useState } from "react";
import { IPropsBookingAccordian } from "./bookingAccordianInterfaces";
import BookingAccordianPanel from "./BookingAccordianPanel";

export default ({
    resultInfo,
    activeIndex,
    ...otherProps
}: IPropsBookingAccordian) => {
    const [activePanelIndex, setActivePanelIndex] = useState(activeIndex);

    return (
        <Box background="white">
            {/* Active index: {activePanelIndex} */}
            <Accordion
                animate={true}
                activeIndex={activePanelIndex}
                onActive={(newActiveIndex) =>
                    setActivePanelIndex(newActiveIndex)
                }
            >
                {resultInfo.map((panel, index) => (
                    <BookingAccordianPanel
                        key={index}
                        isActive={index === activeIndex}
                        panel={panel}
                        {...otherProps}
                    />
                ))}
            </Accordion>
        </Box>
    );
};
