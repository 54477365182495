import { useMutation } from "@apollo/client";
import _ from "lodash";
import React, { createContext, useMemo, useState } from "react";
import { DIRECT_BOOKING } from "../../data/Bookings/mutations";
import {
    IActivityInstance,
    IBooking,
    ICustomer,
    IDirectBookingResponse
} from "../../generated/dataInterfaces";

const BookingContext = createContext<IBookingContext>(null);

export interface IExtraToBook {
    extraId: string;
    quantity: number;
    priceEach: number;
}

function BookingContextProvider(props) {
    const [customer, setCustomer] = useState<ICustomer>();
    const [instanceToBook, setInstanceToBook] = useState<IActivityInstance>();
    const [ticketQty, setTicketQty] = useState<number>(0);
    const [extrasToBook, setExtrasToBook] = useState<IExtraToBook[]>([]);
    const [note, setNote] = useState<string>("");
    const [total, setTotal] = useState<number>(null);
    const [initialPayment, setInitialPayment] = useState<number>(null);
    const [shouldSendEmails, setShouldSendEmails] = useState<boolean>(true);
    const [booking, setBooking] = useState<IBooking>(null);
    const [bookingNames, setBookingNames] = useState<string[]>([]);

    const setInstanceToBookExtended = (instance: IActivityInstance) => {
        setInstanceToBook(instance);
        setExtrasToBook(
            instance.activity.resource.extras.map((extra) => {
                return {
                    extraId: extra.id,
                    quantity: 0,
                    priceEach: extra.price
                };
            })
        );
    };

    const setTotalExtended = (total: number) => {
        console.log("BookingContext setTotalExtended", total);
        setTotal(total);
    };

    const [makeBooking] =
        useMutation<{ directBooking: IDirectBookingResponse }>(DIRECT_BOOKING);

    const values: IBookingContext = useMemo(() => {
        const calculateTotal = () => {
            console.log("calculateTotal");
            let tot = instanceToBook
                ? ticketQty * instanceToBook.pricePerBooking
                : 0;
            if (extrasToBook && extrasToBook.length > 0) {
                for (const e of extrasToBook) {
                    tot += e.priceEach * e.quantity;
                }
            }

            return tot;
        };

        const updateExtra = (id: string, qty: number) => {
            const index = extrasToBook.findIndex((s) => s.extraId === id);
            if (index > -1) {
                const clone = _.clone(extrasToBook);
                clone[index].quantity = qty;
                setExtrasToBook(clone);
            }
        };

        const makeBookingExtended = async (nonce) => {
            const extras = extrasToBook.map((e) => {
                return {
                    extraId: e.extraId,
                    quantity: e.quantity
                };
            });

            console.log("BookingContext makeBookingExtended total", total);
            console.log(
                "BookingContext makeBookingExtended initialPayment",
                initialPayment
            ); // 36

            const { data } = await makeBooking({
                variables: {
                    customerId: customer.id,
                    activityInstanceId: instanceToBook.id,
                    quantity: ticketQty,
                    total,
                    initialPayment,
                    note,
                    sendEmails: shouldSendEmails,
                    extras,
                    braintreeNonce: nonce,
                    bookingNames
                }
            });

            if (data && data.directBooking && data.directBooking.isSuccessful) {
                setBooking(data.directBooking.bookings[0]);
                setBookingNames([]);
                setTicketQty(0);
                return data.directBooking.bookings[0];
            } else {
                throw new Error(data.directBooking.errorMessage);
            }
        };

        const setTicketQtyExtended = (value: number) => {
            if (value < 0) {
                setTicketQty(0);
            } else {
                setTicketQty(value);
            }
        };

        return {
            customer,
            setCustomer,
            instanceToBook,
            setInstanceToBook: setInstanceToBookExtended,
            ticketQty,
            setTicketQty: setTicketQtyExtended,
            calculateTotal,
            extrasToBook,
            updateExtra,
            note,
            setNote,
            shouldSendEmails,
            setShouldSendEmails,
            total,
            setTotal: setTotalExtended,
            initialPayment,
            setInitialPayment,
            makeBooking: makeBookingExtended,
            booking,
            bookingNames,
            setBookingNames
        };
    }, [
        customer,
        instanceToBook,
        ticketQty,
        extrasToBook,
        shouldSendEmails,
        booking,
        total,
        initialPayment,
        note,
        makeBooking,
        bookingNames
    ]);

    return <BookingContext.Provider value={values} {...props} />;
}

export interface IBookingContext {
    customer: ICustomer;
    setCustomer: (value: ICustomer) => void;
    instanceToBook: IActivityInstance;
    setInstanceToBook: (value: IActivityInstance) => void;
    ticketQty: number;
    setTicketQty: (value: number) => void;
    calculateTotal: () => number;
    extrasToBook: IExtraToBook[];
    updateExtra: (id: string, qty: number) => void;
    note: string;
    setNote: (value: string) => void;
    shouldSendEmails: boolean;
    setShouldSendEmails: (value: boolean) => void;
    total: number;
    setTotal: (value: number) => void;
    initialPayment: number;
    setInitialPayment: (value: number) => void;
    makeBooking: (nonce: string) => Promise<IBooking>;
    booking: IBooking;
    bookingNames: string[];
    setBookingNames: (value: string[]) => void;
}

export default BookingContext;
export { BookingContextProvider };
