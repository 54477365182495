import { Box, Button, Clock, DropButton, Grid, Image, Text } from "grommet";
import React, { useContext } from "react";
import { Slide, ToastContainer } from "react-toastify";
import AppContext from "./Context/AppContext";
import useStateWithLocalStorage from "./Custom/useStateWithLocalStorage";
import Picker from "./Facilities/FacilityPicker";
import { SmallLink } from "./Icons/LinkIndicators";
import Nav from "./Nav/";

const Page = ({ children, ...rest }) => {
    const [sideBarVisible, setSideBarVisible] =
        useStateWithLocalStorage<boolean>();
    const { userName, setToken, setRedirectTo } = useContext(AppContext);

    const logOut = () => {
        setToken(null);
        setRedirectTo("/login");
    };

    const renderDropdown = () => (
        <Box background="brand" pad="small">
            <Button onClick={() => logOut()}>
                <Text color="white">Log out</Text>
            </Button>
        </Box>
    );

    return (
        <Grid
            fill
            rows={["auto", "flex"]}
            columns={["auto", "flex"]}
            gap="xsmall"
            areas={[
                { name: "header", start: [0, 0], end: [1, 0] },
                { name: "sidebar", start: [0, 1], end: [0, 1] },
                { name: "main", start: [1, 1], end: [1, 1] }
            ]}
        >
            <Box
                gridArea="header"
                direction="row"
                align="center"
                justify="between"
                pad={{ horizontal: "small", vertical: "small" }}
                background="dark-2"
            >
                <Button
                    onClick={() => setSideBarVisible(!sideBarVisible)}
                    data-testid="menu-button"
                >
                    <Text size="xxlarge">
                        {rest.title ? rest.title : "[Open menu]"} <SmallLink />
                    </Text>
                </Button>
                <Image
                    width="100px"
                    src="https://www.lesormesjersey.co.uk/assets/images/logo-white.svg"
                />
                <Picker />
                <Box align="end">
                    <DropButton
                        alignSelf="center"
                        margin={{ vertical: "small" }}
                        dropContent={renderDropdown()}
                        dropProps={{ align: { top: "bottom" } }}
                    >
                        <Text>
                            <SmallLink /> {userName}
                        </Text>
                    </DropButton>
                    <Clock type="digital" />
                </Box>
            </Box>
            {sideBarVisible && <Nav />}
            <Box
                fill="vertical"
                pad={{
                    left: "none",
                    top: "large",
                    right: "none",
                    bottom: "large"
                }}
                margin="none"
                gridArea="main"
                background="light-1"
                className="texture"
            >
                {children}
            </Box>
            <ToastContainer
                autoClose={4000}
                transition={Slide}
                position="bottom-right"
                bodyClassName="toastify"
                hideProgressBar={true}
                progressStyle={{ background: "brand" }}
            />
        </Grid>
    );
};

export default Page;
