import { Box, Grid, Heading, Meter, Paragraph, Stack, Text } from "grommet";
import React from "react";
import { Link } from "react-router-dom";
import { IActivityInstance } from "../../generated/dataInterfaces";
import { SmallLink } from "../Icons/LinkIndicators";
import { Package } from "../Icons/Package";

interface IPropsActivityInstance {
    instance: IActivityInstance;
    showActivity: boolean;
    showResource: boolean;
    showDate?: boolean;
    showPrice?: boolean;
    hideBookingLink?: boolean;
    otherProps?: any;
}

export default ({
    instance,
    showActivity,
    showResource,
    showDate,
    showPrice,
    hideBookingLink,
    ...otherProps
}: IPropsActivityInstance) => {
    const {
        quantityAvailable,
        maxCapacity,
        quantityUnavailable,
        availabilityText,
        id,
        timeFromToText,
        checkedInText
    } = instance;

    let details = null;
    if (showActivity || showResource) {
        const activityId = instance.activity.id;
        const activityName = instance.activity.name;
        const isPackaged = instance.activity.isPackaged;
        const date =
            instance && instance.daySchedule && instance.daySchedule.dayText;
        const resourceId =
            instance.activity &&
            instance.activity.resource &&
            instance.activity.resource.id;
        const resourceName =
            instance.activity &&
            instance.activity.resource &&
            instance.activity.resource.name;

        details = (
            <Box>
                {showResource ? (
                    <Link to={`/resource/${resourceId}`}>
                        <Heading
                            level="3"
                            margin={{ bottom: "xsmall", top: "xsmall" }}
                        >
                            {resourceName} <SmallLink />
                        </Heading>
                    </Link>
                ) : null}

                {showActivity ? (
                    <Link to={`/activities/${activityId}`}>
                        <Heading
                            level="4"
                            margin={{ bottom: "xsmall", top: "xsmall" }}
                        >
                            {isPackaged ? <Package /> : null} {activityName}{" "}
                            <SmallLink />
                        </Heading>
                    </Link>
                ) : null}

                {showDate ? (
                    <Heading
                        level="4"
                        margin={{ bottom: "none", top: "small" }}
                    >
                        {date}
                    </Heading>
                ) : null}
            </Box>
        );
    }

    const percentage =
        quantityAvailable <= 0
            ? 100
            : parseFloat(
                  ((quantityUnavailable / maxCapacity) * 100).toFixed(0)
              );

    let mainBody = (
        <>
            <Heading level="3" margin={{ top: "small", bottom: "small" }}>
                {timeFromToText}
            </Heading>

            <Grid
                columns={{
                    count: 2,
                    size: "auto"
                }}
                gap="small"
            >
                <Box>
                    <Paragraph color="text" margin="none">
                        {availabilityText}
                    </Paragraph>
                    <Paragraph
                        color="text"
                        margin={{ top: "small", bottom: "none" }}
                    >
                        {checkedInText}
                    </Paragraph>

                    {showPrice ? (
                        <Heading
                            level="2"
                            margin={{ top: "small", bottom: "none" }}
                        >
                            £{instance.pricePerBooking.toFixed(2)}
                        </Heading>
                    ) : null}
                </Box>
                <Stack anchor="center">
                    <Box direction="column" align="center">
                        <Meter
                            type="circle"
                            background="light-2"
                            values={[{ value: percentage, label: "" }]}
                            size="xsmall"
                            thickness="small"
                        />
                    </Box>
                    <Box
                        direction="row"
                        align="center"
                        pad={{ bottom: "xsmall" }}
                    >
                        <Text size="large" weight="bold">
                            {percentage}
                        </Text>
                        <Text size="small">%</Text>
                        <Text size="xsmall">&nbsp;full</Text>
                    </Box>
                </Stack>
            </Grid>

            {hideBookingLink ? null : (
                <Text color="accent-3" size="small">
                    VIEW BOOKINGS
                </Text>
            )}
        </>
    );

    if (!hideBookingLink) {
        mainBody = (
            <Link
                to={`/activities/instance/${id}`}
                data-testid="view-bookings-link"
            >
                {mainBody}
            </Link>
        );
    }

    return (
        <Box
            margin="xsmall"
            width="medium"
            pad="small"
            border="all"
            round="small"
            elevation="small"
            flex={false}
            responsive={true}
            background="white"
            id={instance.id}
        >
            {details}

            {mainBody}
        </Box>
    );
};
