import { Box, Button, Grid, Heading, Image, Layer, Text } from "grommet";
import { Close } from "grommet-icons";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SmallLink } from "../../../components/Icons/LinkIndicators";
import { BOOKING_CHECK_IN } from "../../../data/Bookings/mutations";
import { BOOKING_FULL_DETAILS } from "../../../data/Bookings/queries";
import { IBooking } from "../../../generated/dataInterfaces";
import AppContext from "../../Context/AppContext";
import BookingListContext from "../../Context/BookingListContext";

interface IPropsBookingMembershipCheckin {
    bookingId: string;
    open: boolean;
    setOpen: any;
    otherProps?: any;
}

export default ({
    open,
    setOpen,
    bookingId
}: IPropsBookingMembershipCheckin) => {
    const [booking, setBooking] = useState<IBooking>();
    const [shouldLoadNow, setShouldLoadNow] = useState<boolean>();
    const [currentBookingId, setCurrentBookingId] = useState<string>();
    const [isCancelled, setIsCancelled] = useState<boolean>();
    const [canCancel, setCanCancel] = useState<boolean>();
    const [canCheckIn, setCanCheckIn] = useState<boolean>();
    const [isCheckedIn, setIsCheckedIn] = useState<boolean>();
    const [checkedInAt, setCheckedInAt] = useState<Date>();
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);

    const { userId } = useContext(AppContext);

    if (currentBookingId !== bookingId && bookingId) {
        setShouldLoadNow(true);
        setCurrentBookingId(bookingId);
    }

    const setBookingExtended = (booking: IBooking) => {
        setBooking(booking);
        setCheckedInAt(booking.checkedInAt);
        setCanCheckIn(!booking.isCheckedIn && !isCancelled);
    };

    const calcIsCancelled = (booking) =>
        booking && booking.bookingStatus === "CANCELLED";
    const calcCanCancel = (booking) =>
        booking &&
        booking.bookingStatus !== "CANCELLED" &&
        !booking.isCheckedIn &&
        moment(booking.activityStartsAt).utc().isAfter(moment.utc());

    const onClose = () => {
        open = false;
        setOpen(false);
    };

    const [mutateCheckIn] = useMutation(BOOKING_CHECK_IN);
    const onBookingCheckIn = async () => {
        const result = await mutateCheckIn({
            variables: { bookingId, userId }
        });
        const booking = result.data["checkIn"];
        setIsCheckedIn(booking.isCheckedIn);
        setCheckedInAt(booking.checkedInAt);
        setCanCancel(false);
        toast("Checked in");

        setShouldRefresh(true);
    };

    const { data, error, loading } = useQuery(BOOKING_FULL_DETAILS, {
        variables: {
            bookingId: currentBookingId
        },
        skip: !shouldLoadNow,
        fetchPolicy: "no-cache"
    });

    const hasData = data && data.booking;
    if (hasData) {
        setShouldLoadNow(false);
        const booking: IBooking = data.booking;
        setBookingExtended(booking);
    }

    const formatDateTime = (dateTime: any) => {
        return moment(dateTime).utc().format("DD/MM/YYYY HH:mm");
    };

    const imgStyle = {
        width: "97%",
        marginRight: "3%",
        marginBottom: "3%",
        maxHeight: "200px"
    };

    return open && booking ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box
                as="form"
                fill="vertical"
                overflow="auto"
                width="large"
                pad="medium"
                onSubmit={onClose}
            >
                <Box flex={false} direction="row" justify="between">
                    <Heading level="2" margin="bottom">
                        {booking.name}{" "}
                    </Heading>
                    <Heading level="2" color="accent-2" margin="bottom">
                        {booking.reference}{" "}
                    </Heading>
                    <Button icon={<Close />} onClick={onClose} />
                </Box>
                <Box direction="column" margin={{ bottom: "large" }}>
                    <Grid columns={["flex", "flex"]} gap="small">
                        <Box flex key="checkin-cancel-left">
                            {isCheckedIn ? (
                                <Text size="medium" color="accent-2">
                                    Checked in: {formatDateTime(checkedInAt)}
                                </Text>
                            ) : canCheckIn ? (
                                <Button
                                    label="Not checked in"
                                    color="status-warning"
                                    type="button"
                                    onClick={onBookingCheckIn}
                                />
                            ) : null}
                        </Box>
                    </Grid>
                </Box>
                <Box
                    flex={false}
                    direction="column"
                    margin={{ bottom: "medium" }}
                >
                    <Heading level="3" alignSelf="stretch">
                        Memberships ({booking.customerMemberships.length})
                    </Heading>

                    <Grid columns={["flex", "flex", "flex"]}>
                        {booking.customerMemberships.map((membership) => (
                            <Box>
                                <Image
                                    style={imgStyle}
                                    fit="contain"
                                    src={membership.photo.base64}
                                />
                                <Heading margin="small" level="4">
                                    {membership.name}
                                </Heading>
                                {membership.customer &&
                                    membership.customer.id !==
                                        booking.customer.id && (
                                        <Heading margin="small" level="5">
                                            Linked from{" "}
                                            {membership.customer.name}
                                        </Heading>
                                    )}
                            </Box>
                        ))}
                    </Grid>
                </Box>

                <Box flex={false} direction="row" justify="between">
                    <Heading
                        alignSelf="stretch"
                        level="3"
                        margin={{ bottom: "small" }}
                    >
                        <Text size="xxlarge">{booking.activityQuantity}</Text>
                        <Text size="large" margin={{ horizontal: "small" }}>
                            x
                        </Text>
                        <Link
                            to={`/resource/${booking.activityInstance.activity.resource.id}`}
                        >
                            {booking.resourceName}
                            <SmallLink />
                        </Link>
                        {` / `}
                        <Link
                            to={`/activities/${booking.activityInstance.activity.id}`}
                        >
                            {booking.activityName}
                            <SmallLink />
                        </Link>
                        <br />
                    </Heading>
                </Box>

                <Box
                    flex={false}
                    direction="row"
                    justify="between"
                    margin={{ bottom: "medium" }}
                >
                    <Heading
                        alignSelf="stretch"
                        level="3"
                        margin={{ bottom: "small" }}
                    >
                        <Link
                            to={`/activities/instance/${booking.activityInstance.id}`}
                        >
                            {booking.activityInstance.daySchedule.dayText}{" "}
                            {booking.activityInstance.timeFromToText}
                        </Link>
                        <SmallLink />
                    </Heading>
                </Box>

                {isCancelled ? (
                    <Heading level="2" color="status-error">
                        BOOKING IS CANCELLED
                    </Heading>
                ) : null}
            </Box>
        </Layer>
    ) : null;
};
