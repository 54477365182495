import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Heading, Layer } from "grommet";
import { Close } from "grommet-icons";
import moment from "moment";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import {
    REQUEST_PASSWORD_RESET_CREDENTIAL_MUTATION,
    TOGGLE_DISABLED_CREDENTIAL_MUTATION,
    UNLOCK_CREDENTIAL_MUTATION
} from "../../../../data/Credentials/mutations";
import { CREDENTIAL_FULL_DETAILS } from "../../../../data/Credentials/queries";
import { ICredential } from "../../../../generated/dataInterfaces";
import AppContext from "../../../Context/AppContext";
import FieldValue from "../../../Custom/FieldValue";

interface IPropsCredentialDetail {
    credentialId: string;
    open: boolean;
    setOpen: any;
}

export default ({ open, setOpen, credentialId }: IPropsCredentialDetail) => {
    const [credential, setCredential] = useState<ICredential>();
    const [shouldLoadNow, setShouldLoadNow] = useState(false);
    const [currentCredentialId, setCurrentCredentialId] = useState<string>();
    const [notification, setNotification] = useState<string>();

    const { organisationId } = useContext(AppContext);

    if (currentCredentialId !== credentialId) {
        setShouldLoadNow(true);
        setCurrentCredentialId(credentialId);
    }

    const onClose = () => {
        open = false;
        setOpen(false);
    };

    const { data, error, loading } = useQuery(CREDENTIAL_FULL_DETAILS, {
        variables: {
            credentialId
        },
        skip: !shouldLoadNow,
        fetchPolicy: "no-cache"
    });

    const hasData = data && data.credential;
    if (hasData) {
        setShouldLoadNow(false);
        setCredential(data.credential as ICredential);
    }

    const [mutateToggleDisabled] = useMutation(
        TOGGLE_DISABLED_CREDENTIAL_MUTATION
    );
    const [mutateUnlock] = useMutation(UNLOCK_CREDENTIAL_MUTATION);
    const [mutateRequestPasswordReset] = useMutation(
        REQUEST_PASSWORD_RESET_CREDENTIAL_MUTATION
    );

    const toggleDisabled = async () => {
        const result = await mutateToggleDisabled({
            variables: { credentialId: currentCredentialId }
        });
        setCredential(result.data["toggleCredential"]);
        toast(
            `Credential was ${credential.isDisabled ? "enabled" : "disabled"}`
        );
    };

    const unlock = async () => {
        const result = await mutateUnlock({
            variables: { credentialId: currentCredentialId }
        });
        setCredential(result.data["unlockCredential"]);
        // setNotification(`Credential was unlocked`);
        toast("Credential was unlocked");
        const timeout = setTimeout(() => setNotification(null), 3000);
    };

    const passwordReset = async () => {
        const result = await mutateRequestPasswordReset({
            variables: {
                email: credential.email,
                organisationId
            }
        });
        // setNotification(`An email has been sent to ${credential.email}`);
        const timeout = setTimeout(() => setNotification(null), 3000);
        toast(`An email has been sent to ${credential.email}`);
    };

    const formatDateTime = (dateTime: any) => {
        return moment(dateTime).format("DD/MM/YYYY HH:mm");
    };

    return open && credential ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box
                as="form"
                fill="vertical"
                overflow="auto"
                width="large"
                pad="medium"
                round="none"
                onSubmit={onClose}
            >
                <Box flex={false} direction="row" justify="between">
                    <Heading level="3" margin="bottom">
                        {credential.firstName} {credential.lastName}
                    </Heading>

                    <Button icon={<Close />} onClick={onClose} />
                </Box>
                <Box flex={false} direction="row" justify="between">
                    <Heading
                        alignSelf="stretch"
                        level="4"
                        margin={{ top: "none", bottom: "small" }}
                    >
                        {credential.email} /{" "}
                        {credential.mobileNumber
                            ? credential.mobileNumber
                            : "no mobile"}
                    </Heading>
                </Box>
                <Box
                    flex={false}
                    direction="column"
                    justify="between"
                    margin={{ top: "medium", bottom: "small" }}
                >
                    <FieldValue
                        label="Last login"
                        value={
                            credential.lastLoginAt
                                ? moment(credential.lastLoginAt)
                                      .utc()
                                      .format("YYYY-MM-DD HH:mm")
                                : "Never"
                        }
                    />
                    <FieldValue
                        label="Is disabled?"
                        value={credential.isDisabled ? "Yes" : "No"}
                    />
                    <FieldValue
                        label="Is locked out?"
                        value={credential.isLockedOut ? "Yes" : "No"}
                    />
                    <FieldValue
                        label="Receive emails?"
                        value={credential.doesReceiveEmails ? "Yes" : "No"}
                    />
                    <FieldValue
                        label="Receive SMS?"
                        value={credential.doesReceiveSms ? "Yes" : "No"}
                    />
                </Box>

                <Box direction="row" align="center" gap="small">
                    <Button
                        primary
                        color="light-2"
                        label={
                            credential.isDisabled ? "Enable now" : "Disable now"
                        }
                        onClick={() => {
                            toggleDisabled();
                        }}
                    />
                    {credential.isLockedOut ? (
                        <Button
                            primary
                            color="light-2"
                            label="Unlock account"
                            onClick={() => {
                                unlock();
                            }}
                        />
                    ) : null}
                    <Button
                        primary
                        color="light-2"
                        label="Send reset password email"
                        onClick={() => {
                            passwordReset();
                        }}
                    />
                </Box>
                {/* {notification ? (
                    <Box
                        background="status-ok"
                        margin={{ top: "medium" }}
                        pad="small"
                        round="small"
                    >
                        <Text color="white">{notification}</Text>
                    </Box>
                ) : null} */}
            </Box>
        </Layer>
    ) : null;
};
