import { gql } from "@apollo/client";

export const ACTIVITY_SUMMARIES_BY_RESOURCE_BY_FACILITY = gql`
    query activitySummariesByResourceByFacility(
        $organisationId: ID!
        $facilityId: ID
    ) {
        facilitiesByOrg(
            input: { organisationId: $organisationId, facilityId: $facilityId }
        ) {
            id
            name
            resources(orderBy: ordering_ASC, where: { isDeleted_not: true }) {
                id
                name
                activities(
                    orderBy: ordering_ASC
                    where: { isDeleted_not: true }
                ) {
                    id
                    name
                    isPackaged
                }
            }
        }
    }
`;

export const RESOURCE_SUMMARIES_BY_FACILITY = gql`
    query activitySummariesByResourceByFacility(
        $organisationId: ID!
        $facilityId: ID
    ) {
        facilitiesByOrg(
            input: { organisationId: $organisationId, facilityId: $facilityId }
        ) {
            id
            name
            resources(orderBy: ordering_ASC, where: { isDeleted_not: true }) {
                id
                name
            }
        }
    }
`;

const ACTIVITY_INSTANCES_QUERY = `
quantityAvailable
maxCapacity
quantityUnavailable
availabilityText
checkedInText
id
timeFromToText
 activity{
  id
  name
  isPackaged
  packages {
      id
      name
  }
  resource{
    id
    name
  }
}
`;

export const ACTIVITY_INSTANCES_NOW_NEXT = gql`
    query instancesNowAndNext($organisationId: ID!, $facilityId: ID) {
        instancesNowAndNext(input: { organisationId: $organisationId, facilityId: $facilityId }) {
            now{
                ${ACTIVITY_INSTANCES_QUERY}
            }
            next{
                ${ACTIVITY_INSTANCES_QUERY}
            }
        }
    }
`;

export const INSTANCES_BY_DATE_RANGE = gql`
    query instancesByDateRange(
        $activityId: ID!
        $startDate: DateTime!
        $endDate: DateTime!
    ) {
        instancesByDateRange(
            input: {
                activityId: $activityId
                startDate: $startDate
                endDate: $endDate
            }
        ) {
            startDate
            endDate
            activity {
                id
                name
                isPackaged
                packages {
                    id
                    name
                }
                resource {
                    id
                    name
                }
            }
            activityId
            days {
                dayText
                date
                activityInstances {
                    startTime
                    endTime
                    id
                    timeFromToText
                    availabilityText
                    checkedInText
                    quantityAvailable
                    quantityUnavailable
                    maxCapacity
                    activity {
                        id
                    }
                }
            }
        }
    }
`;

export const ACTIVITY_INSTANCE_WITH_BOOKINGS = gql`
    query bookingsByActivityInstance($activityInstanceId: ID!) {
        bookingsByActivityInstance(
            input: { activityInstanceId: $activityInstanceId }
        ) {
            id
            timeFromToText
            startTime
            endTime
            availabilityText
            checkedInText
            pricePerBooking
            quantityAvailable
            quantityUnavailable
            maxCapacity
            daySchedule {
                dayText
            }
            package {
                id
                name
                activityInstances {
                    id
                    timeFromToText
                    daySchedule {
                        dayText
                    }
                }
            }
            activity {
                id
                name
                isPackaged
                isBookingNameRequired
                resource {
                    id
                    name
                    extras {
                        name
                        id
                        isAvailable
                        price
                    }
                }
            }
            bookings(where: { bookingStatus_not: CANCELLED }) {
                customer {
                    id
                }
                id
                name
                reference
                packageReference
                bookingNames
                bookingStatus
                peopleCount
                extrasCount
                total
                balance
                activityStartsAt
                isCheckedIn
                customerMemberships {
                    id
                    name
                    photo {
                        base64
                    }
                }
            }
        }
    }
`;
