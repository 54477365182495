import { Box } from "grommet";
import React from "react";
import ResetPassword from "../Login/ResetPassword";

const ResetPasswordScreen = ({
    match
}: {
    match: { params: { secToken: string } };
}) => {
    const { secToken } = match.params;
    return (
        <Box
            fill="horizontal"
            pad={{
                left: "none",
                top: "large",
                right: "none",
                bottom: "large"
            }}
            gridArea="main"
            background="light-1"
            className="texture"
        >
            <ResetPassword token={secToken} />
        </Box>
    );
};

export default ResetPasswordScreen;
