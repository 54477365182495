import {
    Box,
    Button,
    Form,
    FormField,
    Grid,
    Heading,
    Select,
    TextArea
} from "grommet";
import React, { useContext, useState } from "react";
import { IBooking, INote } from "../../../../generated/dataInterfaces";
import AppContext from "../../../Context/AppContext";
import Notes from "./Notes";
import { useMutation } from "@apollo/client";
import { ADD_MIDADMIN_NOTE_BOOKING_MUTATION } from "../../../../data/Bookings/mutations";
import { toast } from "react-toastify";

interface IPropsBookingNotes {
    booking: IBooking;
}

export default ({ booking }: IPropsBookingNotes) => {
    const [midAdminNotes, setMidAdminNotes] = useState<INote[]>(
        booking.midAdminNotes
    );
    const { userId } = useContext(AppContext);
    const [mutateNote] = useMutation(ADD_MIDADMIN_NOTE_BOOKING_MUTATION);

    const addNote = async (content) => {
        const result = await mutateNote({
            variables: {
                bookingId: booking.id,
                content,
                noteType: "ACTIVITIES"
            }
        });
        const note = result.data["addMidAdminNoteToBooking"];
        // dunno, just add it?
        setMidAdminNotes([...midAdminNotes, note]);
        toast("Note added. Customer will not see this note.");
    };

    return booking ? (
        <Grid
            columns={{
                count: 2,
                size: "auto"
            }}
            gap="none"
        >
            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "small"
                }}
            >
                <Box direction="column">
                    <Heading level="3" margin={{ bottom: "small" }}>
                        Admin notes (added by you)
                    </Heading>
                    <Notes notes={midAdminNotes} />
                </Box>
                <Box direction="column">
                    <Heading level="6" margin={{ bottom: "small" }}>
                        Add a new note
                    </Heading>
                    <Form
                        onSubmit={({ value }) => {
                            // not ideal!!! erroring, but shouldn't
                            // https://storybook.grommet.io/?path=/story/form--all
                            const { content } = value;
                            if (content) {
                                addNote(content);
                                value.content = "";
                            }
                        }}
                    >
                        <FormField
                            label=""
                            placeholder="Add your note here"
                            name="content"
                            component={TextArea}
                        />
                        <Box direction="row" margin={{ top: "medium" }}>
                            <Button
                                label="Add note"
                                color="accent-1"
                                type="submit"
                                primary
                            />
                        </Box>
                    </Form>
                </Box>
            </Box>
            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "small"
                }}
            >
                <Box direction="column">
                    <Heading level="3" margin={{ bottom: "small" }}>
                        Booking notes (added by them)
                    </Heading>
                    <Notes notes={booking.customerNotes} />
                </Box>
            </Box>
        </Grid>
    ) : (
        <Box>Loading...</Box>
    );
};
