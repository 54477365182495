import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
    Box,
    Button,
    Grid,
    Heading,
    Image,
    Layer,
    Paragraph,
    Text
} from "grommet";
import { Close } from "grommet-icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SmallLink } from "../../../components/Icons/LinkIndicators";
import {
    BOOKING_CANCEL,
    BOOKING_CHECK_IN,
    BOOKING_PACKAGE_CANCEL,
    BOOKING_RESEND_EMAILS
} from "../../../data/Bookings/mutations";
import { BOOKING_FULL_DETAILS } from "../../../data/Bookings/queries";
import AppContext from "../../Context/AppContext";

interface IPropsBookingDetail {
    bookingId: string;
    open: boolean;
    setOpen: any;
    otherProps?: any;
}

export default ({ open, setOpen, bookingId }: IPropsBookingDetail) => {
    const [isCancelled, setIsCancelled] = useState<boolean>();
    const [canCancel, setCanCancel] = useState<boolean>();
    const [canCheckIn, setCanCheckIn] = useState<boolean>();
    const [isCheckedIn, setIsCheckedIn] = useState<boolean>();
    const [isPackagedBooking, setIsPackagedBooking] = useState<boolean>();
    const [checkedInAt, setCheckedInAt] = useState<Date>();

    const { userId } = useContext(AppContext);

    const { data, refetch: refetchBooking } = useQuery(BOOKING_FULL_DETAILS, {
        variables: {
            bookingId
        },
        skip: !bookingId,
        fetchPolicy: "no-cache"
    });

    const booking = data && data.booking;

    useEffect(() => {
        if (booking) {
            const isCancelled = calcIsCancelled(booking);
            setIsCancelled(isCancelled);
            setCanCancel(calcCanCancel(booking));
            setIsCheckedIn(booking.isCheckedIn);
            setCheckedInAt(booking.checkedInAt);
            setCanCheckIn(!booking.isCheckedIn && !isCancelled);
            setIsPackagedBooking(!!booking.package);
        }
    }, [booking]);

    const calcIsCancelled = (booking) =>
        Boolean(booking && booking.bookingStatus === "CANCELLED");
    const calcCanCancel = (booking) =>
        Boolean(
            booking &&
                booking.bookingStatus !== "CANCELLED" &&
                !booking.isCheckedIn &&
                moment(booking.activityStartsAt).utc().isAfter(moment.utc())
        );

    const onClose = () => {
        open = false;
        setOpen(false);
    };

    const [mutateCancelBooking] = useMutation(BOOKING_CANCEL);
    const onBookingCancel = async () => {
        const result = await mutateCancelBooking({
            variables: {
                bookingId,
                note: ""
            }
        });
        const booking = result.data["cancelBooking"];
        setIsCancelled(calcIsCancelled(booking));
        setCanCancel(calcCanCancel(booking));
        setCanCheckIn(false);
        toast("Cancelled. Email sent to customer");
    };

    const [mutateCancelPackageBooking] = useMutation(BOOKING_PACKAGE_CANCEL);
    const onPackageBookingCancel = async () => {
        const result = await mutateCancelPackageBooking({
            variables: {
                bookingId,
                note: ""
            }
        });
        // still return the original booking
        const booking = result.data["cancelAllBookingsInPackage"];
        setIsCancelled(calcIsCancelled(booking));
        setCanCancel(calcCanCancel(booking));
        setCanCheckIn(false);
        toast(
            "All bookings in package are now cancelled. Email sent to customer"
        );

        await refetchBooking();
    };

    const [mutateCheckIn] = useMutation(BOOKING_CHECK_IN);
    const onBookingCheckIn = async () => {
        const result = await mutateCheckIn({
            variables: { bookingId, userId }
        });
        const booking = result.data["checkIn"];
        setIsCheckedIn(booking.isCheckedIn);
        setCheckedInAt(booking.checkedInAt);
        setCanCancel(false);
        toast("Checked in");
    };

    const [mutateResendEmails] = useMutation(BOOKING_RESEND_EMAILS);
    const onBookingResendEmails = async () => {
        const result = await mutateResendEmails({
            variables: {
                bookingId
            }
        });
        const success = result.data["resendBookingEmails"] as boolean;
        if (success) {
            toast("Customer has been resent their confirmation email");
        } else {
            toast("There was a problem. No emails sent");
        }
    };

    const formatDateTime = (dateTime: any) => {
        return moment(dateTime).format("DD/MM/YYYY HH:mm");
    };

    const imgStyle = {
        width: "97%",
        marginRight: "3%",
        marginBottom: "3%",
        maxHeight: "120px"
    };

    return open && booking ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box
                as="form"
                fill="vertical"
                overflow="auto"
                width="large"
                pad="medium"
                onSubmit={onClose}
            >
                <Box flex={false} direction="row" justify="between">
                    <Heading level="2" margin="bottom">
                        {booking.name}{" "}
                        {booking.bookingNames && booking.bookingNames.length ? (
                            <>
                                <br />
                                <small>
                                    Name(s): {booking.bookingNames.join(", ")}
                                </small>
                            </>
                        ) : null}
                    </Heading>
                    <Heading level="2" color="accent-2" margin="bottom">
                        {booking.reference}{" "}
                    </Heading>
                    <Button icon={<Close />} onClick={onClose} />
                </Box>
                {isPackagedBooking ? (
                    <Box flex={false} direction="row" justify="between">
                        <Heading level="4" color="dark-3" margin="bottom">
                            This is a package booking for <br />
                            {booking.package.name}
                        </Heading>
                        <Heading level="3" color="accent-2" margin="bottom">
                            {booking.packageReference ? (
                                <>({booking.packageReference})</>
                            ) : null}
                        </Heading>
                    </Box>
                ) : null}
                <Box flex={false} direction="row" justify="between">
                    <Heading
                        alignSelf="stretch"
                        level="3"
                        margin={{ bottom: "small" }}
                    >
                        <Text size="xxlarge">{booking.activityQuantity}</Text>
                        <Text size="large" margin={{ horizontal: "small" }}>
                            x
                        </Text>
                        <Link
                            to={`/resource/${booking.activityInstance.activity.resource.id}`}
                        >
                            {booking.resourceName}
                            <SmallLink />
                        </Link>
                        {` / `}
                        <Link
                            to={`/activities/${booking.activityInstance.activity.id}`}
                        >
                            {booking.activityName}
                            <SmallLink />
                        </Link>
                        <br />
                    </Heading>
                </Box>
                <Box
                    flex={false}
                    direction="row"
                    justify="between"
                    margin={{ bottom: "medium" }}
                >
                    <Heading
                        alignSelf="stretch"
                        level="3"
                        margin={{ bottom: "small" }}
                    >
                        <Link
                            to={`/activities/instance/${booking.activityInstance.id}`}
                        >
                            {booking.activityInstance.daySchedule.dayText}{" "}
                            {booking.activityInstance.timeFromToText}
                        </Link>
                        <SmallLink />
                    </Heading>
                </Box>
                {isCancelled ? (
                    <Heading level="2" color="status-error">
                        BOOKING IS CANCELLED
                    </Heading>
                ) : null}
                <Box direction="column" margin={{ bottom: "xlarge" }}>
                    <Grid columns={["flex", "flex"]} gap="small">
                        <Box flex key="checkin-cancel-left">
                            {isCheckedIn ? (
                                <Text size="medium" color="accent-2">
                                    Checked in: {formatDateTime(checkedInAt)}
                                </Text>
                            ) : canCheckIn ? (
                                <Button
                                    label="Not checked in"
                                    color="status-warning"
                                    type="button"
                                    onClick={onBookingCheckIn}
                                    data-testid="side-panel-check-in-button"
                                />
                            ) : null}
                        </Box>
                        <Box>
                            <Box
                                flex
                                align="end"
                                key="checkin-cancel-right"
                                margin={{ bottom: "xsmall" }}
                            >
                                {canCancel && !isPackagedBooking ? (
                                    <Button
                                        onClick={onBookingCancel}
                                        color="status-critical"
                                        label="Cancel Booking"
                                    />
                                ) : null}

                                {canCancel && isPackagedBooking ? (
                                    <Button
                                        onClick={onPackageBookingCancel}
                                        color="status-critical"
                                        label="Cancel all Bookings in Package"
                                    />
                                ) : null}
                            </Box>
                            <Box flex align="end" key="checkin-resend-right">
                                {!isCancelled ? (
                                    <Button
                                        onClick={onBookingResendEmails}
                                        color="status-ok"
                                        label="Resend confirmation"
                                    />
                                ) : null}
                            </Box>
                        </Box>
                    </Grid>
                </Box>
                <Box
                    direction="column"
                    flex="grow"
                    margin="top,bottom"
                    pad="top"
                >
                    <Heading level="4" margin="none">
                        Tickets
                    </Heading>
                    <Box
                        flex={true}
                        overflow="auto"
                        direction="row"
                        pad={{ vertical: "medium" }}
                    >
                        <>
                            <Box>
                                {booking.activityQuantity}

                                {" x "}
                                {
                                    booking.activityInstance.activity
                                        .ticketDescription
                                }
                            </Box>
                            <Box margin={{ left: "small" }}>
                                <Text size="medium" weight="normal">
                                    £
                                    {(
                                        booking.activityQuantity *
                                        (booking.activityPrice -
                                            booking.activityDiscount)
                                    ).toFixed(2)}
                                </Text>
                            </Box>
                        </>
                    </Box>
                </Box>
                {booking.customerMemberships &&
                booking.customerMemberships.length ? (
                    <Box
                        flex={false}
                        direction="column"
                        margin={{ bottom: "medium" }}
                    >
                        <Heading level="3" alignSelf="stretch">
                            Memberships used (
                            {booking.customerMemberships.length})
                        </Heading>

                        {booking.customerMemberships.map((membership) => (
                            <Grid
                                key={membership.id}
                                columns={["small", "flex"]}
                                margin={{ bottom: "small" }}
                            >
                                <Image
                                    style={imgStyle}
                                    fit="contain"
                                    src={membership.photo.base64}
                                />
                                <Box>
                                    <Heading
                                        level="3"
                                        margin={{
                                            top: "small",
                                            bottom: "none"
                                        }}
                                    >
                                        {membership.name}
                                    </Heading>

                                    <Paragraph
                                        margin={{
                                            top: "small",
                                            bottom: "none"
                                        }}
                                    >
                                        {membership.membership.name}
                                    </Paragraph>
                                    {membership.customer &&
                                        membership.customer.id !==
                                            booking.customer.id && (
                                            <Heading margin="none" level="5">
                                                Linked from{" "}
                                                {membership.customer.name}
                                            </Heading>
                                        )}
                                </Box>
                            </Grid>
                        ))}
                    </Box>
                ) : null}
                {booking.bookingExtras && booking.bookingExtras.length > 0 && (
                    <Box
                        direction="column"
                        flex="grow"
                        margin="top,bottom"
                        pad="top"
                    >
                        <Heading level="4" margin="none">
                            Extras
                        </Heading>
                        <Box
                            flex={false}
                            overflow="auto"
                            pad={{ vertical: "medium" }}
                        >
                            {booking.bookingExtras.map((extra) => (
                                <Grid
                                    columns={["small", "flex"]}
                                    gap="small"
                                    key={extra.id}
                                >
                                    <Box key={`extra-${extra.id}`}>
                                        {extra.quantity} x {extra.name}
                                    </Box>

                                    <Box key={`extra-price-${extra.id}`}>
                                        <Text size="medium" weight="normal">
                                            £
                                            {(
                                                extra.quantity *
                                                (extra.pricePerItem -
                                                    extra.discountPerItem)
                                            ).toFixed(2)}
                                        </Text>
                                    </Box>
                                </Grid>
                            ))}
                        </Box>
                    </Box>
                )}
                <Box flex="grow" overflow="auto" pad={{ vertical: "medium" }}>
                    <Grid columns={["small", "flex"]} gap="small">
                        <Box key="sub-total">Sub total</Box>
                        <Box key="sub-total-value">
                            <Text size="medium" weight="normal">
                                £{booking.subTotal.toFixed(2)}
                            </Text>
                        </Box>
                        <Box key="discount">Discount</Box>
                        <Box key="discount-value">
                            <Text size="medium" weight="normal">
                                £{booking.discount.toFixed(2)}
                            </Text>
                        </Box>
                        <Box key="total-label">Total</Box>
                        <Box key="total-value">
                            <Text size="large" weight="bold">
                                £{booking.total.toFixed(2)}
                            </Text>
                        </Box>
                        <Box key="balance">Balance</Box>
                        <Box key="balance-value">
                            <Text size="medium" weight="normal">
                                £{booking.balance.toFixed(2)}
                            </Text>
                        </Box>
                    </Grid>
                </Box>
                <Box flex={false} overflow="auto" pad={{ vertical: "medium" }}>
                    <Link
                        to={`/booking/${booking.id}`}
                        data-testid="add-transaction-button"
                    >
                        <Button
                            fill
                            alignSelf="stretch"
                            color="status-ok"
                            label="Amend Booking / Add Transaction"
                        />
                    </Link>
                </Box>
                <Box flex={false} overflow="auto" pad={{ vertical: "medium" }}>
                    <Heading level="4">Transactional details</Heading>
                    {booking.transactions.map((transaction) => (
                        <Box key={`transaction-${transaction.id}`}>
                            <Grid columns={["small", "flex"]} gap="small">
                                <Box key="completed">Completed</Box>
                                <Box key="c-val">
                                    {formatDateTime(transaction.completedAt)}
                                </Box>
                                <Box key="a">Amount</Box>
                                <Box key="a-val">
                                    £
                                    {transaction.amount
                                        ? transaction.amount.toFixed(2)
                                        : "???"}
                                </Box>
                                <Box key="p">Provider ID</Box>
                                <Box key="p-val">
                                    {transaction.providerIdentifier}
                                </Box>
                                <Box key="auth">Auth Code</Box>
                                <Box key="auth-val">{transaction.authCode}</Box>
                            </Grid>
                        </Box>
                    ))}
                </Box>
                <Box flex={false} overflow="auto" pad={{ vertical: "medium" }}>
                    <Heading level="4">Notes</Heading>
                    {booking.midAdminNotes &&
                    booking.midAdminNotes.length > 0 ? (
                        booking.midAdminNotes.map((note) => {
                            const created = moment(note.createdAt)
                                .utc()
                                .format("dddd, MMMM Do YYYY, HH:mm");
                            const userName = note.createdByUser
                                ? note.createdByUser.name
                                : "";
                            return (
                                <Grid
                                    key={`note-${note.id}`}
                                    columns={["small", "flex"]}
                                    gap="small"
                                >
                                    <Box key={`${note.id}-l`}>
                                        <Text size="xsmall">
                                            <strong>{created}</strong>
                                            <br />
                                            {userName}
                                        </Text>
                                    </Box>
                                    <Box key={`${note.id}-r`}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: note.content.replace(
                                                    /(<? *script)/gi,
                                                    "illegalscript"
                                                )
                                            }}
                                        />{" "}
                                    </Box>
                                </Grid>
                            );
                        })
                    ) : (
                        <Text>No notes</Text>
                    )}
                </Box>
            </Box>
        </Layer>
    ) : null;
};
