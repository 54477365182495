import React, {
    createContext,
    useMemo,
    useState,
    useContext,
    useEffect
} from "react";
import { useMutation } from "@apollo/client";
import {
    LOGIN_USER,
    REQUEST_RESET_USER_PASSWORD,
    RESET_USER_PASSWORD
} from "../../data/User/mutations";
import decodeJwt from "jwt-decode";
import AppContext from "./AppContext";

const AuthContext = createContext(null);

function AuthContextProvider(props) {
    const {
        token,
        setToken,
        userId,
        setUserId,
        organisationId,
        setOrganisationId,
        userName,
        setUserName
    } = useContext(AppContext);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        if (token) {
            const decodedToken = decodeJwt(token);

            if (
                decodedToken &&
                decodedToken.userId &&
                decodedToken.userName &&
                decodedToken.role &&
                decodedToken.organisationId
            ) {
                setUserId(decodedToken.userId);
                setUserName(decodedToken.userName);
                setOrganisationId(decodedToken.organisationId);
                setIsAuthenticated(true);
            }
        } else {
            // TODO logout function
            setIsAuthenticated(false);
            setUserId(null);
        }
    }, [token]);

    const [loginUser] = useMutation(LOGIN_USER);
    const loginUserExtended = async (email, password) => {
        // do checks here to make sure credentials are correct
        try {
            const { data, errors } = await loginUser({
                variables: {
                    email,
                    password
                }
            });

            if (data && data["loginUser"]) {
                setToken(data["loginUser"].token);
                setIsAuthenticated(true);
            }

            if (errors && errors.length > 0) {
                setToken(null);
                setIsAuthenticated(false);
                return false;
            }

            return true;
        } catch (e) {
            return false;
        }
    };

    const [requestPasswordReset] = useMutation(REQUEST_RESET_USER_PASSWORD);
    const requestPasswordResetExtended = async (email) => {
        try {
            const { data, errors } = await requestPasswordReset({
                variables: {
                    email,
                    webAppRoot: process.env.WEBAPP_ROOT
                }
            });

            if (data && data["requestUserPasswordReset"]) {
                return true;
            }

            if (errors && errors.length > 0) {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    const [passwordReset] = useMutation(RESET_USER_PASSWORD);
    const passwordResetExtended = async (securityStamp, newPassword) => {
        try {
            const { data, errors } = await passwordReset({
                variables: {
                    securityStamp,
                    newPassword
                }
            });

            if (data && data["resetUserPassword"]) {
                return true;
            }

            if (errors && errors.length > 0) {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    const values = useMemo(() => {
        return {
            isAuthenticated,
            setIsAuthenticated,
            loginUser: loginUserExtended,
            requestPasswordReset: requestPasswordResetExtended,
            passwordReset: passwordResetExtended
        };
    }, [isAuthenticated]);

    return <AuthContext.Provider value={values} {...props} />;
}

function useAuthenticated() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(
            "Authenticated must be used within a AuthContextProvider"
        );
    }
    const { isAuthenticated, setIsAuthenticated } = context;
    return { isAuthenticated, setIsAuthenticated };
}

function useLogin() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("Login must be used within a AuthContextProvider");
    }
    const { loginUser } = context;
    return { loginUser };
}

function useRequestPasswordReset() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(
            "RequestPasswordReset must be used within a AuthContextProvider"
        );
    }
    const { requestPasswordReset } = context;
    return { requestPasswordReset };
}

function usePasswordReset() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(
            "PasswordReset must be used within a AuthContextProvider"
        );
    }
    const { passwordReset } = context;
    return { passwordReset };
}

export {
    AuthContextProvider,
    useAuthenticated,
    useLogin,
    useRequestPasswordReset,
    usePasswordReset
};
