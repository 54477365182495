import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Grid, Heading, Text } from "grommet";
import { Lock } from "grommet-icons";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TOGGLE_DISABLED_CUSTOMER_MUTATION } from "../../data/Customers/mutations";
import { CUSTOMER_FULL } from "../../data/Customers/queries";
import { ICustomer } from "../../generated/dataInterfaces";
import BookingListContext from "../Context/BookingListContext";
import CustomerView from "../Customers/Customer";
import Page from "../Page";
import EmptyPage from "./EmptyScreen";

const CustomerAccountsScreen = ({ match }) => {
    const { id } = match.params;
    const views = [
        "Bookings",
        "Credentials",
        "Memberships",
        "Notes",
        "Benefits"
    ];
    const [view, setView] = useState(views[0]);
    const [shouldLoadData, setShouldLoadData] = useState(true);
    const [customer, setCustomer] = useState<ICustomer>();

    const { data, loading } = useQuery(CUSTOMER_FULL, {
        variables: {
            customerId: id
        },
        skip: !shouldLoadData,
        fetchPolicy: "no-cache"
    });

    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);

    useEffect(() => {
        setShouldLoadData(shouldLoadData || shouldRefresh);
    }, [shouldRefresh, shouldLoadData]);

    if (data && data.customer && !loading) {
        setCustomer(data.customer as ICustomer);
        setShouldLoadData(false);
        setShouldRefresh(false);
    }

    const [mutateToggleDisabled] = useMutation(
        TOGGLE_DISABLED_CUSTOMER_MUTATION
    );
    const toggleCustomer = async () => {
        const result = await mutateToggleDisabled({
            variables: { customerId: id }
        });
        setCustomer(result.data["toggleCustomer"]);
        toast(`Customer was ${customer.isDisabled ? "enabled" : "disabled"}`);
    };

    if (customer) {
        return (
            <Page title="Customer">
                <Box flex="grow">
                    <Grid
                        columns={["medium", "flex"]}
                        gap="small"
                        margin={{ bottom: "medium" }}
                    >
                        <Box
                            direction="column"
                            pad={{ left: "small" }}
                            margin="none"
                            flex={true}
                        >
                            <Heading level="2" margin={{ top: "none" }}>
                                {customer.name}
                            </Heading>

                            <Box direction="row-responsive">
                                <Text weight="bold" size="large">
                                    {customer.memberships &&
                                    customer.memberships.length
                                        ? "Member"
                                        : customer.benefits &&
                                          customer.benefits.length > 0
                                        ? "Customer with benefits"
                                        : "Customer"}
                                </Text>
                                {customer.isDisabled ? (
                                    <>
                                        {"   "}
                                        <Lock />
                                    </>
                                ) : null}

                                <Button
                                    margin={{ left: "medium" }}
                                    onClick={() => toggleCustomer()}
                                >
                                    {customer.isDisabled
                                        ? "Enable now"
                                        : "Disable now"}
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            alignContent="end"
                            align="end"
                            flex={false}
                            pad={{ right: "small" }}
                        >
                            <Box
                                height="60px"
                                round="large"
                                direction="row-responsive"
                                background="light-1"
                                border="all"
                                flex={false}
                            >
                                {views.map((currentView) => (
                                    <Button
                                        key={currentView}
                                        primary
                                        hoverIndicator=""
                                        color={
                                            view === currentView
                                                ? ""
                                                : "light-1"
                                        }
                                        plain={false}
                                        onClick={() => setView(currentView)}
                                    >
                                        <Text
                                            color={
                                                view === currentView
                                                    ? "white"
                                                    : ""
                                            }
                                            size="large"
                                        >
                                            {currentView}
                                        </Text>
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                </Box>
                <Box>
                    <CustomerView customer={customer} view={view} />
                </Box>
            </Page>
        );
    } else {
        return <EmptyPage />;
    }
};

export default CustomerAccountsScreen;
