import {
    Box,
    Paragraph,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text
} from "grommet";
import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import { ICustomer } from "../../generated/dataInterfaces";
import { SmallLink } from "../Icons/LinkIndicators";

interface IPropsCustomerTable {
    listData: ICustomer[];
    otherProps?: any;
}

export default ({ listData, ...otherProps }: IPropsCustomerTable) => {
    const [customerId, setCustomerId] = useState("");
    const columns = [
        "Name",
        "Postcode",
        "Primary email",
        "No of bookings",
        "Status"
    ];

    const [data, setData] = useState(listData);

    const navigateToCustomer = (customerId) => {
        setCustomerId(customerId);
    };

    const hasData = data && data.length > 0;
    return customerId ? (
        <Redirect to={`/customer/${customerId}`} />
    ) : hasData ? (
        <Box
            margin={{ top: "medium" }}
            pad={{ horizontal: "medium", bottom: "large" }}
            background="white"
        >
            <Table className="table hover">
                <TableHeader>
                    <TableRow>
                        {columns.map((c) => (
                            <TableCell key={c} scope="col">
                                <Text>{c}</Text>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {data.map((customer) => {
                        return (
                            <TableRow
                                key={customer.id}
                                onClick={() => navigateToCustomer(customer.id)}
                            >
                                <TableCell size="1/4" className="bold">
                                    <Text weight="bold">
                                        {customer.name} <SmallLink />
                                    </Text>
                                </TableCell>
                                <TableCell>{customer.postcode}</TableCell>
                                <TableCell>{customer.primaryEmail}</TableCell>
                                <TableCell>
                                    {customer.numberOfBookings}
                                </TableCell>
                                <TableCell>
                                    {customer.isDisabled ? (
                                        <Text color="status-error">
                                            Disabled
                                        </Text>
                                    ) : (
                                        <Text color="status-ok">Active</Text>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    ) : (
        <div>
            <Paragraph color="light-3">No results</Paragraph>
        </div>
    );
};

// export default withRouter(component);
