import { useQuery } from "@apollo/client";
import { Box, Button, Text, TextInput } from "grommet";
import { Search } from "grommet-icons";
import React, { useContext, useEffect, useState } from "react";
import { BOOKING_SEARCH_PAST_FUTURE } from "../../data/Bookings/queries";
import BookingAccordian from "../Bookings/Accordian/BookingAccordian";
import { IBookingAccordianItem } from "../Bookings/Accordian/bookingAccordianInterfaces";
import AppContext from "../Context/AppContext";
import BookingListContext from "../Context/BookingListContext";
import useStateWithLocalStorageSimple from "../Custom/useStateWithLocalStorageSimple";
import { SmallLink } from "../Icons/LinkIndicators";
import { LoadingText } from "../Icons/Loading";
import Page from "../Page";

export default () => {
    const [searchTerm, setSearchTerm] = useStateWithLocalStorageSimple(
        "__bookingSearch"
    );
    const [shouldSearchNow, setShouldSearchNow] = useState(false);
    const [todayBookings, setTodayBookings] = useState();
    const [pastBookings, setPastBookings] = useState();
    const [futureBookings, setFutureBookings] = useState();
    const { organisationId } = useContext(AppContext);
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);

    useEffect(() => {
        setShouldSearchNow(shouldSearchNow || shouldRefresh);
    }, [shouldRefresh, shouldSearchNow]);

    const { data, error, loading } = useQuery(BOOKING_SEARCH_PAST_FUTURE, {
        variables: {
            organisationId,
            searchTerm: searchTerm ?? ""
        },
        skip: !shouldSearchNow
    });

    if (error) {
        console.log("error", error);
    }
    const hasData = data && data.searchBookingsPastFuture;
    if (hasData && !loading) {
        setTodayBookings(data.searchBookingsPastFuture.today);
        setPastBookings(data.searchBookingsPastFuture.past);
        setFutureBookings(data.searchBookingsPastFuture.future);
        setShouldSearchNow(false);
        setShouldRefresh(false);
    }

    const clearData = () => {
        setTodayBookings(null);
        setPastBookings(null);
        setFutureBookings(null);
    };

    const panels: IBookingAccordianItem[] = [
        { title: "Today's bookings", results: todayBookings },
        { title: "Future bookings", results: futureBookings },
        { title: "Past bookings", results: pastBookings }
    ];

    const activeIndex =
        todayBookings && todayBookings.length > 0
            ? 0
            : futureBookings && futureBookings.length > 0
            ? 1
            : pastBookings && pastBookings.length > 0
            ? 2
            : 0;

    return (
        <Page title="Booking Search">
            <Box
                direction="row"
                align="center"
                pad={{ horizontal: "small", vertical: "xsmall" }}
                round="small"
                border={{ side: "all" }}
                background="white"
                margin={{ left: "large", right: "large", bottom: "large" }}
            >
                <Search color="brand" />
                <TextInput
                    type="search"
                    plain
                    size="large"
                    value={searchTerm}
                    data-testid="booking-search-input"
                    placeholder="Booking reference, package booking reference or customer name..."
                    onChange={(event) => {
                        setSearchTerm(event.target.value);
                        if (!event.target.value) {
                            clearData();
                        }
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            setShouldSearchNow(true);
                        }
                    }}
                />
                <Button onClick={() => setShouldSearchNow(true)}>
                    <Text size="large">
                        <SmallLink />
                    </Text>
                </Button>
            </Box>

            {loading ? <LoadingText /> : null}

            {(todayBookings || pastBookings || futureBookings) && !loading ? (
                <Box
                    background="white"
                    border="top"
                    pad={{ left: "large", right: "large" }}
                >
                    <BookingAccordian
                        resultInfo={panels}
                        activeIndex={activeIndex}
                    />
                </Box>
            ) : null}
        </Page>
    );
};
