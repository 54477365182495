import React, { useState, useContext, useEffect } from "react";
import {
    IBooking,
    ICredential,
    ICustomer,
    INote
} from "../../../../generated/dataInterfaces";
import { useQuery, useMutation } from "@apollo/client";
import {
    Paragraph,
    Box,
    Heading,
    Text,
    Button,
    Grid,
    Form,
    FormField,
    TextArea,
    Select
} from "grommet";
import { Lock } from "grommet-icons";
import _ from "lodash";
import { ADD_MIDADMIN_NOTE_CUSTOMER_MUTATION } from "../../../../data/Customers/mutations";
import CredentialDetail from "../../Detail/CredentialDetail";
import Notes from "./Notes";
import AppContext from "../../../Context/AppContext";
import { toast } from "react-toastify";

interface IPropsAccount {
    customer: ICustomer;
}

export default ({ customer }: IPropsAccount) => {
    const [midAdminNotes, setMidAdminNotes] = useState<INote[]>(
        customer.midAdminNotes
    );
    const { userId } = useContext(AppContext);
    const [mutateNote] = useMutation(ADD_MIDADMIN_NOTE_CUSTOMER_MUTATION);
    const [noteType, setNoteType] = useState();

    const addNote = async (content, noteType) => {
        const result = await mutateNote({
            variables: {
                customerId: customer.id,
                content,
                noteType
            }
        });
        const note = result.data["addMidAdminNoteToCustomer"];
        // dunno, just add it?

        setMidAdminNotes([...midAdminNotes, note]);
        toast("Note added. Customer will not see this note.");
    };

    // useEffect(() => {}, [midAdminNotes]);
    const options = [
        { value: "ACTIVITIES", label: "Activities" },
        { value: "ACCOUNT", label: "Account" },
        { value: "FOOD_AND_BEVERAGES", label: "Food & Beverages" }
    ];
    return customer ? (
        <Grid
            columns={{
                count: 2,
                size: "auto"
            }}
            gap="none"
        >
            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "small"
                }}
            >
                <Box direction="column">
                    <Heading level="3" margin={{ bottom: "small" }}>
                        Admin notes (added by you)
                    </Heading>
                    <Notes notes={midAdminNotes} />
                </Box>
                <Box direction="column">
                    <Heading level="6" margin={{ bottom: "small" }}>
                        Add a new note
                    </Heading>
                    <Form
                        onSubmit={({ value }) => {
                            // not ideal!!! erroring, but shouldn't
                            // https://storybook.grommet.io/?path=/story/form--all
                            const { content, noteType } = value;
                            if (content && noteType) {
                                addNote(content, noteType.value);
                                value.content = "";
                                setNoteType({});
                            }
                        }}
                    >
                        <FormField
                            name="noteType"
                            options={options}
                            value={noteType}
                            onChange={(event) => {
                                setNoteType(event.option);
                            }}
                            placeholder="Select note type"
                            valueKey="value"
                            labelKey="label"
                            component={Select}
                        />
                        <FormField
                            label=""
                            placeholder="Add your note here"
                            name="content"
                            component={TextArea}
                        />
                        <Box direction="row" margin={{ top: "medium" }}>
                            <Button
                                label="Add note"
                                color="accent-1"
                                type="submit"
                                primary
                            />
                        </Box>
                    </Form>
                </Box>
            </Box>
            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "small"
                }}
            >
                <Box direction="column">
                    <Heading level="3" margin={{ bottom: "small" }}>
                        Customer notes (added by them)
                    </Heading>
                    <Notes notes={customer.customerNotes} />
                </Box>
            </Box>
        </Grid>
    ) : (
        <Box>Loading...</Box>
    );
};
