import { Box, Button, Heading, Layer, Paragraph, Text } from "grommet";
import { Close } from "grommet-icons";
import moment from "moment";
import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { DISCOUNT_FULL_DETAILS } from "../../data/Discounts/queries";
import { IDiscountData } from "../../generated/dataInterfaces";
import FieldValue from "../Custom/FieldValue";

interface IPropsDiscountItemProps {
    discountId: string;
}

export default ({ discountId }: IPropsDiscountItemProps) => {
    const [discountInfo, setDiscountInfo] = useState<IDiscountData>();
    const [shouldLoadNow, setShouldLoadNow] = useState(true);

    const { data, error, loading } = useQuery(DISCOUNT_FULL_DETAILS, {
        variables: {
            discountId
        },
        skip: !shouldLoadNow,
        fetchPolicy: "no-cache"
    });

    const hasData = data && data.discountFullDetails;
    if (hasData) {
        setShouldLoadNow(false);
        setDiscountInfo(data.discountFullDetails as IDiscountData);
    }

    return discountInfo ? (
        <Box margin="small" pad="none" border="top">
            <Heading level="4">Discount "{discountInfo.name}"</Heading>
            <Text size="small">{discountInfo.description}</Text>
            <Paragraph margin={{ bottom: "none" }}>
                {discountInfo.amount} off
            </Paragraph>

            {discountInfo.activities.length === 0 ? (
                <Paragraph margin={{ bottom: "none" }}>
                    No activity restrictions
                </Paragraph>
            ) : (
                <>
                    Only applies to the following activities:
                    <ul>
                        {discountInfo.activities.map((d, index) => (
                            <li key={`li-${index}`}>{d.name}</li>
                        ))}
                    </ul>
                </>
            )}
            <Paragraph margin={{ bottom: "none" }}>
                {discountInfo.daysOfWeek}
            </Paragraph>
            <Paragraph margin={{ bottom: "none" }}>
                {discountInfo.activitiesExtraApplicationDescription}
            </Paragraph>
        </Box>
    ) : null;
};
