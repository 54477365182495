import { ApolloProvider } from "@apollo/client";
import { Grommet } from "grommet";
import React, { useContext } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { customTheme } from "../theme/lesormes";
import AppContext from "./Context/AppContext";
import { AuthContextProvider } from "./Context/AuthContext";
import { BookingContextProvider } from "./Context/BookingContext";
import { BookingListContextProvider } from "./Context/BookingListContext";
import { CustomerContextProvider } from "./Context/CustomerContext";
import { FacilityContextProvider } from "./Context/FacilityContext";
import Routes from "./Routes";

const App = () => {
    // get apollo client from context
    const { apolloClient, organisationId } = useContext(AppContext);

    if (!apolloClient) {
        return null;
    }

    return (
        <ApolloProvider client={apolloClient}>
            <Grommet theme={customTheme}>
                <AuthContextProvider>
                    <FacilityContextProvider>
                        <BookingListContextProvider>
                            <BookingContextProvider>
                                <CustomerContextProvider>
                                    <Routes />
                                </CustomerContextProvider>
                            </BookingContextProvider>
                        </BookingListContextProvider>
                    </FacilityContextProvider>
                </AuthContextProvider>
            </Grommet>
        </ApolloProvider>
    );
};
export default App;
