import React from "react";
import { ICustomer } from "../../../generated/dataInterfaces";
import { Heading } from "grommet";
import CustomerBookings from "./Bookings";
import CustomerCredentials from "./Credentials";
import CustomerNotes from "./Notes";
import CustomerBenefits from "./Benefits";
import CustomerMemberships from "./Memberships";

interface IPropsCustomerAccount {
    customer: ICustomer;
    view: string;
    otherProps?: any;
}

export default ({ customer, view, ...otherProps }: IPropsCustomerAccount) => {
    switch (view.toLowerCase()) {
        case "notes":
            return <CustomerNotes customer={customer} />;

        case "bookings":
            return (
                <CustomerBookings
                    futureBookings={customer.futureBookings}
                    pastBookings={customer.pastBookings}
                    packagedBookings={customer.packagedBookings}
                />
            );

        case "credentials":
            return <CustomerCredentials credentials={customer.credentials} />;

        case "memberships":
            return (
                <CustomerMemberships
                    customerId={customer.id}
                    memberships={customer.memberships}
                />
            );

        case "benefits":
            return (
                <CustomerBenefits
                    customerId={customer.id}
                    benefits={customer.benefits}
                />
            );

        default:
            return <Heading>UNKNOWN VIEW {view}</Heading>;
    }
};
