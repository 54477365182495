import { Box } from "grommet";
import React from "react";
import Login from "../Login";

const LoginScreen = () => {
    return (
        <Box
            fill="horizontal"
            pad={{
                left: "none",
                top: "large",
                right: "none",
                bottom: "large"
            }}
            gridArea="main"
            background="light-1"
            className="texture"
        >
            <Login />
        </Box>
    );
};

export default LoginScreen;
