import { useQuery } from "@apollo/client";
import { Box, Button, Grid, Text, TextInput } from "grommet";
import { Search } from "grommet-icons";
import React, { useContext, useState } from "react";
import { CUSTOMER_SEARCH } from "../../data/Customers/queries";
import AppContext from "../Context/AppContext";
import useStateWithLocalStorageSimple from "../Custom/useStateWithLocalStorageSimple";
import CustomerTable from "../Customers/CustomerTable";
import NewCustomer from "../Customers/Make/NewCustomer";
import { LoadingText } from "../Icons/Loading";
import Page from "../Page";

const CustomerSearchScreen = () => {
    const [searchTerm, setSearchTerm] = useStateWithLocalStorageSimple<string>(
        "__customerSearch"
    );
    const [shouldSearchNow, setShouldSearchNow] = useState(Boolean(searchTerm));
    const [customers, setCustomers] = useState();
    const { organisationId } = useContext(AppContext);
    const [openCreate, setOpenCreate] = useState(false);

    const { data, loading } = useQuery(CUSTOMER_SEARCH, {
        variables: {
            organisationId,
            searchTerm: searchTerm ?? "",
            showDisabled: true
        },
        skip: !shouldSearchNow
    });

    const hasData = data && data.searchCustomers;
    if (hasData && !loading) {
        setCustomers(data.searchCustomers);
        setShouldSearchNow(false);
    }

    const clearData = () => {
        setCustomers(null);
    };

    return (
        <Page title="Customer Search">
            <NewCustomer open={openCreate} setOpen={setOpenCreate} />
            <Grid columns={["flex", "small"]} gap="xxsmall">
                <Box
                    direction="row"
                    align="center"
                    pad={{ horizontal: "small", vertical: "xsmall" }}
                    round="small"
                    border={{ side: "all" }}
                    background="white"
                    margin={{ left: "large", right: "large", bottom: "small" }}
                >
                    <Search color="brand" />
                    <TextInput
                        type="search"
                        plain
                        size="large"
                        placeholder="Customer name, postcode or email address..."
                        value={searchTerm}
                        onChange={(event) => {
                            setSearchTerm(event.target.value);
                            if (!event.target.value) {
                                clearData();
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                setShouldSearchNow(true);
                            }
                        }}
                    />
                    <Button onClick={() => setShouldSearchNow(true)}>
                        <Text size="large">SEARCH</Text>
                    </Button>
                </Box>
                <Box alignContent="center" pad="small">
                    <Button
                        onClick={() => {
                            setOpenCreate(true);
                        }}
                        primary
                        color="accent-4"
                    >
                        <Box alignContent="center" pad="small" align="center">
                            <Text color="white">Create</Text>
                        </Box>
                    </Button>
                </Box>
            </Grid>
            {loading ? <LoadingText /> : null}
            {customers && !loading ? (
                <CustomerTable listData={customers} />
            ) : null}
        </Page>
    );
};

export default CustomerSearchScreen;
