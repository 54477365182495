import { gql } from "@apollo/client";
import { customerMembershipFullDetails } from "./fields";

export const TOGGLE_DISABLED_MEMBERSHIP_MUTATION = gql`
mutation toggleMembership($customerMembershipId: ID!){
    toggleMembership(input: {
        customerMembershipId: $customerMembershipId,
    }) {
        ${customerMembershipFullDetails}
    }
}
`;

export const ACTIVATE_MEMBERSHIP_MUTATION = gql`
mutation activateMembership($customerMembershipId: ID!){
    activateMembership(input: {
        customerMembershipId: $customerMembershipId,
    }) {
        ${customerMembershipFullDetails}
    }
}
`;

export const SAVE_IMAGE_MEMBERSHIP_MUTATION = gql`
    mutation uploadCustomerMembershipPhoto(
        $customerId: ID!
        $customerMembershipId: ID!
        $base64ImageData: String!
    ) {
        uploadCustomerMembershipPhoto(
            data: {
                customerId: $customerId
                customerMembershipId: $customerMembershipId
                base64DataImage: $base64ImageData
            }
        ) {
            base64
        }
    }
`;

export const RENEW_CUSTOMER_MEMBERSHIP_MUTATION = gql`
    mutation renewCustomerMembership(
        $braintreeClientToken: String!
        $customerMembershipId: ID!
    ) {
        renewCustomerMembership(
            data: {
                customerMembershipId: $customerMembershipId
                braintreeClientToken: $braintreeClientToken
            }
        ) {
            id
        }
    }
`;
