import { Box, Heading, Paragraph, Select } from "grommet";
import React, { useContext, useEffect, useState, useDebugValue } from "react";
import { useQuery } from "@apollo/client";
import AppContext from "../Context/AppContext";

import { FACILITY_LIST } from "../../data/Facilities/queries";
import { IFacility } from "../../generated/dataInterfaces";
import FacilityContext from "../Context/FacilityContext";

export default () => {
    const { organisationId, userId } = useContext(AppContext);
    const [facilities, setFacilities] = useState<IFacility[]>();
    const { facilityId, setFacilityId } = useContext(FacilityContext);
    const [currentName, setCurrentName] = useState("Select facility");
    const [shouldLoadNow, setShouldLoadNow] = useState(true);

    const { data, error } = useQuery(FACILITY_LIST, {
        variables: {
            organisationId
        },
        errorPolicy: "all",
        skip: !shouldLoadNow
    });

    if (data && data.facilitiesByOrg) {
        setFacilities(data.facilitiesByOrg);
        setShouldLoadNow(false);
    }

    useEffect(() => {
        if (facilities) {
            const current = facilities.filter((f) => f.id === facilityId)[0];
            if (current) {
                setCurrentName(current.name);
            }
        }
    }, [facilityId]);

    const setValues = (nextValue) => {
        setFacilityId(nextValue.val);
        setCurrentName(nextValue.lab);
    };

    if (facilities) {
        const options = facilities.map((f) => {
            return { lab: f.name, val: f.id };
        });
        options.push({ lab: "All facilities", val: "" });
        return (
            <>
                <Select
                    id="facilitySelect"
                    options={options}
                    labelKey="lab"
                    valueKey="val"
                    placeholder={currentName}
                    onChange={({ value }) => {
                        setValues(value);
                    }}
                />
            </>
        );
    } else {
        return null;
    }
};
