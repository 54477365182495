import { Box, Button, Text } from "grommet";
import React from "react";
import { Link } from "react-router-dom";
import { SmallLink } from "../Icons/LinkIndicators";

interface ISidebarButtonProps {
    label: string;
    href: string;
    rest: any;
}

export const SidebarButton = ({
    label,
    href,
    ...rest
}: ISidebarButtonProps) => (
    <Link to={href} data-testid={`${label.toLowerCase()}-link`}>
        <Button plain {...rest} fill>
            {({ hover }) => (
                <Box
                    background={hover ? "accent-1" : undefined}
                    pad={{ horizontal: "medium", vertical: "medium" }}
                >
                    <Text size="large" color="white">
                        {label} <SmallLink />
                    </Text>
                </Box>
            )}
        </Button>
    </Link>
);
