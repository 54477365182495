import { gql } from "@apollo/client";
import { transactionFields } from "./fields";

export const CREATE_BOOKING_TRANSACTION_NO_PAYMENT = gql`
mutation createBookingTransactionNoPayment(
    $bookingId: ID!,
    $amount: Float!,
    $description: String,
    $updateBookingBalance: Boolean!){
        createBookingTransactionNoPayment(input: {
            bookingId: $bookingId,
            amount: $amount
            description: $description
            updateBookingBalance: $updateBookingBalance
        }) {
            ${transactionFields}
        }
}
`;

export const CREATE_BOOKING_TRANSACTION_WITH_PAYMENT = gql`
mutation createBookingTransactionWithPayment(
    $bookingId: ID!,
    $amount: Float!,
    $description: String,
    $updateBookingBalance: Boolean!,
    $braintreeNonce: String!){
        createBookingTransactionWithPayment(input: {
            bookingId: $bookingId,
            amount: $amount
            description: $description
            updateBookingBalance: $updateBookingBalance
            braintreeNonce: $braintreeNonce
        }) {
            ${transactionFields}
        }
}
`;
