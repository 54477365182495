import React, { useState } from "react";
import { ICustomerMembership } from "../../../../generated/dataInterfaces";
import { Box, Paragraph, Button } from "grommet";
import MembershipItem from "./MembershipItem";
import MembershipDetail from "./MembershipDetail";
import { orderBy } from "lodash";

interface IPropMemberships {
    memberships: ICustomerMembership[];
    customerId: string;
}

export default ({ memberships, customerId }: IPropMemberships) => {
    const [customerMembershipId, setCustomerMembershipId] = useState();
    const [openMembership, setOpenMembership] = useState(false);

    const setActiveCustomerMembershipId = (id) => {
        setCustomerMembershipId(id);
        setOpenMembership(true);
    };

    const orderedMemberships = orderBy(
        memberships,
        ["hasBeenActivated"],
        ["asc"]
    );

    return (
        <>
            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "large"
                }}
            >
                <MembershipDetail
                    open={openMembership}
                    customerMembershipId={customerMembershipId}
                    setOpen={setOpenMembership}
                />
                <Box direction="row-responsive" wrap={true}>
                    {orderedMemberships.length > 0 ? (
                        orderedMemberships.map((membership) => (
                            <Button
                                margin="xsmall"
                                key={membership.id}
                                onClick={() =>
                                    setActiveCustomerMembershipId(membership.id)
                                }
                            >
                                <MembershipItem membership={membership} />
                            </Button>
                        ))
                    ) : (
                        <Paragraph>No benefits</Paragraph>
                    )}
                </Box>
            </Box>
        </>
    );
};
