import { gql } from "@apollo/client";

export const PACKAGE = gql`
    query package($packageId: ID!) {
        package(where: { id: $packageId }) {
            id
            name
            activity {
                id
                name
                isBookingNameRequired
                resource {
                    id
                    name
                    extras {
                        id
                    }
                }
                quantityPerBooking
            }
            activityInstances {
                id
                daySchedule {
                    date
                }
                availabilityText
                timeFromToText
                pricePerBooking
                startTime
                endTime
                maxCapacity
                quantityUnavailable
            }
        }
    }
`;
