import { gql } from "@apollo/client";
import { customerFullFields, notesFields } from "./fields";

export const TOGGLE_DISABLED_CUSTOMER_MUTATION = gql`
mutation toggleCustomer($customerId: ID!){
    toggleCustomer(input: {
        customerId: $customerId,
    }) {
        ${customerFullFields}
    }
}
`;

export const ADD_MIDADMIN_NOTE_CUSTOMER_MUTATION = gql`
mutation addMidAdminNoteToCustomer($customerId: ID!, $content: String!, $noteType: NoteType!){
    addMidAdminNoteToCustomer(input: {
        content: $content,
        customerId: $customerId
        noteType: $noteType
    }) {
        ${notesFields}
    }
}
`;

export const BENEFIT_DELETE = gql`
    mutation deleteBenefit($benefitId: ID!) {
        deleteBenefit(input: { benefitId: $benefitId })
    }
`;

export const BENEFIT_ADD = gql`
    mutation addBenefit($input: AddBenefitInput!) {
        addBenefit(input: $input) {
            id
            name
        }
    }
`;

export const NEW_CUSTOMER_CREDENTIAL_SEND_EMAIL = gql`
    mutation newCustomerWithCredentialSendEmail(
        $input: NewCustomerWithCredentialSendEmailInput!
    ) {
        newCustomerWithCredentialSendEmail(input: $input) {
            id
            name
        }
    }
`;
