import { useMutation } from "@apollo/client";
import {
    Box,
    Button,
    Paragraph,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text
} from "grommet";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../../components/Context/AppContext";
import BookingListContext from "../../components/Context/BookingListContext";
import { BOOKING_CHECK_IN } from "../../data/Bookings/mutations";
import { IBooking } from "../../generated/dataInterfaces";
import { SmallLink } from "../Icons/LinkIndicators";
import BookingDetail from "./Detail/BookingDetail";
import BookingMembershipCheckin from "./Detail/BookingMembershipCheckin";

interface IPropsBookingTable {
    listData: IBooking[];
    hideActivityInfo: boolean;
    otherProps?: any;
}

const BookingTable = ({
    listData,
    hideActivityInfo,
    ...otherProps
}: IPropsBookingTable) => {
    const columns = [
        "Name",
        "", // empty one for "booking names"
        "People",
        "Extras",
        "Total",
        "Reference (Package)",
        "Memberships"
    ];

    const [data, setData] = useState(listData);
    const [bookingId, setBookingId] = useState<string>();
    const [openBooking, setOpenBooking] = useState<boolean>(false);
    const [openMembershipCheckin, setOpenMembershipCheckin] =
        useState<boolean>(false);
    const { setShouldRefresh } = useContext(BookingListContext);

    const { userId } = useContext(AppContext);

    useEffect(() => {
        setData(listData);
    }, [listData]);

    const [mutateCheckIn] = useMutation(BOOKING_CHECK_IN);
    const toggleCheckedIn = async (booking: IBooking) => {
        // not really toggle
        if (!booking.isCheckedIn) {
            const d = [...listData];
            d.find((b) => b.id === booking.id).isCheckedIn =
                !booking.isCheckedIn;
            setData(d);
            await mutateCheckIn({
                variables: { bookingId: booking.id, userId }
            });
            toast("Checked in");
            setShouldRefresh(true);
        }
    };

    const setActiveBooking = (id) => {
        setBookingId(id);
        setOpenBooking(true);
    };

    const setActiveMembershipCheckin = (id) => {
        setBookingId(id);
        setOpenMembershipCheckin(true);
    };

    const hasData = data && data.length > 0;
    return hasData ? (
        <>
            <BookingDetail
                open={openBooking}
                setOpen={setOpenBooking}
                bookingId={bookingId}
            />
            <BookingMembershipCheckin
                open={openMembershipCheckin}
                setOpen={setOpenMembershipCheckin}
                bookingId={bookingId}
            />
            <Box
                alignSelf="stretch"
                background="white"
                pad={{ bottom: "large" }}
                alignContent="stretch"
            >
                <Table alignSelf="stretch" className="table hover">
                    <TableHeader>
                        <TableRow>
                            {columns.map((c) => (
                                <TableCell key={c} scope="col">
                                    <Text>{c}</Text>
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data.map((booking) => {
                            const hasCustomerMemberships =
                                booking.customerMemberships &&
                                booking.customerMemberships.length > 0;

                            const isCancelled =
                                booking.bookingStatus == "CANCELLED";

                            return (
                                <>
                                    {!hideActivityInfo ? (
                                        <TableRow
                                            style={{
                                                borderTop: "1px solid #666",
                                                backgroundColor: "#fff"
                                            }}
                                            key={`head-${booking.id}`}
                                            className={
                                                booking.bookingStatus ==
                                                "CANCELLED"
                                                    ? "status-disabled"
                                                    : ""
                                            }
                                        >
                                            <TableCell colSpan={99}>
                                                <Link
                                                    onClick={(event) =>
                                                        event.stopPropagation()
                                                    }
                                                    to={`/activities/instance/${booking.activityInstance.id}`}
                                                >
                                                    <Text
                                                        style={{
                                                            fontSize: "15px"
                                                        }}
                                                    >
                                                        {booking.date}{" "}
                                                        {booking.time}
                                                        {" - "}
                                                        {
                                                            booking
                                                                .activityInstance
                                                                .activity
                                                                .resource.name
                                                        }{" "}
                                                        /{" "}
                                                        {
                                                            booking
                                                                .activityInstance
                                                                .activity.name
                                                        }{" "}
                                                        <SmallLink />
                                                    </Text>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}

                                    <TableRow
                                        key={booking.id}
                                        onClick={() =>
                                            setActiveBooking(booking.id)
                                        }
                                        className={
                                            isCancelled ? "status-disabled" : ""
                                        }
                                        data-testid="open-side-booking-menu"
                                    >
                                        <TableCell>
                                            <Link
                                                to={`/customer/${booking.customer.id}`}
                                            >
                                                <Text
                                                    weight={
                                                        booking.bookingNames
                                                            ? "normal"
                                                            : "bold"
                                                    }
                                                >
                                                    {booking.name} <SmallLink />{" "}
                                                    {isCancelled
                                                        ? " (cancelled)"
                                                        : ""}
                                                </Text>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Text
                                                weight={
                                                    booking.bookingNames
                                                        ? "bold"
                                                        : "normal"
                                                }
                                            >
                                                {booking.bookingNames
                                                    ? booking.bookingNames.join(
                                                          ", "
                                                      )
                                                    : null}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            {booking.peopleCount}
                                        </TableCell>
                                        <TableCell>
                                            {booking.extrasCount}
                                        </TableCell>
                                        <TableCell>
                                            £{booking.total.toFixed(2)}
                                            {booking.balance > 0 ? (
                                                <>
                                                    <Text size="xsmall">
                                                        £
                                                        {booking.balance.toFixed(
                                                            2
                                                        )}{" "}
                                                        to be paid
                                                    </Text>
                                                </>
                                            ) : null}
                                        </TableCell>

                                        <TableCell>
                                            {booking.reference}
                                            {booking.packageReference
                                                ? ` (${booking.packageReference})`
                                                : ""}
                                        </TableCell>
                                        <TableCell>
                                            {hasCustomerMemberships
                                                ? booking.customerMemberships
                                                      .length
                                                : "-"}
                                        </TableCell>

                                        <TableCell>
                                            {booking.bookingStatus !==
                                                "CANCELLED" &&
                                            moment(booking.activityStartsAt)
                                                .utc()
                                                .add(12, "hours")
                                                .isAfter(moment.utc()) ? (
                                                booking.isCheckedIn ? (
                                                    <Button
                                                        label="Checked in"
                                                        color="status-ok"
                                                        type="button"
                                                    />
                                                ) : (
                                                    <Button
                                                        label="Not checked in"
                                                        data-testid="check-in-button"
                                                        primary={
                                                            hasCustomerMemberships
                                                        }
                                                        color={
                                                            hasCustomerMemberships
                                                                ? "status-critical"
                                                                : "status-warning"
                                                        }
                                                        type="button"
                                                        onClick={async (
                                                            event
                                                        ) => {
                                                            event.stopPropagation();
                                                            hasCustomerMemberships
                                                                ? setActiveMembershipCheckin(
                                                                      booking.id
                                                                  )
                                                                : await toggleCheckedIn(
                                                                      booking
                                                                  );
                                                        }}
                                                    />
                                                )
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        </>
    ) : (
        <div>
            <Paragraph color="light-3">No results</Paragraph>
        </div>
    );
};

export default BookingTable;
