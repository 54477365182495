import { useQuery } from "@apollo/client";
import { Box, Select } from "grommet";
import React, { useContext, useState } from "react";
import { CUSTOMER_SEARCH } from "../../../data/Customers/queries";
import { ICustomer } from "../../../generated/dataInterfaces";
import AppContext from "../../Context/AppContext";
import BookingContext from "../../Context/BookingContext";
import useStateWithLocalStorageSimple from "../../Custom/useStateWithLocalStorageSimple";

// interface IFindCustomerProps {
//     customer: ICustomer;
//     setCustomer: (value: ICustomer) => void;
// }

export default () => {
    const { organisationId } = useContext(AppContext);
    const { customer, setCustomer } = useContext(BookingContext);
    const [searchTerm, setSearchTerm] =
        useStateWithLocalStorageSimple("__customerSearch");
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [shouldSearchNow, setShouldSearchNow] = useState(searchTerm);

    const { data, error, loading } = useQuery(CUSTOMER_SEARCH, {
        variables: {
            organisationId,
            searchTerm: searchTerm ?? "",
            showDisabled: false
        },
        skip: !shouldSearchNow
    });

    const hasData = data && data.searchCustomers;
    if (hasData) {
        setCustomers(data.searchCustomers);
        setShouldSearchNow(false);
    }

    return (
        <Box fill justify="start">
            {/* {customer ? <Text>{customer.name}</Text> : null} */}

            <Select
                data-testid="find-customer-input"
                size="medium"
                placeholder="Start typing name, email, etc"
                options={customers}
                labelKey={(customer) =>
                    `${customer.name}  (${customer.postcode})`
                }
                valueKey="id"
                value={customer}
                onChange={({ option }) => {
                    setCustomer(option);
                }}
                // onClose={() => {]}
                onSearch={(text) => {
                    setSearchTerm(text);
                    setShouldSearchNow(true);
                }}
            />
        </Box>
    );
};
