import { Box, DropButton, Calendar, Text } from "grommet";
import { Close, FormDown } from "grommet-icons";
import React, { useState } from "react";
import moment from "moment";
import { start } from "repl";

interface IPropsCalendarDropButton {
    dates: Date[];
    setDates: any;
    otherProps?: any;
}

export default ({
    dates,
    setDates,
    ...otherProps
}: IPropsCalendarDropButton) => {
    const [open, setOpen] = useState(false);
    const [[startDate, endDate], setCalendarDates] = useState(dates);

    const onSelect = (date) => {
        setOpen(false);
        let start = moment(date).utc().startOf("isoWeek");
        if (start < moment.utc()) {
            start = moment.utc().startOf("day");
        }

        const end = moment(date).utc().endOf("isoWeek");
        setCalendarDates([start.toDate(), end.toDate()]);
        setDates([start, end]);
    };

    return (
        <Box align="center" pad="large">
            <DropButton
                color="white"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                dropContent={
                    <Calendar
                        date={moment(startDate).utc().format("YYYY-MM-DD")}
                        daysOfWeek
                        onSelect={onSelect}
                        bounds={[
                            moment().utc().format("YYYY-MM-DD"),
                            moment().utc().add(1, "years").format("YYYY-MM-DD")
                        ]}
                    />
                }
            >
                <Box
                    direction="row"
                    gap="medium"
                    align="center"
                    pad="small"
                    background="white"
                    border="all"
                    round="small"
                >
                    <Text>
                        {startDate && endDate
                            ? `${moment(startDate)
                                  .utc()
                                  .format("DD/MM/YYYY")} - ${moment(endDate)
                                  .utc()
                                  .format("DD/MM/YYYY")}`
                            : "Select date"}
                    </Text>
                    <FormDown color="brand" />
                </Box>
            </DropButton>
        </Box>
    );
};
