import { gql } from "@apollo/client";
import { bookingListFields, notesFields } from "./fields";

export const BOOKING_CHECK_IN = gql`
    mutation checkIn($bookingId: ID!, $userId: ID!){
        checkIn(input: {
            bookingId: $bookingId,
            userId: $userId,
        }) {
            ${bookingListFields}
        }
    }
`;

export const BOOKING_CANCEL = gql`
    mutation cancelBooking($bookingId: ID!, $note: String){
        cancelBooking(input: {
            bookingId: $bookingId,
            note: $note
        }) {
            ${bookingListFields}
        }
    }
`;

export const BOOKING_PACKAGE_CANCEL = gql`
    mutation cancelAllBookingsInPackage($bookingId: ID!, $note: String){
        cancelAllBookingsInPackage(input: {
            bookingId: $bookingId,
            note: $note
        }) {
            ${bookingListFields}
        }
    }
`;

export const BOOKING_RESEND_EMAILS = gql`
    mutation resendBookingEmails($bookingId: ID!) {
        resendBookingEmails(input: { bookingId: $bookingId })
    }
`;

export const BOOKING_UPDATE_QUANTITY = gql`
    mutation updateBookingQuantity($bookingId: ID!, $quantity: Int!) {
        updateBookingQuantity(
            input: { bookingId: $bookingId, quantity: $quantity }
        ) {
            ${bookingListFields}
        }
    }
`;

export const BOOKING_UPDATE_TOTAL = gql`
    mutation updateBookingTotal($bookingId: ID!, $newTotal: Float!) {
        updateBookingTotal(
            input: { bookingId: $bookingId, newTotal: $newTotal }
        ) {
            ${bookingListFields}
        }
    }
`;

export const BOOKING_UPDATE_BALANCE = gql`
    mutation updateBookingBalance($bookingId: ID!, $newBalance: Float!) {
        updateBookingBalance(
            input: { bookingId: $bookingId, newBalance: $newBalance }
        ) {
            ${bookingListFields}
        }
    }
`;

export const ADD_MIDADMIN_NOTE_BOOKING_MUTATION = gql`
mutation addMidAdminNoteToBooking($bookingId: ID!, $content: String!, $noteType: NoteType!){
    addMidAdminNoteToBooking(input: {
        content: $content,
        bookingId: $bookingId
        noteType: $noteType
    }) {
        ${notesFields}
    }
}
`;

export const DIRECT_BOOKING = gql`
    mutation directBooking(
        $customerId: ID!,
        $activityInstanceId: ID!,
        $quantity: Int!,
        $total: Float!,
        $initialPayment: Float!,
        $note: String,
        $sendEmails: Boolean,
        $extras: [DirectBookingExtraInput!],
        $braintreeNonce: String,
        $bookingNames: [String!]) {
            
        directBooking(input:{
            customerId: $customerId
            activityInstanceId: $activityInstanceId
            quantity: $quantity
            total: $total
            initialPayment: $initialPayment
            note: $note
            sendEmails: $sendEmails
            extras: $extras
            braintreeNonce: $braintreeNonce
            bookingNames: $bookingNames
        }) {
            bookings {
                ${bookingListFields}
            }
            isSuccessful
            errorMessage
        }
    }
`;
