import { useQuery } from "@apollo/client";
import { Box, Heading, Paragraph } from "grommet";
import React, { useContext } from "react";
import { ACTIVITY_INSTANCES_NOW_NEXT } from "../../data/Activities/queries";
import { IActivityInstance } from "../../generated/dataInterfaces";
import ActivityInstanceItem from "../Activities/ActivityInstanceItem";
import AppContext from "../Context/AppContext";
import FacilityContext from "../Context/FacilityContext";
import Page from "../Page";
import EmptyPage from "./EmptyScreen";

const DashboardScreen = () => {
    const { organisationId } = useContext(AppContext);
    const { facilityId } = useContext(FacilityContext);
    const { data } = useQuery(ACTIVITY_INSTANCES_NOW_NEXT, {
        variables: {
            organisationId,
            facilityId
        },
        skip: false,
        fetchPolicy: "cache-and-network",
        pollInterval: 30000
    });

    const nowNext = data && data.instancesNowAndNext;

    if (nowNext) {
        const now: Array<IActivityInstance> = nowNext.now;
        const next: Array<IActivityInstance> = nowNext.next;

        return (
            <Page title="Dashboard">
                <Box
                    direction="column"
                    border="bottom"
                    margin={{
                        top: "none",
                        left: "small",
                        right: "small",
                        bottom: "xlarge"
                    }}
                    pad={{
                        top: "none",
                        left: "small",
                        right: "small",
                        bottom: "xlarge"
                    }}
                >
                    <Box flex="grow" pad="medium" margin={{ top: "medium" }}>
                        <Heading level="2" margin={{ top: "none" }}>
                            Now
                        </Heading>
                        {now && now.length > 0 ? (
                            <Box direction="row-responsive" wrap={true}>
                                {now.map((act) => (
                                    <ActivityInstanceItem
                                        instance={act}
                                        showActivity={true}
                                        showResource={true}
                                        key={act.id}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Paragraph>Nothing right now</Paragraph>
                        )}
                    </Box>

                    <Box flex="grow" pad="medium" margin={{ top: "medium" }}>
                        <Heading level="2" margin={{ top: "none" }}>
                            Next
                        </Heading>
                        {next && next.length > 0 ? (
                            <Box direction="row-responsive" wrap={true}>
                                {next.map((act) => (
                                    <ActivityInstanceItem
                                        instance={act}
                                        showActivity={true}
                                        showResource={true}
                                        key={act.id}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Paragraph>Nothing else today</Paragraph>
                        )}
                    </Box>
                </Box>
            </Page>
        );
    } else return <EmptyPage />;
};

export default DashboardScreen;
