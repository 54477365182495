import React from "react";
import { IBooking } from "../../../../generated/dataInterfaces";
import { Paragraph, Box, Heading, Text, Button } from "grommet";
import _ from "lodash";
import { Package, SmallPackage } from "../../../Icons/Package";

interface IPropsGroupedByResource {
    bookings: IBooking[];
    setActiveBooking: any;
}

export default ({ bookings, setActiveBooking }: IPropsGroupedByResource) => {
    const grouped = _.groupBy(
        bookings,
        (booking) => booking.activityInstance.activity.resource.id
    );

    return grouped ? (
        <Box>
            {Object.keys(grouped).map((key) => {
                const bookings = grouped[key];
                const resource = bookings[0].activityInstance.activity.resource;

                return (
                    <Box flex="grow" key={key}>
                        <Heading level="3">{resource.name}</Heading>
                        <Box flex="grow" wrap={true} direction="row-responsive">
                            {bookings.map((booking) => {
                                const isCancelled =
                                    booking.bookingStatus == "CANCELLED";

                                return (
                                    <Button
                                        key={booking.id}
                                        onClick={() =>
                                            setActiveBooking(booking.id)
                                        }
                                    >
                                        <Box
                                            margin="small"
                                            width="medium"
                                            pad="small"
                                            border="all"
                                            round="small"
                                            elevation="small"
                                            background={
                                                !isCancelled
                                                    ? "white"
                                                    : "light-2"
                                            }
                                        >
                                            <Heading
                                                level="4"
                                                margin={{
                                                    top: "small",
                                                    bottom: "small"
                                                }}
                                            >
                                                {
                                                    booking.activityInstance
                                                        .activity.name
                                                }{" "}
                                                {isCancelled ? (
                                                    <Text color="status-error">
                                                        CANCELLED
                                                    </Text>
                                                ) : null}
                                            </Heading>

                                            <Text margin={{ bottom: "xsmall" }}>
                                                {booking.date}
                                                {": "}
                                                {
                                                    booking.activityInstance
                                                        .timeFromToText
                                                }
                                            </Text>
                                            <Text margin={{ bottom: "xsmall" }}>
                                                Reference: {booking.reference}
                                            </Text>
                                            {booking.packageReference ? (
                                                <Text
                                                    margin={{
                                                        bottom: "xsmall"
                                                    }}
                                                >
                                                    <SmallPackage /> Package
                                                    reference:{" "}
                                                    {booking.packageReference}
                                                </Text>
                                            ) : null}
                                            <Text margin={{ bottom: "small" }}>
                                                People:{" "}
                                                {booking.activityQuantity}
                                            </Text>
                                            {booking.bookingNames &&
                                            booking.bookingNames.length > 0 ? (
                                                <Text
                                                    margin={{
                                                        bottom: "xsmall"
                                                    }}
                                                >
                                                    Name(s):{" "}
                                                    {booking.bookingNames.join(
                                                        ", "
                                                    )}
                                                </Text>
                                            ) : null}
                                            <Text margin={{ bottom: "small" }}>
                                                Total:{" £"}
                                                {booking.total.toFixed(2)}
                                            </Text>
                                            {booking.balance > 0 ? (
                                                <Text
                                                    margin={{
                                                        bottom: "small"
                                                    }}
                                                >
                                                    Balance:{" £"}
                                                    {booking.balance.toFixed(2)}
                                                </Text>
                                            ) : null}
                                            <Text
                                                color="accent-3"
                                                size="small"
                                                margin={{ top: "medium" }}
                                            >
                                                VIEW DETAILS
                                            </Text>
                                        </Box>
                                    </Button>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    ) : (
        <Box>Loading...</Box>
    );
};
