import {
    Box,
    Button,
    CheckBox,
    Grid,
    Layer,
    Text,
    TextInput,
    Heading
} from "grommet";
import { Close } from "grommet-icons";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ActivityInstanceItem from "../../Activities/ActivityInstanceItem";
import BookingContext from "../../Context/BookingContext";
import FindCustomer from "../../Customers/Finder/FindCustomer";
import BookingNames from "./BookingNames";

interface IPropsMakeBookingByActivityInstance {
    open: boolean;
    setOpen: any;
    otherProps?: any;
}

export default function BookingByActivityInstanceNoPayment({
    open,
    setOpen
}: IPropsMakeBookingByActivityInstance) {
    const {
        instanceToBook,
        ticketQty,
        setTicketQty,
        calculateTotal,
        extrasToBook,
        updateExtra,
        note,
        setNote,
        shouldSendEmails,
        setShouldSendEmails,
        setTotal,
        setInitialPayment,
        makeBooking
    } = useContext(BookingContext);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [totalPrice, setTotalPrice] = useState<string>();
    const [initialPaymentPrice, setInitialPaymentPrice] = useState<string>();

    useEffect(() => {
        setTotalPrice("");
        setInitialPaymentPrice("");
    }, [open]);

    useEffect(() => {
        console.log("NOPAY useEffect [ticketQty, extrasToBook]");
        const p = calculateTotal();

        setTotalPrice(p.toFixed(2));
        setTotal(p);
        setInitialPaymentPrice(p.toFixed(2));
        setInitialPayment(p);
    }, [ticketQty, extrasToBook]);

    const onClose = () => {
        setOpen(false);
    };

    const makeBookingExtended = async () => {
        try {
            console.log("makeBookingExtended");
            await makeBooking(null);
            toast("Bookings created");
            setButtonDisabled(false);
            setTotalPrice("");
            setTotal(0);
            setInitialPaymentPrice("");
            setInitialPayment(0);
            setNote("");
            onClose();
        } catch (e) {
            toast(e);
            setButtonDisabled(false);
        }
    };

    return open && instanceToBook ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box width="large" pad="small" overflow="auto">
                <Heading level="2" alignSelf="stretch">
                    Booking (no payment){" "}
                    <Button
                        icon={<Close />}
                        onClick={onClose}
                        alignSelf="end"
                    />
                </Heading>
                <Box flex={false} direction="row" justify="between">
                    <ActivityInstanceItem
                        instance={instanceToBook}
                        showActivity={true}
                        showResource={true}
                        showDate={true}
                        showPrice={true}
                        hideBookingLink={true}
                    />
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Find customer</strong>
                        </Box>
                        <Box>
                            {" "}
                            <FindCustomer />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Number of tickets</strong>
                        </Box>
                        <Box>
                            <TextInput
                                data-testid="ticket-quantity-input"
                                required
                                onChange={(e) => {
                                    const num = Number(e.target.value);
                                    setTicketQty(isNaN(num) ? 0 : num);
                                }}
                                value={ticketQty}
                                type="number"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    as="form"
                    direction="column"
                    margin={{ left: "small" }}
                >
                    {instanceToBook.activity.resource.extras.map((extra) => {
                        if (!extra.isAvailable) {
                            return null;
                        }
                        return (
                            <Box
                                key={extra.id}
                                direction="column"
                                margin={{
                                    top: "small",
                                    bottom: "medium"
                                }}
                            >
                                <Grid columns={["small", "flex"]} gap="xsmall">
                                    <Box
                                        margin={{
                                            top: "small",
                                            right: "small"
                                        }}
                                    >
                                        <Text size="small">{extra.name}</Text>
                                    </Box>
                                    <Box>
                                        <TextInput
                                            placeholder={`£${extra.price.toFixed(
                                                2
                                            )} each`}
                                            onChange={(e) => {
                                                const num = Number(
                                                    e.target.value
                                                );
                                                updateExtra(
                                                    extra.id,
                                                    isNaN(num) ? 0 : num
                                                );
                                            }}
                                            type="number"
                                        />
                                    </Box>
                                </Grid>
                            </Box>
                        );
                    })}
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Total price</strong>
                        </Box>
                        <Box>
                            <TextInput
                                value={totalPrice}
                                onChange={(e) => {
                                    console.log(
                                        "BookingByActivityInstanceNoPayment.tsx total e.target.value",
                                        e.target.value
                                    );
                                    const num = Number(e.target.value);
                                    setTotalPrice(e.target.value);
                                    setTotal(isNaN(num) ? 0 : num);
                                }}
                                type="text"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Initial payment</strong>
                        </Box>
                        <Box>
                            <TextInput
                                value={initialPaymentPrice}
                                onChange={(e) => {
                                    const num = Number(e.target.value);
                                    setInitialPaymentPrice(e.target.value);
                                    setInitialPayment(isNaN(num) ? 0 : num);
                                }}
                                type="text"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "small", right: "small" }}>
                            <strong>Note</strong>
                        </Box>
                        <Box>
                            <TextInput
                                value={note}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                                type="text"
                            />
                        </Box>
                    </Grid>
                </Box>

                <Box
                    flex={false}
                    direction="column"
                    margin={{ top: "small", bottom: "medium" }}
                >
                    <Grid columns={["small", "flex"]} gap="xsmall">
                        <Box margin={{ top: "none", right: "small" }}>
                            <strong>Send emails</strong>
                        </Box>
                        <Box>
                            <CheckBox
                                checked={shouldSendEmails}
                                onChange={(e) => {
                                    setShouldSendEmails(e.target.checked);
                                }}
                            />
                        </Box>
                    </Grid>
                </Box>

                <BookingNames />

                <Grid
                    columns={["small", "flex"]}
                    gap="xsmall"
                    margin={{ bottom: "medium" }}
                >
                    <Box margin={{ top: "small", right: "small" }} />
                    <Box>
                        <Button
                            data-testid="complete-booking-no-payment-button"
                            onClick={(e) => {
                                setButtonDisabled(true);
                                void makeBookingExtended();
                            }}
                            disabled={buttonDisabled}
                            primary
                            color="accent-2"
                        >
                            <Box
                                direction="column"
                                alignContent="center"
                                pad="small"
                                align="center"
                            >
                                {buttonDisabled
                                    ? `Creating booking`
                                    : `Complete booking`}
                            </Box>
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </Layer>
    ) : null;
}
