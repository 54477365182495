import React from "react";
import { ICustomerMembership } from "../../../../generated/dataInterfaces";
import { Box, Paragraph, Grid, Heading, Button, Image } from "grommet";
import moment from "moment";

interface IPropMembership {
    membership: ICustomerMembership;
}

export default ({ membership }: IPropMembership) => {
    return (
        <Box
            margin="none"
            width="large"
            pad="small"
            border="all"
            round="small"
            elevation="small"
            background="white"
        >
            <Grid columns={["small", "flex"]} gap="small">
                <Box direction="column">
                    {membership.photo.base64 ? (
                        <Image src={membership.photo.base64} fit="contain" />
                    ) : (
                        <p>No photo</p>
                    )}
                </Box>
                <Box direction="column">
                    <Heading
                        level="3"
                        margin="xsmall"
                        color={membership.isDisabled ? "status-error" : "black"}
                    >
                        {membership.name}{" "}
                        {membership.isDisabled ? "[DISABLED]" : null}
                    </Heading>
                    <Heading level="4" margin="xsmall">
                        {membership.membership.name}{" "}
                    </Heading>

                    {membership.hasBeenActivated ? (
                        <Paragraph>
                            Activated:{" "}
                            {moment(membership.activatedAt)
                                .utc()
                                .format("YYYY-MM-DD")}
                            <br />
                            Expires:{" "}
                            {moment(membership.expiresAt)
                                .utc()
                                .format("YYYY-MM-DD")}
                        </Paragraph>
                    ) : (
                        <Paragraph color="status-critical">
                            Membership has not been activated
                        </Paragraph>
                    )}
                </Box>
            </Grid>
        </Box>
    );
};
