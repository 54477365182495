import { Box, Heading } from "grommet";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { IDayActivity } from "../../generated/dataInterfaces";
import { SmallLink } from "../Icons/LinkIndicators";
import ActivityInstanceItem from "./ActivityInstanceItem";
interface IPropsDayInstances {
    day: IDayActivity;
    showActivity: boolean;
    showResource: boolean;
    activityId?: string;
    otherProps?: any;
}

const DayInstanceItem = ({
    day,
    showActivity,
    showResource,
    activityId
}: IPropsDayInstances) => {
    console.log("day", day);

    const activities = activityId
        ? _.sortBy(
              day.activityInstances.filter(
                  (ai) => ai.activity.id == activityId
              ),
              [
                  (instance) => {
                      return instance.startTime;
                  }
              ]
          )
        : _.sortBy(day.activityInstances, [
              (instance) => {
                  return instance.startTime;
              }
          ]);

    return (
        <Box
            id={day.date}
            margin="small"
            width="flow"
            pad="small"
            border="bottom"
        >
            <Link to={`#${day.date}`}>
                <Heading level="3" margin={{ top: "none" }}>
                    {moment(day.date).utc().format("YYYY-MM-DD") ===
                    moment().utc().format("YYYY-MM-DD")
                        ? "Today: "
                        : ""}
                    {day.dayText} <SmallLink />
                </Heading>
            </Link>
            <Box direction="row-responsive" wrap={true}>
                {activities.map((act) => (
                    <ActivityInstanceItem
                        instance={act}
                        showActivity={showActivity}
                        showResource={showResource}
                        key={act.id}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default DayInstanceItem;
