export const customerListFields = `
id
name
searchableUpper
numberOfBookings
postcode
namePostcode
isDisabled
`;

export const notesFields = `
id
createdAt
createdByUser {
    id
    name
}
content
noteType
`;

const bookingFields = `
id
activityStartsAt
activityPrice
activityQuantity
completedAt
extrasCount
bookingStatus
date
time
total
balance
reference
packageReference
bookingNames
bookingExtras{
    id
    name
    description
    quantity
    pricePerItem
    discountPerItem
}
activityInstance{
    id
    startTime
    endTime
    timeFromToText
    activity{
        id
        name
        resource{
            id
            name
        }
    }
}
transactions{
    isComplete
    completedAt
    amount
    providerIdentifier
    authCode
        }
`;

const credentialFields = `
id
fullNameText
firstName
isDisabled
isLockedOut
login
email
isPrimaryCredential
mobileNumber
doesReceiveSms
doesReceiveEmails
lastLoginAt
password{
numberOfIncorrectAttempts
lastLockedOutAt
lastChangedAt
}
`;

const discountFields = `
id
name
description
isActive
discountAmount
discountType
appliesToActivities
appliesToExtras
validDaysOfWeek
validActivityIds
`;

const benefitsFields = `
id
name
description
expiresAt
createdAt
discount{
    ${discountFields}
}
`;

const membershipFields = `
id
name
hasBeenActivated
isDisabled
activatedAt
expiresAt
photo {
    base64
}
membership{
    name
    discountAmount
}
`;

export const customerFullFields = `

      id
      name
      postcode
      searchableUpper
      isDisabled
      createdAt
      updatedAt
      benefits{
          ${benefitsFields}
      }
      customerNotes{
         ${notesFields}
      }
      midAdminNotes {
        ${notesFields}
      }
      pastBookings {
        ${bookingFields}
      }
      futureBookings {
        ${bookingFields}
      }
      packagedBookings{
        ${bookingFields}
      }
      credentials{
       ${credentialFields}
      }
      memberships{
          ${membershipFields}
      }

`;
