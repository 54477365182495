import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Box, Form, FormField, Button, Image, Text, Paragraph } from "grommet";
import AppContext, {
    useAppRedirect
} from "../../components/Context/AppContext";
import { Redirect, Link } from "react-router-dom";
import {
    useLogin,
    useAuthenticated,
    usePasswordReset
} from "../../components/Context/AuthContext";

export default ({ token }) => {
    const [message, setMessage] = useState("");
    const { redirectTo } = useAppRedirect();
    const { loginUser } = useLogin();
    const { passwordReset } = usePasswordReset();
    const { isAuthenticated } = useAuthenticated();

    const attemptPasswordReset = async (password: string) => {
        if (!password || password.length < 8) {
            setMessage(
                `Please ensure that your password is 8 characters or more.`
            );
            return;
        }

        const passwordResetResponse = await passwordReset(token, password);

        if (!passwordResetResponse) {
            setMessage(`Password reset failed`);
        } else {
            setMessage(`Your password has been reset. Please log in.`);
        }
    };

    // if (isAuthenticated) {
    //     return <Redirect to={redirectTo} />;
    // }

    return (
        <>
            <Box
                fill
                align="center"
                justify="center"
                margin={{ bottom: "xlarge" }}
            >
                <Box width="medium" margin={{ bottom: "xlarge" }}>
                    <Box align="center" pad="large">
                        <Image
                            width="100px"
                            src="https://www.lesormesjersey.co.uk/assets/images/logo-white.svg"
                        />
                    </Box>
                    {message ? (
                        <Box
                            background="brand"
                            round="small"
                            pad="small"
                            margin={{ bottom: "small" }}
                        >
                            <Text size="small" color="white">
                                {message}
                            </Text>
                        </Box>
                    ) : null}

                    <Form
                        onSubmit={({ value }) => {
                            attemptPasswordReset(value.password);
                        }}
                    >
                        <Paragraph>Enter your new password</Paragraph>
                        <FormField
                            label="Password"
                            name="password"
                            type="password"
                            required
                        />
                        <Box direction="column" margin={{ top: "medium" }}>
                            <Button
                                primary
                                type="submit"
                                label="Reset password"
                            />

                            <Button
                                margin={{ top: "small" }}
                                plain
                                alignSelf="center"
                            >
                                <Link to={`/login`}>Return to log in</Link>
                            </Button>
                        </Box>
                    </Form>
                </Box>
            </Box>
        </>
    );
};
