import { Paragraph } from "grommet";
import { Alarm } from "grommet-icons";
import React from "react";
import Page from "../Page";

const EmptyScreen = () => {
    return (
        <Page title={<Alarm />}>
            <Paragraph />
        </Page>
    );
};

export default EmptyScreen;
