import React, { useState } from "react";
import {
    IBooking,
    ICredential,
    ICustomer,
    INote
} from "../../../../generated/dataInterfaces";
import { Paragraph, Box, Heading, Text, Button } from "grommet";
import { Lock } from "grommet-icons";
import _ from "lodash";
import CredentialDetail from "../../Detail/CredentialDetail";
import moment from "moment";
import { defaultFieldResolver } from "graphql";

interface IPropsNotes {
    notes: INote[];
}

export default ({ notes }: IPropsNotes) => {
    const groupedNotes = _.groupBy(notes, "noteType");

    const label = (key) => {
        switch (key) {
            case "ACTIVITIES":
                return "Activities";
            case "ACCOUNT":
                return "Account";
            case "FOOD_AND_BEVERAGES":
                return "Food & Beverages";
            default:
                return key;
        }
    };

    return groupedNotes ? (
        <>
            <Box flex="grow" direction="column" background="light-1" pad="none">
                {Object.keys(groupedNotes).map((key, index) => {
                    const type = label(key);
                    const notes = groupedNotes[key];

                    const noteLayout = notes.map((note) => {
                        const created = moment(note.createdAt)
                            .utc()
                            .format("dddd, MMMM Do YYYY, HH:mm");
                        const userName = note.createdByUser
                            ? note.createdByUser.name
                            : "";
                        return (
                            <Box
                                key={note.id}
                                margin="xsmall"
                                width="full"
                                pad="small"
                                border="all"
                                round="small"
                                elevation="small"
                                flex={false}
                                responsive={true}
                                background="white"
                            >
                                <Box direction="column" flex={true}>
                                    <Text
                                        size="small"
                                        alignSelf="stretch"
                                        margin={{
                                            bottom: "small",
                                            top: "none"
                                        }}
                                    >
                                        <strong>{created} </strong>
                                        {userName ? `(${userName})` : ""}
                                    </Text>
                                </Box>
                                <Box direction="column">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: note.content.replace(
                                                /(<? *script)/gi,
                                                "illegalscript"
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>
                        );
                    });

                    return (
                        <Box key={`grouped-${index}`}>
                            <Heading level="4">{type}</Heading>
                            {noteLayout}
                        </Box>
                    );
                })}
            </Box>
        </>
    ) : (
        <Box>No notes</Box>
    );
};
