import React, { useEffect } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useAppRedirect } from "../Context/AppContext";
import { useAuthenticated } from "../Context/AuthContext";

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useAuthenticated();
    const { redirectTo, setRedirectTo } = useAppRedirect();

    useEffect(() => {
        const redirect: string = rest.location.pathname;
        setRedirectTo(redirect && redirect !== "/login" ? redirect : "/");
    }, [isAuthenticated, setRedirectTo, rest.location.pathname]);

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: redirectTo }
                        }}
                    />
                )
            }
        />
    );
};

// @ts-expect-error types are hard
export default withRouter(PrivateRoute);
