import React, { useState } from "react";
import { IBooking, ICredential } from "../../../../generated/dataInterfaces";
import { Paragraph, Box, Heading, Text, Button } from "grommet";
import { Lock } from "grommet-icons";
import _ from "lodash";
import CredentialDetail from "./CredentialDetail";

interface IPropsCredentials {
    credentials: ICredential[];
}

export default ({ credentials }: IPropsCredentials) => {
    const [credentialId, setCredentialId] = useState();
    const [openCredential, setOpenCredential] = useState(false);

    const setActiveCredential = (id) => {
        setCredentialId(id);
        setOpenCredential(true);
    };

    return credentials ? (
        <>
            <Box
                flex="grow"
                direction="column"
                background="light-1"
                pad={{
                    left: "medium",
                    right: "medium",
                    bottom: "large",
                    top: "large"
                }}
            >
                <Heading level="3">Credentials</Heading>
                <CredentialDetail
                    open={openCredential}
                    setOpen={setOpenCredential}
                    credentialId={credentialId}
                />
                <Box direction="row-responsive">
                    {credentials.map((credential) => {
                        return (
                            <Button
                                key={credential.id}
                                onClick={() =>
                                    setActiveCredential(credential.id)
                                }
                            >
                                <Box
                                    margin="small"
                                    width="medium"
                                    pad="small"
                                    border="all"
                                    round="small"
                                    elevation="small"
                                    background="white"
                                >
                                    <Box
                                        flex={false}
                                        direction="row"
                                        justify="between"
                                    >
                                        <Heading
                                            level="4"
                                            margin={{
                                                top: "small",
                                                bottom: "small"
                                            }}
                                        >
                                            {credential.fullNameText}
                                        </Heading>

                                        {credential.isPrimaryCredential ? (
                                            <Heading
                                                level="4"
                                                margin={{
                                                    top: "small",
                                                    bottom: "small"
                                                }}
                                                textAlign="end"
                                                alignSelf="end"
                                                color="brand"
                                            >
                                                Primary
                                            </Heading>
                                        ) : null}
                                    </Box>
                                    <Text margin={{ bottom: "xsmall" }}>
                                        {credential.email}
                                        {" / "}
                                        {credential.mobileNumber
                                            ? credential.mobileNumber
                                            : "no mobile"}
                                    </Text>
                                    <Text margin={{ bottom: "medium" }} />

                                    <Box
                                        flex={false}
                                        direction="row"
                                        justify="between"
                                    >
                                        <Text color="accent-3" size="small">
                                            VIEW DETAILS
                                        </Text>
                                        {credential.isLockedOut ||
                                        credential.isDisabled ? (
                                            <>
                                                {"   "}
                                                <Lock />
                                            </>
                                        ) : null}
                                    </Box>
                                </Box>
                            </Button>
                        );
                    })}
                </Box>
            </Box>
        </>
    ) : (
        <Box>Loading...</Box>
    );
};
