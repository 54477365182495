export const customerMembershipFullDetails = `
id
name
membership {
    id
    name
    price
}
transactions{
    id
    amount
    description
    providerIdentifier
    isComplete
    completedAt
}
customer{
    id
}
hasBeenActivated
isDisabled
activatedAt
expiresAt
completedAt
createdAt
updatedAt
photo{
    base64
}
`;
