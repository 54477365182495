import React, { createContext, useMemo, useState } from "react";
import useStateWithLocalStorage from "../Custom/useStateWithLocalStorageSimple";

const BookingListContext = createContext<IBookingListContext>(null);

function BookingListContextProvider(props) {
    const [shouldRefresh, setShouldRefresh] = useState(true);

    const values = useMemo(() => {
        return {
            shouldRefresh,
            setShouldRefresh
        };
    }, [shouldRefresh]);

    return <BookingListContext.Provider value={values} {...props} />;
}

export interface IBookingListContext {
    shouldRefresh: boolean;
    setShouldRefresh: (value: boolean) => void;
}

export default BookingListContext;
export { BookingListContextProvider };
