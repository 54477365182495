import { Paragraph, Heading, Box, Button, Text } from "grommet";
import { IActivity, IResource } from "../../generated/dataInterfaces";
import React from "react";
import { Link } from "react-router-dom";
import { SmallLink } from "../Icons/LinkIndicators";
interface IPropsResourceItem {
    resource: IResource;
    otherProps?: any;
}
export default ({ resource, ...otherProps }: IPropsResourceItem) => {
    return (
        <Link
            id={resource.id}
            key={resource.id}
            to={`/resource/${resource.id}`}
        >
            <Box
                margin="small"
                width="medium"
                pad="small"
                border="all"
                round="small"
                elevation="small"
                background="white"
                wrap={true}
                key={resource.id}
            >
                <Heading level="3" margin={{ top: "none" }}>
                    {resource.name} <SmallLink />
                </Heading>

                <Text color="accent-3" size="small">
                    VIEW RESOURCE
                </Text>
            </Box>
        </Link>
    );
};
