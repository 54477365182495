import { useQuery } from "@apollo/client";
import { Box, Button, Heading, Text } from "grommet";
import React, { useContext, useEffect, useState } from "react";
import { ACTIVITY_INSTANCE_WITH_BOOKINGS } from "../../data/Activities/queries";
import { IActivityInstance } from "../../generated/dataInterfaces";
import BookingTable from "../Bookings/BookingTable";
import BookingByActivityInstanceNoPayment from "../Bookings/Make/BookingByActivityInstanceNoPayment";
import BookingByActivityInstanceWithPayment from "../Bookings/Make/BookingByActivityInstanceWithPayment";
import BookingContext from "../Context/BookingContext";
import BookingListContext from "../Context/BookingListContext";
import { SmallLink } from "../Icons/LinkIndicators";
import { Package } from "../Icons/Package";
import Page from "../Page";
import EmptyPage from "./EmptyScreen";

const useInstanceBookingData = (
    id: string,
    shouldLoadData: boolean,
    setShouldLoadData: (value: boolean) => void
) => {
    const [activityInstance, setActivityInstance] =
        useState<IActivityInstance>();
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);
    const { data, error, loading } = useQuery(ACTIVITY_INSTANCE_WITH_BOOKINGS, {
        variables: {
            activityInstanceId: id
        },
        skip: !shouldLoadData,
        fetchPolicy: "no-cache"
    });

    if (data && data.bookingsByActivityInstance && !loading) {
        setActivityInstance(
            data.bookingsByActivityInstance as IActivityInstance
        );
        setShouldLoadData(false);
        setShouldRefresh(false);
    }
    return activityInstance;
};

const ActivityInstanceBookingsScreen = ({
    match
}: {
    match: { params: { id: string } };
}) => {
    const { id } = match.params;

    const [shouldLoadData, setShouldLoadData] = useState(true);
    const [openBookingNoPayment, setOpenBookingNoPayment] =
        useState<boolean>(false);
    const [openBookingWithPayment, setOpenBookingWithPayment] =
        useState<boolean>(false);
    const { setInstanceToBook } = useContext(BookingContext);

    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);

    useEffect(() => {
        setShouldLoadData(shouldLoadData || shouldRefresh);
    }, [shouldRefresh, shouldLoadData]);

    const activityInstance = useInstanceBookingData(
        id,
        shouldLoadData,
        setShouldLoadData
    );

    const setOpenBookingNoPaymentExtended = (value: boolean) => {
        setOpenBookingNoPayment(value);
        setShouldRefresh(true);
    };

    const setOpenBookingWithPaymentExtended = (value: boolean) => {
        setOpenBookingWithPayment(value);
        setShouldRefresh(true);
    };

    const makeABookingNoPayment = () => {
        setInstanceToBook(activityInstance);
        setOpenBookingNoPayment(true);
    };

    const makeABookingWithPayment = () => {
        setInstanceToBook(activityInstance);
        setOpenBookingWithPayment(true);
    };

    const header = activityInstance ? (
        <Box
            direction="row-responsive"
            margin="none"
            pad={{
                top: "none",
                left: "large",
                right: "medium",
                bottom: "none"
            }}
            border="bottom"
        >
            <Box
                direction="column"
                flex="grow"
                pad="none"
                margin={{ bottom: "medium" }}
            >
                <Button
                    href={`/resource/${activityInstance.activity.resource.id}`}
                >
                    <Heading level="2" margin={{ top: "none" }}>
                        {activityInstance.activity.resource.name} <SmallLink />
                    </Heading>
                </Button>
                <Button href={`/activities/${activityInstance.activity.id}`}>
                    <Heading level="3" margin={{ top: "none" }}>
                        {activityInstance.activity.name} <SmallLink />
                    </Heading>
                </Button>

                <Text color="text" size="large" style={{ fontWeight: "bold" }}>
                    {activityInstance.daySchedule.dayText}
                    <br />
                    {activityInstance.timeFromToText}
                </Text>
                <Text color="text" size="large" margin={{ top: "small" }}>
                    {activityInstance.availabilityText}
                    <br />
                    {activityInstance.checkedInText}
                </Text>
            </Box>

            <Box direction="column">
                {activityInstance.package ? (
                    <>
                        <Heading level="4" margin={{ top: "none" }}>
                            <Package /> {activityInstance.package.name}
                        </Heading>
                        <Heading level="5" margin={{ top: "none" }}>
                            All dates in this package:
                        </Heading>

                        <ul style={{ marginTop: 0, padding: 0 }}>
                            {activityInstance.package.activityInstances.map(
                                (a) => (
                                    <li
                                        key={a.id}
                                        style={{ listStyleType: "none" }}
                                    >
                                        <a
                                            href={`/activities/instance/${a.id}`}
                                        >
                                            {a.daySchedule.dayText},{" "}
                                            {a.timeFromToText}
                                        </a>
                                    </li>
                                )
                            )}
                        </ul>
                    </>
                ) : (
                    <>
                        <Box margin={{ bottom: "medium" }}>
                            <Button
                                label="Create booking (no payment)"
                                onClick={() => makeABookingNoPayment()}
                            />
                        </Box>
                        <Box>
                            <Button
                                label="Make and pay for a booking"
                                onClick={() => makeABookingWithPayment()}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    ) : null;

    return activityInstance ? (
        <Page title="Activity bookings">
            {openBookingNoPayment ? (
                <BookingByActivityInstanceNoPayment
                    open={openBookingNoPayment}
                    setOpen={setOpenBookingNoPaymentExtended}
                />
            ) : null}
            {openBookingWithPayment ? (
                <BookingByActivityInstanceWithPayment
                    open={openBookingWithPayment}
                    setOpen={setOpenBookingWithPaymentExtended}
                />
            ) : null}

            {header}
            <Box
                direction="column"
                background="white"
                pad={{ left: "medium", right: "medium" }}
            >
                <BookingTable
                    hideActivityInfo={true}
                    listData={activityInstance.bookings}
                />
            </Box>
        </Page>
    ) : (
        <EmptyPage />
    );
};

export default ActivityInstanceBookingsScreen;
