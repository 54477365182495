import { Box, Button, Form, FormField, Image, Text } from "grommet";
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import AppContext, {
    useAppRedirect
} from "../../components/Context/AppContext";
import {
    useAuthenticated,
    useLogin,
    useRequestPasswordReset
} from "../../components/Context/AuthContext";

const Login = () => {
    const [message, setMessage] = useState("");
    const { redirectTo } = useAppRedirect();
    const { loginUser } = useLogin();
    const { requestPasswordReset } = useRequestPasswordReset();
    const { isAuthenticated } = useAuthenticated();
    const [showForgottenPassword, setShowForgottenPassword] = useState(false);

    const { organisationId } = useContext(AppContext);

    const attemptLogin = async (email: string, password: string) => {
        const isLoggedIn = await loginUser(email, password);

        if (!isLoggedIn) {
            setMessage(`Login failed for ${email}`);
        } else {
            setMessage("SUCCESS!");
        }
    };
    const attemptPasswordResetRequest = async (email: string) => {
        const passwordReset = await requestPasswordReset(email);

        if (!passwordReset) {
            setMessage(`Password reset request failed`);
        } else {
            setMessage(
                `You have been sent an email with a link to reset your password.`
            );
        }
    };

    if (isAuthenticated) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <>
            <Box
                fill
                align="center"
                justify="center"
                margin={{ bottom: "xlarge" }}
            >
                <Box width="medium" margin={{ bottom: "xlarge" }}>
                    <Box align="center" pad="large">
                        <Image
                            width="100px"
                            src="https://www.lesormesjersey.co.uk/assets/images/logo-white.svg"
                        />
                    </Box>
                    {message ? (
                        <Box
                            background="brand"
                            round="small"
                            pad="small"
                            margin={{ bottom: "small" }}
                        >
                            <Text size="small" color="white">
                                {message}
                            </Text>
                        </Box>
                    ) : null}

                    {!showForgottenPassword ? (
                        <Form
                            onSubmit={({ value }) => {
                                attemptLogin(value.email, value.password);
                            }}
                        >
                            <FormField
                                label="Email"
                                name="email"
                                type="email"
                                required
                                data-testid="email-input"
                            />
                            <FormField
                                label="Password"
                                name="password"
                                type="password"
                                required
                                data-testid="password-input"
                            />
                            <Box direction="column" margin={{ top: "medium" }}>
                                <Button
                                    primary
                                    type="submit"
                                    label="Sign in"
                                    data-testid="sign-in-button"
                                />
                                <Button
                                    margin={{ top: "small" }}
                                    label="Forgotten password?"
                                    plain
                                    alignSelf="center"
                                    onClick={() =>
                                        setShowForgottenPassword(true)
                                    }
                                />
                            </Box>
                        </Form>
                    ) : (
                        <Form
                            onSubmit={({ value }) => {
                                attemptPasswordResetRequest(value.email);
                            }}
                        >
                            <FormField
                                label="Email"
                                name="email"
                                type="email"
                                required
                            />

                            <Box direction="column" margin={{ top: "medium" }}>
                                <Button
                                    primary
                                    type="submit"
                                    label="Send reset request"
                                />
                                <Button
                                    margin={{ top: "small" }}
                                    plain
                                    alignSelf="center"
                                    label="Return to login"
                                    onClick={() =>
                                        setShowForgottenPassword(false)
                                    }
                                />
                            </Box>
                        </Form>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default Login;
