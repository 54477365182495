import { useMutation, useQuery } from "@apollo/client";
import DropIn from "braintree-web-drop-in-react";
import { Box, Button, Text } from "grommet";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BRAINTREE_CLIENT_TOKEN_QUERY } from "../../../../data/Braintree/queries";
import { RENEW_CUSTOMER_MEMBERSHIP_MUTATION } from "../../../../data/Memberships/mutations";
import {
    ICustomerMembership,
    IRenewCustomerMembershipInput
} from "../../../../generated/dataInterfaces";

interface IRenewMembershipProps {
    customerId: string;
    customerMembershipId: string;
    price: number;
    customerMembershipName: string;
    onRenewed: () => void;
}

const RenewMembership = ({
    customerId,
    customerMembershipId,
    price,
    customerMembershipName,
    onRenewed
}: IRenewMembershipProps) => {
    const [braintreeClientToken, setBraintreeClientToken] = useState(null);
    const [braintreeInstance, setBraintreeInstance] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

    useEffect(() => {
        if (braintreeInstance) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [braintreeInstance]);

    const { data, error, loading } = useQuery<{
        braintreeClientToken: { token: string };
    }>(BRAINTREE_CLIENT_TOKEN_QUERY, {
        variables: {
            customerId
        },
        skip: braintreeClientToken
    });

    if (data && data.braintreeClientToken && !braintreeClientToken) {
        setBraintreeClientToken(data.braintreeClientToken.token);
    }

    const [renewCustomerMembership] = useMutation<
        { renewCustomerMembership: ICustomerMembership },
        IRenewCustomerMembershipInput
    >(RENEW_CUSTOMER_MEMBERSHIP_MUTATION, {
        onCompleted({ renewCustomerMembership }) {
            toast("The membership was renewed");
        },
        onError(errors) {
            toast("The membership wasn't renewed");
            console.log(errors);
        }
    });

    return (
        <>
            <Box margin={{ bottom: "medium" }}>
                <Text size="medium">
                    Renew {customerMembershipName} for £{price}
                </Text>
                {braintreeClientToken ? (
                    <>
                        <DropIn
                            options={{
                                authorization: braintreeClientToken,
                                translations: {
                                    payingWith: "",
                                    chooseAnotherWayToPay:
                                        "Enter new card details",
                                    chooseAWayToPay: "Enter card details",
                                    saveCardLabel:
                                        "Save card against customer account? You must have their permission."
                                },
                                threeDSecure: true,
                                vaultManager: true,
                                card: {
                                    cardholderName: {
                                        required: true
                                    },
                                    vault: {
                                        vaultCard: false,
                                        allowVaultCardOverride: true
                                    }
                                }
                            }}
                            onInstance={(instance) => {
                                setBraintreeInstance(instance);
                            }}
                        />
                        <Button
                            onClick={async () => {
                                setIsPaymentInProgress(true);
                                setIsDisabled(true);
                                const { nonce } =
                                    await braintreeInstance.requestPaymentMethod(
                                        {
                                            threeDSecure: {
                                                amount: price,
                                                challengeRequested: true
                                            }
                                        }
                                    );
                                const { data } = await renewCustomerMembership({
                                    variables: {
                                        customerMembershipId,
                                        braintreeClientToken: nonce
                                    }
                                });
                                if (data && data.renewCustomerMembership) {
                                    onRenewed();
                                }
                                setIsPaymentInProgress(false);
                                setIsDisabled(false);
                            }}
                            disabled={isDisabled}
                            primary
                            color="accent-2"
                            label={isPaymentInProgress ? "Paying" : "Renew"}
                            margin="medium"
                        />
                    </>
                ) : (
                    <Text>Loading Braintree</Text>
                )}
            </Box>
        </>
    );
};
export default RenewMembership;
