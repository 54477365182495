import { gql } from "@apollo/client";
import { customerListFields, customerFullFields } from "./fields";

export const CUSTOMER_SEARCH = gql`
    query searchCustomers($organisationId: ID!, $searchTerm: String!, $showDisabled: Boolean){
      searchCustomers(input: {
        organisationId: $organisationId,
        searchTerm: $searchTerm,
        showDisabled: $showDisabled
      }) {
         ${customerListFields}
      }
    }
`;

export const CUSTOMER_FULL = gql`
  query customerWithBookingsAndDetails($customerId:ID!){
      customer(where: { id: $customerId}){
          ${customerFullFields}
      }
  }
`;
