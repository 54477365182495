import { useQuery } from "@apollo/client";
import { Box, Heading, Paragraph } from "grommet";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { INSTANCES_BY_DATE_RANGE } from "../../data/Resources/queries";
import {
    IDateRangeByResource,
    IDayActivity
} from "../../generated/dataInterfaces";
import DayInstanceItem from "../Activities/DayInstanceItem";
import WeekSelectCalendarDropButton from "../Activities/WeekSelectCalendarDropButton";
import Page from "../Page";
import EmptyPage from "./EmptyScreen";

const ActivityInstancesByResourceAndDateScreen = ({
    match
}: {
    match: { params: { id: string } };
}) => {
    const { id } = match.params;
    const [
        dateRangeByActivity,
        setDateRangeByActivity
    ] = useState<IDateRangeByResource>(null);
    const [shouldLoadData, setShouldLoadData] = useState(true);
    const [[startDate, endDate], setDates] = useState([
        moment().startOf("day").toDate(),
        moment().endOf("isoWeek").toDate()
    ]);

    const { data, loading } = useQuery(INSTANCES_BY_DATE_RANGE, {
        variables: {
            resourceId: id,
            startDate,
            endDate
        },
        skip: !shouldLoadData,
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (data && data.instancesByResourceAndDateRange && !loading) {
            setShouldLoadData(false);
            setDateRangeByActivity(
                data.instancesByResourceAndDateRange as IDateRangeByResource
            );

            toast("Date range updated");
        }
    }, [data, loading]);

    const setDatesAndReload = ([startDate, endDate]) => {
        setDates([startDate, endDate]);
        setShouldLoadData(true);
    };

    return dateRangeByActivity ? (
        <Page title="Resource calendar">
            <Box
                direction="row-responsive"
                border="bottom"
                margin={{
                    top: "none",
                    left: "small",
                    right: "small",
                    bottom: "none"
                }}
                pad={{
                    top: "none",
                    left: "small",
                    right: "small",
                    bottom: "none"
                }}
            >
                <Box direction="column" flex="grow" pad="none" margin="none ">
                    <Heading level="2" margin={{ top: "none" }}>
                        {dateRangeByActivity.resource.name}
                    </Heading>
                </Box>
                <Box direction="column" align="end">
                    <WeekSelectCalendarDropButton
                        dates={[startDate, endDate]}
                        setDates={setDatesAndReload}
                    />
                </Box>
            </Box>

            <Box direction="column">
                {dateRangeByActivity.days &&
                dateRangeByActivity.days.length > 0 ? (
                    dateRangeByActivity.days.map((day, index) => (
                        <DayInstanceItem
                            key={index}
                            day={(day as unknown) as IDayActivity}
                            showActivity={true}
                            showResource={false}
                        />
                    ))
                ) : (
                    <Box
                        margin="small"
                        width="flow"
                        pad="small"
                        border="bottom"
                    >
                        <Paragraph>
                            There are no activities scheduled this week. Please
                            adjust the date range.
                        </Paragraph>
                    </Box>
                )}
            </Box>
        </Page>
    ) : (
        <EmptyPage />
    );
};

export default ActivityInstancesByResourceAndDateScreen;
