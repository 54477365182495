import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
    mutation loginUser($email: String!, $password: String!) {
        loginUser(data: { email: $email, password: $password }) {
            token
        }
    }
`;

export const REQUEST_RESET_USER_PASSWORD = gql`
    mutation requestUserPasswordReset($email: String!, $webAppRoot: String!) {
        requestUserPasswordReset(
            input: { email: $email, webAppRoot: $webAppRoot }
        ) {
            id
            name
            passwordResetRequestedAt
            password {
                lastChangedAt
            }
        }
    }
`;

export const RESET_USER_PASSWORD = gql`
    mutation resetUserPassword($securityStamp: String!, $newPassword: String!) {
        resetUserPassword(
            input: { securityStamp: $securityStamp, newPassword: $newPassword }
        ) {
            id
            name
            passwordResetRequestedAt
            password {
                lastChangedAt
            }
        }
    }
`;
