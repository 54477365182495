import { Box, Heading, Paragraph } from "grommet";
import _ from "lodash";
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { ACTIVITY_SUMMARIES_BY_RESOURCE_BY_FACILITY } from "../../data/Activities/queries";
import { IFacility } from "../../generated/dataInterfaces";
import ActivityItem from "../Activities/ActivityItem";
import AppContext from "../Context/AppContext";
import FacilityContext from "../Context/FacilityContext";
import { SmallLink } from "../Icons/LinkIndicators";
import Page from "../Page";
import EmptyPage from "./EmptyScreen";
export default () => {
    const { organisationId, userId } = useContext(AppContext);
    const { facilityId } = useContext(FacilityContext);
    const { data, error, loading } = useQuery(
        ACTIVITY_SUMMARIES_BY_RESOURCE_BY_FACILITY,
        {
            variables: {
                organisationId,
                facilityId
            },
            skip: false,
            fetchPolicy: "network-only"
        }
    );

    const facilities: IFacility[] = data ? data.facilitiesByOrg : null;

    if (facilities) {
        const resources = _.flatten(
            facilities.map((facility) => facility.resources)
        );
        const resourceItems = resources.map((resource) => (
            <Box
                id={resource.id}
                key={resource.id}
                pad={{ horizontal: "medium", vertical: "medium" }}
            >
                <Link to={`/activities/#${resource.id}`}>
                    <Heading level="2" margin={{ top: "none" }}>
                        {resource.name} <SmallLink />
                    </Heading>
                </Link>

                <Box direction="row" wrap={true}>
                    {resource.activities.map((activity) => (
                        <ActivityItem activity={activity} key={activity.id} />
                    ))}
                </Box>
            </Box>
        ));

        return (
            <Page title="Activities">
                {!loading && facilities ? (
                    <div>{resourceItems}</div>
                ) : (
                    <Paragraph>Loading...</Paragraph>
                )}
            </Page>
        );
    } else {
        return <EmptyPage />;
    }
};
