import { Box, Button, Grid, Heading, Layer, Paragraph } from "grommet";
import { Close } from "grommet-icons";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
    ACTIVATE_MEMBERSHIP_MUTATION,
    SAVE_IMAGE_MEMBERSHIP_MUTATION,
    TOGGLE_DISABLED_MEMBERSHIP_MUTATION
} from "../../../../data/Memberships/mutations";
import { CUSTOMER_MEMBERSHIP_FULL_DETAILS } from "../../../../data/Memberships/queries";
import { ICustomerMembership } from "../../../../generated/dataInterfaces";
import BookingListContext from "../../../Context/BookingListContext";
import FieldValue from "../../../Custom/FieldValue";
import PhotoUpload from "../../../Util/PhotoUpload";
import RenewMembership from "./RenewMembership";

interface IPropsMembershipDetail {
    customerMembershipId: string;
    open: boolean;
    setOpen: (flag: boolean) => void;
}

export default ({
    open,
    setOpen,
    customerMembershipId
}: IPropsMembershipDetail) => {
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);
    const [base64ImageData, setBase64ImageData] = useState(null);
    const [
        disableSavePhotoButton,
        setDisableSavePhotoButton
    ] = useState<boolean>(false);
    const [mustShowRenewMembership, setMustShowRenewMembership] = useState(
        false
    );

    const onClose = () => {
        open = false;
        setShouldRefresh(true);
        setMustShowRenewMembership(false);
        setOpen(false);
    };

    const { data } = useQuery<{ customerMembership: ICustomerMembership }>(
        CUSTOMER_MEMBERSHIP_FULL_DETAILS,
        {
            variables: {
                customerMembershipId
            },
            skip: !customerMembershipId
        }
    );

    const customerMembership = data ? data.customerMembership : null;

    if (customerMembership && !base64ImageData) {
        setBase64ImageData(customerMembership.photo.base64);
        setDisableSavePhotoButton(!customerMembership.photo.base64);
    }

    const [mutateSaveImage] = useMutation(SAVE_IMAGE_MEMBERSHIP_MUTATION);
    const saveImage = async () => {
        setDisableSavePhotoButton(true);

        const { data, errors } = await mutateSaveImage({
            variables: {
                customerId: customerMembership.customer.id,
                customerMembershipId,
                base64ImageData
            }
        });

        if (data) {
            toast(`The picture has been saved`);
        }
        if (errors) {
            toast(errors);
        }

        setDisableSavePhotoButton(false);
    };

    const [mutateToggleDisabled] = useMutation(
        TOGGLE_DISABLED_MEMBERSHIP_MUTATION
    );

    const toggleDisabled = async () => {
        const result = await mutateToggleDisabled({
            variables: { customerMembershipId }
        });

        toast(
            `Customer membership was ${
                customerMembership.isDisabled ? "enabled" : "disabled"
            }`
        );
    };

    const [mutateActivate] = useMutation(ACTIVATE_MEMBERSHIP_MUTATION);

    const activate = async () => {
        const result = await mutateActivate({
            variables: { customerMembershipId }
        });

        const newMembership = result.data[
            "activateMembership"
        ] as ICustomerMembership;

        toast(`Customer membership was activated`);
    };

    const setBase64ImageDataExtended = (value: string) => {
        if (value) {
            setDisableSavePhotoButton(false);
        }
        setBase64ImageData(value);
    };

    return open && customerMembership ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box
                as="form"
                fill="vertical"
                overflow="auto"
                width="large"
                pad="medium"
                round="none"
                onSubmit={onClose}
            >
                {mustShowRenewMembership ? (
                    <RenewMembership
                        customerId={customerMembership.customer.id}
                        customerMembershipId={customerMembership.id}
                        customerMembershipName={customerMembership.name}
                        price={customerMembership.membership.price}
                        onRenewed={() => {
                            setOpen(false);
                            setShouldRefresh(true);
                        }}
                    />
                ) : (
                    <>
                        {" "}
                        <Box flex={false} direction="row" justify="between">
                            <Heading
                                level="3"
                                margin="bottom"
                                color={
                                    customerMembership.isDisabled
                                        ? "status-error"
                                        : "black"
                                }
                            >
                                {customerMembership.name}{" "}
                                {customerMembership.isDisabled
                                    ? "[DISABLED]"
                                    : null}
                            </Heading>

                            <Button icon={<Close />} onClick={onClose} />
                        </Box>
                        <Grid columns={["medium", "flex"]} gap="medium">
                            <Box
                                flex={true}
                                direction="column"
                                justify="between"
                                margin={{ top: "medium", bottom: "medium" }}
                                pad={{ bottom: "medium" }}
                            >
                                <Box
                                    width="medium"
                                    border="all"
                                    margin={{ bottom: "small" }}
                                    style={{ minHeight: "150px" }}
                                >
                                    <PhotoUpload
                                        base64ImageData={base64ImageData}
                                        setBase64ImageData={
                                            setBase64ImageDataExtended
                                        }
                                    />
                                </Box>
                                <Box width="medium">
                                    <Button
                                        disabled={disableSavePhotoButton}
                                        label="Save image"
                                        primary
                                        color="accent-1"
                                        onClick={() => {
                                            saveImage();
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                flex={true}
                                direction="column"
                                margin={{ top: "medium", bottom: "medium" }}
                                pad={{ bottom: "medium" }}
                            >
                                {customerMembership.hasBeenActivated ? (
                                    <>
                                        <FieldValue
                                            label="Activated"
                                            value={moment(
                                                customerMembership.activatedAt
                                            )
                                                .utc()
                                                .format("YYYY-MM-DD")}
                                        />
                                        <FieldValue
                                            label="Expires"
                                            value={moment(
                                                customerMembership.expiresAt
                                            )
                                                .utc()
                                                .format("YYYY-MM-DD")}
                                        />
                                        <FieldValue
                                            label="Membership type"
                                            value={
                                                customerMembership.membership
                                                    .name
                                            }
                                        />
                                        <FieldValue
                                            label="Transactions"
                                            value={customerMembership.transactions
                                                .map((transaction) => {
                                                    return `£${transaction.amount.toFixed(
                                                        2
                                                    )} ${
                                                        transaction.description
                                                    } ${
                                                        transaction.providerIdentifier
                                                    }`;
                                                })
                                                .join(" / ")}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Paragraph>
                                            The membership has not been
                                            activated. It must be activated
                                            before the customer can use it.
                                        </Paragraph>
                                        {!customerMembership.photo.base64 ? (
                                            <Paragraph>
                                                <strong>
                                                    You must save an image
                                                    before you can activate the
                                                    membership
                                                </strong>
                                            </Paragraph>
                                        ) : null}
                                        <Button
                                            disabled={
                                                !customerMembership.photo.base64
                                            }
                                            primary
                                            color="accent-2"
                                            label="Activate membership"
                                            onClick={() => {
                                                activate();
                                            }}
                                        />
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Grid columns={["medium", "flex"]} gap="medium">
                            <Box width="medium">
                                <Button
                                    primary
                                    color={
                                        customerMembership.isDisabled
                                            ? "status-ok"
                                            : "status-error"
                                    }
                                    label={
                                        customerMembership.isDisabled
                                            ? "Enable now"
                                            : "Disable now"
                                    }
                                    onClick={() => {
                                        toggleDisabled();
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid columns={["medium", "flex"]} gap="medium">
                            <Box
                                width="medium"
                                margin={{ top: "medium", bottom: "medium" }}
                            >
                                <Button
                                    primary
                                    color="status-ok"
                                    label="Renew membership"
                                    onClick={() => {
                                        setMustShowRenewMembership(true);
                                    }}
                                />
                            </Box>
                        </Grid>
                    </>
                )}
            </Box>
        </Layer>
    ) : null;
};
