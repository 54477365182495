import { useQuery } from "@apollo/client";
import { Box, Heading, Paragraph } from "grommet";
import React, { useContext } from "react";
import { RESOURCE_SUMMARIES_BY_FACILITY } from "../../data/Activities/queries";
import { IFacility } from "../../generated/dataInterfaces";
import AppContext from "../Context/AppContext";
import FacilityContext from "../Context/FacilityContext";
import Page from "../Page";
import ResourceItem from "../Resources/ResourceItem";
import EmptyPage from "./EmptyScreen";
export default () => {
    const { organisationId } = useContext(AppContext);
    const { facilityId } = useContext(FacilityContext);
    const { data, loading } = useQuery(RESOURCE_SUMMARIES_BY_FACILITY, {
        variables: {
            organisationId,
            facilityId
        },
        skip: false,
        fetchPolicy: "cache-and-network",
        pollInterval: 30000
    });

    const facilities: IFacility[] = data ? data.facilitiesByOrg : null;

    if (facilities) {
        // const resources = _.flatten(
        //     facilities.map(facility => facility.resources)
        // );

        const facilityItems = facilities.map((facility) => (
            <Box
                pad={{ horizontal: "medium", vertical: "none" }}
                key={facility.id}
            >
                <Heading level="2">{facility.name}</Heading>
                <Box
                    direction="row"
                    wrap={true}
                    pad={{ horizontal: "none", vertical: "medium" }}
                >
                    {facility.resources.map((resource) => (
                        <ResourceItem resource={resource} key={resource.id} />
                    ))}
                </Box>
            </Box>
        ));

        return (
            <Page title="Resources">
                {!loading && facilities ? (
                    <div>{facilityItems}</div>
                ) : (
                    <Paragraph>Loading...</Paragraph>
                )}
            </Page>
        );
    } else {
        return <EmptyPage />;
    }
};
