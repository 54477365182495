import { gql } from "@apollo/client";
import { credentialFullDetails } from "./fields";

export const CREDENTIAL_FULL_DETAILS = gql`
query credential($credentialId: ID!){
  credential(where: {id: $credentialId}) {
    ${credentialFullDetails}
  }
}
`;

export const CREDENTIAL_EXISTS = gql`
query doesCredentialExist($email: String!){
  doesCredentialExist(input: { email: $email }) {
    ${credentialFullDetails}
  }
}
`;
