import React from "react";
import { ICustomer, IBooking } from "../../../generated/dataInterfaces";
import { Heading } from "grommet";
import BookingDetails from "./Details";
import BookingTransactions from "./Transactions";
import BookingNotes from "./Notes";

interface IPropsBookingView {
    booking: IBooking;
    view: string;
    otherProps?: any;
}

export default ({ booking, view, ...otherProps }: IPropsBookingView) => {
    switch (view.toLowerCase()) {
        case "details":
            return <BookingDetails booking={booking} />;
        case "notes":
            return <BookingNotes booking={booking} />;
        case "transactions":
            return <BookingTransactions booking={booking} />;

        default:
            return <Heading>UNKNOWN VIEW: {view.toLowerCase()}</Heading>;
    }
};
