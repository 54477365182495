import { gql } from "@apollo/client";
import { credentialFullDetails } from "./fields";

export const TOGGLE_DISABLED_CREDENTIAL_MUTATION = gql`
mutation toggleCredential($credentialId: ID!){
    toggleCredential(input: {
        credentialId: $credentialId,
    }) {
        ${credentialFullDetails}
    }
}
`;

export const UNLOCK_CREDENTIAL_MUTATION = gql`
mutation unlockCredential($credentialId: ID!){
    unlockCredential(input: {
        credentialId: $credentialId,
    }) {
        ${credentialFullDetails}
    }
}
`;

export const REQUEST_PASSWORD_RESET_CREDENTIAL_MUTATION = gql`
mutation requestPasswordReset($organisationId: ID!, $email: String!){
    requestPasswordReset(input: {
        organisationId: $organisationId,
        email: $email
    }) {
        ${credentialFullDetails}
    }
}
`;
