import {
    Box,
    Button,
    CheckBox,
    Grid,
    Layer,
    Text,
    TextInput,
    Heading
} from "grommet";
import { Close } from "grommet-icons";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomerContext from "../../Context/CustomerContext";
import { useMutation } from "@apollo/client";
import { NEW_CUSTOMER_CREDENTIAL_SEND_EMAIL } from "../../../data/Customers/mutations";

interface IPropsMakeCustomerByActivityInstance {
    open: boolean;
    setOpen: any;
    otherProps?: any;
}

export default ({ open, setOpen }: IPropsMakeCustomerByActivityInstance) => {
    const {
        setEmail,
        setFirstName,
        setLastName,
        setPostcode,
        setPhone,
        doesReceiveEmail,
        setDoesReceiveEmail,
        doesReceiveSms,
        setDoesReceiveSms,
        sendWelcomeEmail,
        setSendWelcomeEmail,
        credentialExists,
        newCustomer
    } = useContext(CustomerContext);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [exists, setExists] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setExists(!!credentialExists);
        setButtonDisabled(!!credentialExists);
    }, [credentialExists]);

    const setEmailExtended = (email: string) => {
        setEmail(email);
    };

    const makeCustomerExtended = async () => {
        try {
            // create
            await newCustomer();
            toast("Customer created");
            setButtonDisabled(false);
            onClose();
        } catch (e) {
            toast(`There was a problem: ${e.message}`);
            setButtonDisabled(false);
        }
    };

    return open ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box width="xlarge" pad="small">
                <Heading level="3">Create a new customer</Heading>
                <Grid columns={["flex", "flex"]} gap="medium">
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Email address</strong>
                            </Box>
                            <Box>
                                <TextInput
                                    onChange={(e) =>
                                        setEmailExtended(e.target.value)
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Box margin={{ top: "small", right: "small" }}>
                            {exists && credentialExists ? (
                                <Text color="error">
                                    This email address exists already for
                                    customer
                                    <br />
                                    <strong>
                                        {credentialExists.customer.name}
                                    </strong>
                                </Text>
                            ) : null}
                        </Box>
                    </Box>
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>First name</strong>
                            </Box>
                            <Box>
                                <TextInput
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Last name</strong>
                            </Box>
                            <Box>
                                <TextInput
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Post code</strong>
                            </Box>
                            <Box>
                                <TextInput
                                    onChange={(e) =>
                                        setPostcode(e.target.value)
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Mobile number</strong>
                            </Box>
                            <Box>
                                <TextInput
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Receives emails?</strong>
                            </Box>
                            <Box margin={{ top: "small", right: "small" }}>
                                <CheckBox
                                    checked={doesReceiveEmail}
                                    onChange={(e) =>
                                        setDoesReceiveEmail(e.target.checked)
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Receives SMS?</strong>
                            </Box>
                            <Box margin={{ top: "small", right: "small" }}>
                                <CheckBox
                                    checked={doesReceiveSms}
                                    onChange={(e) =>
                                        setDoesReceiveSms(e.target.checked)
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid columns={["small", "flex"]} gap="xsmall">
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Send welcome email?</strong>
                            </Box>
                            <Box margin={{ top: "small", right: "small" }}>
                                <CheckBox
                                    checked={sendWelcomeEmail}
                                    onChange={(e) =>
                                        setSendWelcomeEmail(e.target.checked)
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>

                <Box
                    direction="column"
                    justify="between"
                    margin={{ bottom: "medium", top: "large" }}
                    alignContent="end"
                    width="medium"
                >
                    <Button
                        onClick={(e) => {
                            makeCustomerExtended();
                            setButtonDisabled(true);
                        }}
                        primary
                        disabled={buttonDisabled}
                        color="accent-3"
                    >
                        <Box
                            direction="column"
                            alignContent="center"
                            pad="small"
                            align="center"
                        >
                            Create customer
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Layer>
    ) : null;
};
