import React from "react";
import { SidebarButton } from "./SidebarButton";
import { Box } from "grommet";
export default ({ ...rest }) => {
    const navItems = [
        { name: "Dashboard", url: "/" },
        { name: "Bookings", url: "/bookings" },
        { name: "Activities", url: "/activities" },
        { name: "Resources", url: "/resources" },
        { name: "Customers", url: "/customers" }
        // { name: "Calendar", url: "/calendar" },
    ];

    {
        return (
            <Box
                gridArea="sidebar"
                background="dark-2"
                width="small"
                pad={{ bottom: "large" }}
                animation={[
                    // { type: "fadeIn", duration: 300 }
                    { type: "slideRight", size: "xlarge", duration: 150 }
                ]}
            >
                {navItems.map((item) => (
                    <SidebarButton
                        key={item.name}
                        label={item.name}
                        href={item.url}
                        hoverIndicator
                    />
                ))}
            </Box>
        );
    }
};
