export const credentialFullDetails = `
id
fullNameText
firstName
lastName
isDisabled
isLockedOut
login
email
isPrimaryCredential
mobileNumber
doesReceiveSms
doesReceiveEmails
lastLoginAt
password{
numberOfIncorrectAttempts
    lastLockedOutAt
    lastChangedAt
}
customer{
    id
    name
}
`;
