import { useState, useEffect } from "react";

export function useStateWithLocalStorage<T>(): [
    T,
    (value: T) => void,
    (localStorageKey: string) => void
] {
    const [localStorageKey, setLocalStorageKey] = useState<string>(null);
    const [value, setValue] = useState<T>(null);

    const getValueEffect = () => {
        if (localStorageKey) {
            const itemFromLocalStorage = JSON.parse(
                localStorage.getItem(localStorageKey)
            ) as T;
            if (itemFromLocalStorage) {
                setValue(itemFromLocalStorage);
            }
        }
    };

    useEffect(getValueEffect, [localStorageKey]);

    const setValueEffect = () => {
        if (value && localStorageKey) {
            localStorage.setItem(localStorageKey, JSON.stringify(value));
        }
    };

    useEffect(setValueEffect, [value]);

    const loadStateFromLocalStorage = (key: string) => {
        if (!localStorageKey) {
            setLocalStorageKey(key);
        }
    };

    return [value, setValue, loadStateFromLocalStorage];
}

export default useStateWithLocalStorage;
