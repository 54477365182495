import { AccordionPanel, Heading } from "grommet";
import { CaretNext } from "grommet-icons";
import React from "react";
import { IPropsBookingAccordianPanel } from "./bookingAccordianInterfaces";
import BookingTable from "../BookingTable";

export default ({
    panel,
    isActive,
    ...otherProps
}: IPropsBookingAccordianPanel) => {
    const { title, results } = panel;
    const hasResults = results && results.length > 0;
    return (
        <AccordionPanel
            label={
                <Heading level="2" color={hasResults ? "" : "light-3"}>
                    {title}{" "}
                    <CaretNext
                        size="medium"
                        color={hasResults ? "" : "light-3"}
                    />{" "}
                    {results.length}
                </Heading>
            }
            {...otherProps}
        >
            <BookingTable
                hideActivityInfo={false}
                listData={results}
                {...otherProps}
            />
        </AccordionPanel>
    );
};
