import { useQuery } from "@apollo/client";
import { Box, Button, Grid, Heading, Text } from "grommet";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BOOKING_FULL_DETAILS } from "../../data/Bookings/queries";
import { IBooking, ICustomer } from "../../generated/dataInterfaces";
import BookingView from "../Bookings/Booking";
import BookingListContext from "../Context/BookingListContext";
import { SmallLink } from "../Icons/LinkIndicators";
import Page from "../Page";
import EmptyPage from "./EmptyScreen";

export default ({ match }) => {
    const { id } = match.params;
    const views = ["Details", "Transactions", "Notes"];
    const [view, setView] = useState(views[0]);
    const [shouldLoadData, setShouldLoadData] = useState(true);
    const [booking, setBooking] = useState<IBooking>();
    const [customer, setCustomer] = useState<ICustomer>();
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);

    useEffect(() => {
        setShouldLoadData(shouldLoadData || shouldRefresh);
    }, [shouldRefresh, shouldLoadData]);

    const { data, error, loading } = useQuery(BOOKING_FULL_DETAILS, {
        variables: {
            bookingId: id
        },
        skip: !shouldLoadData,
        fetchPolicy: "no-cache"
    });

    const setBookingExtended = (booking: IBooking) => {
        setBooking(booking);
        setCustomer(booking.customer);
    };

    const hasData = data && data.booking;
    if (hasData && !loading) {
        setShouldLoadData(false);
        setShouldRefresh(false);
        const booking: IBooking = data.booking;
        setBookingExtended(booking);
    }

    if (booking) {
        return (
            <Page title="Booking">
                <Box flex="grow">
                    <Grid
                        columns={["flex", "flex"]}
                        gap="small"
                        margin={{ bottom: "medium" }}
                    >
                        <Box
                            direction="column"
                            pad={{ left: "small" }}
                            margin="none"
                            flex={true}
                        >
                            <Heading level="2" margin={{ top: "none" }}>
                                <Link to={`/customer/${customer.id}`}>
                                    {customer.name} <SmallLink />
                                </Link>
                            </Heading>
                            <Text color="accent-4" size="large" />
                        </Box>
                        <Box
                            alignContent="end"
                            align="end"
                            flex={false}
                            pad={{ right: "small" }}
                        >
                            <Box
                                height="60px"
                                round="large"
                                direction="row-responsive"
                                background="light-1"
                                border="all"
                                flex={false}
                            >
                                {views.map((currentView) => (
                                    <Button
                                        key={currentView}
                                        data-testid={`${currentView.toLowerCase()}-button`}
                                        primary
                                        hoverIndicator=""
                                        color={
                                            view === currentView
                                                ? ""
                                                : "light-1"
                                        }
                                        plain={false}
                                        onClick={() => setView(currentView)}
                                    >
                                        <Text
                                            color={
                                                view === currentView
                                                    ? "white"
                                                    : ""
                                            }
                                            size="large"
                                        >
                                            {currentView}
                                        </Text>
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                    <Box>
                        <BookingView booking={booking} view={view} />
                    </Box>
                </Box>
            </Page>
        );
    } else {
        return <EmptyPage />;
    }
};
