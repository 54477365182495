import { Box, Heading, Tab, Tabs } from "grommet";
import { groupBy } from "lodash";
import React, { useState } from "react";
import { IBooking } from "../../../../generated/dataInterfaces";
import BookingDetail from "../../../Bookings/Detail/BookingDetail";
import GroupedByResource from "./GroupedByResource";
import PackagedBookings from "./PackagedBookings";

interface IPropsCustomerBookings {
    pastBookings: IBooking[];
    futureBookings: IBooking[];
    packagedBookings: IBooking[];
}

export default ({
    pastBookings,
    futureBookings,
    packagedBookings
}: IPropsCustomerBookings) => {
    const [bookingId, setBookingId] = useState<string>();
    const [openBooking, setOpenBooking] = useState<boolean>(false);

    const setActiveBooking = (id) => {
        setBookingId(id);
        setOpenBooking(true);
    };

    const packageGroups = groupBy(packagedBookings, "packageReference");
    const packageKeys = Object.keys(packageGroups);

    const packageTab =
        packagedBookings && packagedBookings.length > 0 ? (
            <Tab
                name="Package"
                title={`Packaged bookings (${packageKeys.length})`}
            >
                <PackagedBookings
                    bookings={packagedBookings}
                    setActiveBooking={setActiveBooking}
                />
            </Tab>
        ) : (
            <Tab name="Package" title="Packaged bookings (0)">
                <Heading level="4">No packaged bookings</Heading>
            </Tab>
        );

    const futureTab =
        futureBookings && futureBookings.length > 0 ? (
            <Tab
                name="Future"
                title={`Future bookings (${futureBookings.length})`}
            >
                <GroupedByResource
                    bookings={futureBookings}
                    setActiveBooking={setActiveBooking}
                />
            </Tab>
        ) : (
            <Tab name="Future" title="Future bookings (0)">
                <Heading level="4">No future bookings</Heading>
            </Tab>
        );
    const pastTab =
        pastBookings && pastBookings.length > 0 ? (
            <Tab name="Past" title={`Past bookings (${pastBookings.length})`}>
                <GroupedByResource
                    bookings={pastBookings}
                    setActiveBooking={setActiveBooking}
                />
            </Tab>
        ) : (
            <Tab name="Past" title="Past bookings (0)">
                <Heading level="4">No past bookings</Heading>
            </Tab>
        );

    return futureTab || pastTab || packageTab ? (
        <Box
            flex="grow"
            background="light-1"
            pad={{ left: "medium", right: "medium", bottom: "large" }}
        >
            <BookingDetail
                open={openBooking}
                setOpen={setOpenBooking}
                bookingId={bookingId}
            />
            <Tabs margin={{ top: "medium" }}>
                {futureTab}
                {pastTab}
                {packageTab}
            </Tabs>
        </Box>
    ) : (
        <Heading margin="large" level="2">
            No bookings
        </Heading>
    );
};
