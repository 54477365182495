import React, { useState, useContext } from "react";
import {
    IBooking,
    ICredential,
    ICustomerBenefit
} from "../../../../generated/dataInterfaces";
import { Paragraph, Box, Heading, Text, Button } from "grommet";
import { Lock, Checkmark, Close } from "grommet-icons";
import _ from "lodash";
import CredentialDetail from "../../Detail/CredentialDetail";
import moment from "moment";
import DiscountItem from "../../../Discount/DiscountItem";
import BookingListContext from "../../../Context/BookingListContext";
import { useMutation } from "@apollo/client";
import { BENEFIT_DELETE } from "../../../../data/Customers/mutations";
import { toast } from "react-toastify";

interface IPropsBenefitItem {
    benefit: ICustomerBenefit;
}

export default ({ benefit }: IPropsBenefitItem) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);

    const [mutateDeleteBenefit] = useMutation(BENEFIT_DELETE);
    const onConfirmDelete = async () => {
        const result = await mutateDeleteBenefit({
            variables: {
                benefitId: benefit.id
            }
        });
        const success = result.data["deleteBenefit"];
        if (success) {
            toast("Cancelled. Email sent to customer");

            setShouldRefresh(true);
        } else {
            toast("There as a problem. No changes made.");
        }
    };
    return benefit ? (
        <Box
            margin="small"
            width="medium"
            pad="small"
            border="all"
            round="small"
            elevation="small"
            background="white"
        >
            <Box flex={false} direction="column" justify="between">
                <Heading
                    level="3"
                    margin={{
                        top: "small",
                        bottom: "small"
                    }}
                >
                    {benefit.name}
                </Heading>
                <Heading
                    level="4"
                    margin={{
                        top: "small",
                        bottom: "small"
                    }}
                >
                    {moment(benefit.expiresAt).utc().isAfter(moment.utc())
                        ? `Expires: ${moment(benefit.expiresAt)
                              .utc()
                              .format("YYYY-MM-DD")}`
                        : `EXPIRED ON ${moment(benefit.expiresAt)
                              .utc()
                              .format("YYYY-MM-DD")}`}
                </Heading>
            </Box>
            <Box flex={false} direction="row" justify="between">
                {benefit.discount.isActive ? (
                    <>
                        Discount active <Checkmark />
                    </>
                ) : (
                    <>
                        Discount inactive <Close />
                    </>
                )}
            </Box>
            <DiscountItem discountId={benefit.discount.id} />
            <Box align="baseline">
                {!confirmDelete ? (
                    <Button primary color="status-warning">
                        <Box
                            align="center"
                            pad="small"
                            onClick={(e) => {
                                setConfirmDelete(true);
                            }}
                        >
                            Delete benefit
                        </Box>
                    </Button>
                ) : null}
                {confirmDelete ? (
                    <Button
                        primary
                        color="status-error"
                        onClick={(e) => {
                            onConfirmDelete();
                        }}
                    >
                        <Box align="center" pad="small">
                            Are you sure?
                        </Box>
                    </Button>
                ) : null}
            </Box>
        </Box>
    ) : null;
};
