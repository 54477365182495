import { useMutation, useQuery } from "@apollo/client";
import DropIn from "braintree-web-drop-in-react";
import {
    Box,
    Button,
    CheckBox,
    Grid,
    Heading,
    Layer,
    Text,
    TextArea,
    TextInput
} from "grommet";
import { Close } from "grommet-icons";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { BRAINTREE_CLIENT_TOKEN_QUERY } from "../../../../data/Braintree/queries";
import { CREATE_BOOKING_TRANSACTION_WITH_PAYMENT } from "../../../../data/Transactions/mutations";
import { IBooking } from "../../../../generated/dataInterfaces";
import AppContext from "../../../Context/AppContext";
import BookingListContext from "../../../Context/BookingListContext";

interface IAddTransactionWithPaymentProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    booking: IBooking;
}

export default ({
    open,
    setOpen,
    booking
}: IAddTransactionWithPaymentProps) => {
    const { shouldRefresh, setShouldRefresh } = useContext(BookingListContext);
    const { userId } = useContext(AppContext);
    const [amount, setAmount] = useState(booking.balance.toFixed(2));
    const [description, setDescription] = useState("");
    const [adjustBalance, setAdjustBalance] = useState(true);
    const [braintreeClientToken, setBraintreeClientToken] = useState(null);
    const [braintreeInstance, setBraintreeInstance] = useState(null);

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    const { data, error, loading } = useQuery(BRAINTREE_CLIENT_TOKEN_QUERY, {
        variables: {
            customerId: booking.customer.id
        },
        skip: braintreeClientToken || !booking
    });

    if (data && data["braintreeClientToken"] && !braintreeClientToken) {
        setBraintreeClientToken(data["braintreeClientToken"].token);
    }

    const [mutateAddTransactionNoPayment] = useMutation(
        CREATE_BOOKING_TRANSACTION_WITH_PAYMENT
    );
    const createTransaction = async () => {
        try {
            const { nonce } = await braintreeInstance.requestPaymentMethod({
                threeDSecure: {
                    amount,
                    challengeRequested: true
                }
            });

            const amountNumber = Number(amount);
            const result = await mutateAddTransactionNoPayment({
                variables: {
                    bookingId: booking.id,
                    amount: amountNumber,
                    description,
                    updateBookingBalance: adjustBalance,
                    braintreeNonce: nonce
                }
            });
            const transaction =
                result.data["createBookingTransactionWithPayment"];
            if (transaction) {
                setOpen(false);
                setShouldRefresh(true);
                toast(
                    `Payment taken. Transaction created. ${
                        adjustBalance ? "Balance updated" : ""
                    }`
                );
            } else {
                toast(
                    `No transaction was created. ${result.errors[0].message}`
                );
            }
            setButtonDisabled(false);
        } catch (e) {
            setButtonDisabled(false);
            toast(e.message);
        }
    };

    return open && booking ? (
        <Layer
            position="right"
            full="vertical"
            modal
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box
                as="form"
                fill="vertical"
                overflow="auto"
                width="large"
                pad="medium"
                onSubmit={onClose}
            >
                <Box flex={false} direction="row" justify="between">
                    <Heading level="2" margin="bottom">
                        Make a payment
                    </Heading>

                    <Button icon={<Close />} onClick={onClose} />
                </Box>

                <Box as="form" direction="column" overflow="auto">
                    <Box
                        direction="column"
                        margin={{ top: "small", bottom: "medium" }}
                    >
                        <Grid
                            columns={["small", "flex"]}
                            gap="xsmall"
                            margin={{ bottom: "medium" }}
                        >
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Amount</strong>
                            </Box>
                            <Box>
                                <TextInput
                                    data-testid="amount-input"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        <Grid
                            columns={["small", "flex"]}
                            gap="xsmall"
                            margin={{ bottom: "medium" }}
                        >
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Description</strong>
                            </Box>
                            <Box>
                                <TextArea
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid
                            columns={["small", "flex"]}
                            gap="xsmall"
                            margin={{ bottom: "medium" }}
                        >
                            <Box margin={{ top: "small", right: "small" }}>
                                <strong>Adjust balance?</strong>
                            </Box>
                            <Box>
                                <CheckBox
                                    checked={adjustBalance}
                                    onChange={(e) => {
                                        setAdjustBalance(e.target.checked);
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Box margin={{ bottom: "medium" }}>
                            {braintreeClientToken ? (
                                <DropIn
                                    options={{
                                        authorization: braintreeClientToken,
                                        translations: {
                                            payingWith: "",
                                            chooseAnotherWayToPay:
                                                "Enter new card details",
                                            chooseAWayToPay:
                                                "Enter card details",
                                            saveCardLabel:
                                                "Save card against customer account? You must have their permission."
                                        },
                                        threeDSecure: true,
                                        vaultManager: true,
                                        card: {
                                            cardholderName: {
                                                required: true
                                            },
                                            vault: {
                                                vaultCard: false,
                                                allowVaultCardOverride: true
                                            }
                                        }
                                    }}
                                    onInstance={(instance) => {
                                        setBraintreeInstance(instance);
                                    }}
                                />
                            ) : (
                                <Text>Loading Braintree</Text>
                            )}
                        </Box>

                        <Grid
                            columns={["small", "flex"]}
                            gap="xsmall"
                            margin={{ bottom: "medium" }}
                        >
                            <Box margin={{ top: "small", right: "small" }} />
                            <Box>
                                <Button
                                    onClick={(e) => {
                                        setButtonDisabled(true);
                                        createTransaction();
                                    }}
                                    primary
                                    disabled={buttonDisabled}
                                    color="accent-2"
                                    data-testid="pay-button"
                                >
                                    <Box
                                        direction="column"
                                        alignContent="center"
                                        pad="small"
                                        align="center"
                                    >
                                        {buttonDisabled
                                            ? `Submitting payment`
                                            : `Make payment`}
                                    </Box>
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Layer>
    ) : null;
};
