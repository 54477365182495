import React, { useCallback } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

interface IPhotoUploadProps {
    base64ImageData: string;
    setBase64ImageData: React.Dispatch<any>;
}

const PhotoUpload = ({
    base64ImageData,
    setBase64ImageData
}: IPhotoUploadProps) => {
    const resizeImg = (img, maxWidth, maxHeight) => {
        // create an off-screen canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const ratio = Math.min(
            1,
            maxWidth / img.naturalWidth,
            maxHeight / img.naturalHeight
        );

        // set its dimension to target size
        canvas.width = img.naturalWidth * ratio;
        canvas.height = img.naturalHeight * ratio;

        // draw source image into the off-screen canvas:
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // encode image to data-uri with base64 version of compressed image
        return canvas.toDataURL();
    };

    const onDrop: DropzoneOptions["onDrop"] = useCallback(
        (acceptedFiles, fileRejections) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                const { result } = reader;

                console.log("result", result.toString());
                const img = new Image();
                img.onload = () => {
                    const resized = resizeImg(img, 300, 300);
                    console.log("resized", resized);
                    setBase64ImageData(resized);
                };
                img.src = result.toString();
            };
            acceptedFiles.forEach((file) => reader.readAsDataURL(file));
            fileRejections.forEach((fileRejection) => {
                console.log(
                    `Rejected file: ${
                        fileRejection.file.name
                    }. Errors: ${fileRejection.errors.map(
                        (error) => `${error.code} `
                    )}`
                );
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop
    });

    return (
        <>
            <div>
                <div
                    className={
                        "m-photo-upload " + (base64ImageData ? "" : "fh")
                    }
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />

                    {base64ImageData ? (
                        <div>
                            <img
                                style={{ width: "100%" }}
                                src={base64ImageData}
                            />
                        </div>
                    ) : isDragActive ? (
                        <p>Drop the image here</p>
                    ) : (
                        <p>
                            Drag &apos;n&apos; drop an image, or click to select
                            one
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default PhotoUpload;
